import React from 'react';
import './AvailableMinutesCard.scss';
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"
import TooltipIcon from "../../assets/info_icon.svg"
import PropTypes from 'prop-types';
import AnimatedNumber from 'react-animated-number';

class AvailableMinutesCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardTitle: props.cardTitle,
      actualMinutes: props.actualMinutes,
      totalMinutes: props.totalMinutes,
      isMax: props.isMax,
      tooltipmessage: props.tooltipmessage
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    // Make sure your book prop is different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        cardTitle: this.props.cardTitle,
        actualMinutes: this.props.actualMinutes,
        totalMinutes: this.props.totalMinutes,
        isMax: this.props.isMax,
        tooltipmessage: this.props.tooltipmessage
      });
  }
  
  render() {

    const numberWithoutCommas = (x) => {
      if (x === null) return '';
      x = x.toString();
      x = x.split(",");
      x = x.join("");
      return x;
    }
  
    const numberWithCommas = (x) => {
      x = numberWithoutCommas(x);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
    
    let actualMinutesNumber = Number(numberWithoutCommas(this.state.actualMinutes));
    let totalMinutesNumber = Number(numberWithoutCommas(this.state.totalMinutes));
    // This function wont be re-rendered in case when the new state is same as previous
    return <div className="availableminutescard">

    <div className="col">
      <div className="minmax">
        {this.state.isMax === 1?
        "MAXIMUM"
        :
        "MINIMUM"
        } 
      </div>
      <div className="title"> 
      {this.state.cardTitle} 
      {
       (this.state.tooltipmessage.length>0)?
          (window.innerWidth <=725)? 
            <Tooltipcomp imgpath={TooltipIcon} classnametooltip="instructionalyeartooltip" tooltipmessage={this.state.tooltipmessage} tooltipid="daytypeheader_tooltipid" tooltipplacement="bottom" /> 
            :
            <Tooltipcomp imgpath={TooltipIcon} classnametooltip="instructionalyeartooltip" tooltipmessage={this.state.tooltipmessage} tooltipid="daytypeheader_tooltipid"  />    
          :
        ""
      }
      
      </div>
      <div className="row">
          <AnimatedNumber
            className= {
              (this.state.isMax === 1) ?
                (actualMinutesNumber <= totalMinutesNumber || !(this.state.totalMinutes.length > 0)) ?
                  "actual_minutes good"
                  :
                  "actual_minutes bad"
                :
                (actualMinutesNumber >= totalMinutesNumber || !(this.state.totalMinutes.length > 0)) ?
                  "actual_minutes good"
                  :
                  "actual_minutes bad"
              }
            value={actualMinutesNumber}
            duration={1000}
            formatValue={n =>`${numberWithCommas(Math.floor(n))} min`} 
          />
          <div className="help bad">

            {(this.state.isMax === 1) ?
            (actualMinutesNumber <= totalMinutesNumber || !(this.state.totalMinutes.length > 0)) ?
              ""
              :
              `(over by ${numberWithCommas((Math.abs(totalMinutesNumber - actualMinutesNumber)).toString())})`
            :
            (actualMinutesNumber >= totalMinutesNumber || !(this.state.totalMinutes.length > 0)) ?
              ""
              :
              `(under by ${numberWithCommas((Math.abs(totalMinutesNumber - actualMinutesNumber)).toString())})`
            }
          </div>
        
      </div>
      
      <div className="limit">
        {this.state.totalMinutes.length > 0 ? `of ${numberWithCommas(totalMinutesNumber)}` : ""}
      </div>

    </div>
  </div>

  }
}

AvailableMinutesCard.defaultProps = {
  tooltipmessage:""
};

AvailableMinutesCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  actualMinutes: PropTypes.string.isRequired,
  totalMinutes: PropTypes.string.isRequired,
  isMax: PropTypes.number.isRequired,
  tooltipmessage: PropTypes.string
};

export default AvailableMinutesCard;