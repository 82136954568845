import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import uuidv4 from 'uuid/v4';

if (!window.localStorage.getItem('uuid')){
  const uuid = uuidv4();
  window.localStorage.setItem('uuid', uuid);
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
