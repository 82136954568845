import React from 'react';
import './FreeingMinutesWindow.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import RadiusFormField from '../RadiusFormField/RadiusFormField';
import PeriodMultiSelector from '../PeriodMultiSelector/PeriodMultiSelector';
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';

const FreeingMinutesWindow = props => {
  
    const {distributeOption, selectedDayType, selectedTimeblock, dayTypes, minutesToFree, selectedMinuteChangeMode, askAfterEdit} = props.formik.values;

    FreeingMinutesWindow.onCancelFunction = () => {

        var form = document.getElementById("freeingminuteswindow");
        form.classList.remove("active");
        var selector = document.getElementById("freeing_period_multiselector");
        selector.classList.remove("active");


        let mode = selectedMinuteChangeMode;
        switch (mode) {
            case "timeblock":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${selectedTimeblock}].duration`,Number(dayTypes[selectedDayType].timeblocks[selectedTimeblock].duration) + minutesToFree);
                break;
            case "passing":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${selectedTimeblock}].passingDuration`,Number(dayTypes[selectedDayType].timeblocks[selectedTimeblock].passingDuration) + minutesToFree);
                break;
            case "lunchblock":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchDuration`,Number(dayTypes[selectedDayType].lunchDuration) + minutesToFree);
                break;
            case "lunchpassing":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchPassingDuration`,Number(dayTypes[selectedDayType].lunchPassingDuration) + minutesToFree);
                break;
            default:
                break;
        }

        props.formik.setFieldValue("minutesToFree",0);

        props.formik.setFieldValue("changeInProgress", false);


    }

    const handleClickOutside = evt => {
        FreeingMinutesWindow.onCancelFunction();
    };

   

    const onSaveFunction = () => {

        switch(distributeOption){
            case "noDistribute":
                props.formik.values.noDistributeFunction();
                break;
            case "distributeInstructional":
                    props.formik.values.distributeTimeblocksFunction();
                    break;
            case "distributePeriods":
                    props.formik.values.distributePeriodsFunction();
                    break;
            case "distributeNonInstructional":
                    props.formik.values.distributePassingsFunction();
                    break;
            default:
                break;
        }

        var form = document.getElementById("freeingminuteswindow");
        form.classList.remove("active");
        var selector = document.getElementById("freeing_period_multiselector");
        selector.classList.remove("active");
        props.formik.setFieldValue("minutesToFree",0);
    }

    const DoNotShowAgain = (e) => {
        props.formik.setFieldValue("askAfterEdit",!askAfterEdit);
    }

    return (
      <div id="freeingminuteswindow" className="freeingminuteswindow">
        <KeyBindingEventComponent  keyname='esc' handlerFunction={handleClickOutside} />
        <div className="window">
            <div className="title">
                You&apos;re freeing up {props.formik.values.minutesToFree} minutes!
            </div>
            <div className="subtitle">
              How would you like to re-distribute the minutes?
            </div>
            <div className="radios mb-1">
                <RadiusFormField
                    fieldName = "distributeOption"
                    value = "distributeInstructional"
                    text = "Distribute minutes as evenly as possible between instructional periods."

                />
                <RadiusFormField
                    fieldName = "distributeOption"
                    value = "noDistribute"
                    text = "Do not re-distribute the newly available minutes to other periods, and allow the school day to end at a different time."
                    secondaryText = "The start and end times following this periods will be adjusted."

                />
                <RadiusFormField
                    fieldName = "distributeOption"
                    value = "distributePeriods"
                    text = "Distribute minutes as evenly as possible between the following periods."

                />

                <PeriodMultiSelector id="freeing_period_multiselector" disabled={distributeOption === "distributePeriods" ? "false" : "true"} />

                
                <RadiusFormField
                    fieldName = "distributeOption"
                    value = "distributeNonInstructional"
                    text = "Distribute minutes as evenly as possible between passing time and non-instructional periods."

                />
            </div>
            <div className="d-flex">
              <div className="donotshowagain flex-grow-1 d-inline-flex">
                <input type="checkbox" name="notShowAgainFreeing" value={!askAfterEdit} checked={!askAfterEdit} onChange={(e) => DoNotShowAgain(e)} />
                <p className="text align-middle flex-glow-1"> Do not show this message again. Always use this setting.</p>
              </div>

              <FunctionalButton
                className="button_clickable_text_a cancel_btn ml-auto"
                text="Cancel"
                onclickhandler={FreeingMinutesWindow.onCancelFunction}
              />
              <FunctionalButton
                id = "send_btn"
                className="next_btn send_btn align-center"
                text="Save"
                onclickhandler={onSaveFunction}
              />
            </div>
        </div>
        <div className="overlay" onClick = {FreeingMinutesWindow.onCancelFunction}>
          
        </div>
      </div>
    );
}

FreeingMinutesWindow.propTypes = {
    formik: PropTypes.object.isRequired
};




export default connect(FreeingMinutesWindow)
