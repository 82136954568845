import React, { useEffect }  from 'react';
import { Switch, Route } from "react-router-dom";
import { withStore } from '../../store';

import DayTypeEditor from '../../components/DayTypeEditor/DayTypeEditor';
import OverViewPage from '../../components/OverviewPage/OverviewPage';

import './index.scss';
import DesignerHeader from '../../components/DesignerHeader/DesignerHeader';
import DesignerTabMenu from '../../components/DesignerTabMenu/DesignerTabMenu';
import PropTypes from 'prop-types';
import { Formik, Form } from "formik";
import ExportForm from '@abl/components/src/ExportForm/ExportForm';
import FreeingMinutesWindow from '../../components/FreeingMinutesWindow/FreeingMinutesWindow';
import CreateNewSchedule from '../../components/CreateNewSchedule/CreateNewSchedule';
import ThankYouWindow from '@abl/components/src/ThankYouWindow/ThankYouWindow';
import AddTimeblockWindow from '../../components/AddTimeblockWindow/AddTimeblockWindow';
import AddDayTypeWindow from '../../components/AddDayTypeWindow/AddDayTypeWindow';
import AddingMinutesWindow from '../../components/AddingMinutesWindow/AddingMinutesWindow';
import BEDataManager from '../../components/BEDataManager/BEDataManager';
import DeleteDayTypeModal from '../../components/DeleteDayTypeModal/DeleteDayTypeModal';
import DeleteTimeblockModal from '../../components/DeleteTimeblockModal/DeleteTimeblockModal';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Designer = props => {

    
    useResizeHelper(true);
    
    const { store } = props;

    const handleSubmit = (values) => {
        try {


            store.set('schedule', values);

            console.log(values);
        } catch (error) {
        }
    };

    const notify = () => {
        toast.info("This is a free tool.  If you have features you would like to add or clarify, please contact us so we can consider accommodating your needs.", {
          position: toast.POSITION.TOP_RIGHT,
          delay: 2000,
          autoClose: 7000,
          className: "toast-properties",
          hideProgressBar: false,
        });
     
    };

    useEffect(() => {
        notify()
      }, []);
    
    return (
        <Formik
            initialValues={{
                backup:{
                    dayType: {
                        id: 0,
                        name: "A DAY",
                        lunches: 0,
                        lunchesPlaced: 0,
                        lunchesPositions: ["","","","",""], // Empty strings as undefined values.
                        lunchDuration: 0,
                        lunchPassingDuration: 0,
                        isLunchLocked: false,
                        isLunchPassingLocked: false,
                        startTime: "00:00",
                        lunchStartTime: "00:00",
                        lunchEndTime: "00:00",
                        lunchPassingEndTime: "00:00",
                        totalStaffOnSiteMinutes: 0,
                        totalInstructionalMinutes: 0,
                        minutesInYear: 0,
                        timeblocks: [
                            {
                                position: 0,
                                id: 0,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 1,
                                id: 1,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingStartTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 2,
                                id: 2,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 3,
                                id: 3,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 4,
                                id: 4,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            
                        ]
                    },
                    
                    weeks:  [],
                    deletedWeeks: [],
                },
                typingTimer: undefined,
                saving: false,
                saved: true,
                dayTypeNameInSettings: "",
                searchingSchool: false,
                changeInProgress: false,
                noSchoolsFound: false,
                initialized: false,
                scheduleId: -1,
                state:"",
                schoolName:"",
                firstName:"",
                lastName:"",
                role:"",
                email:"",
                gsid:"",
                city:"",
                scrolling: false,
                isRotating: true,
                daysInSchoolYear: "180",
                minAnnualMinutes: "65,800",
                minDailyMinutes: "365",
                maxDailyOnSiteMinutes: "425",
                passingTime: "5",
                periodDuration: "40",
                passingTimesInstructional: false,
                askAfterEdit: true,
                selectedDayType: 0,
                selectedMinuteChangeMode: "",
                dayTypesAmount: "2",
                designerSelectedTab: "0",
                scheduleType: "Block",
                distributeOption: "noDistribute",
                takingOption: "noTake",  
                periodToDistribute: 0,
                periodToTake: 0,
                selectedPeriodToCreate: 0,
                createTimeBlockOption: "new",
                selectedDayTypeToDuplicate: 0,
                notShowAgainFreeing: false,
                notShowAgainAdding: false,
                lastMousePosition: 0,
                draggingTimeblockID: 0,
                dragging: false,
                selectedTimeblock: 0,
                modifyingTimeblockPeriod: false,
                hoveredTimeblock: '',
                passingTimeToApply: "",
                periodDurationToApply: "",
                minutesToAdd: 0,
                minutesToFree: 0,
                actualMinutesInYear: 0,
                noDistributeFunction: () => {},
                distributeTimeblocksFunction: () => {},
                distributePeriodsFunction: () => {},
                distributePassingsFunction: () => {},
                startMinutesChange: () => {},
                readyToExport: false,
                schools: [],
                selectedPeriodsToModify: [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                ],
                periods: [ // Courses
                    {
                        id: 0,
                        name: "",
                        theme: "period_1",
                        isInstructional: true,
                        color: "#FDE2ED"
                    }],
                dayTypes: [
                    {
                        id: 0,
                        name: "A DAY",
                        lunches: 0,
                        lunchesPlaced: 0,
                        lunchesPositions: ["","","","",""], // Empty strings as undefined values.
                        lunchDuration: 0,
                        lunchPassingDuration: 0,
                        isLunchLocked: false,
                        isLunchPassingLocked: false,
                        startTime: "00:00",
                        lunchStartTime: "00:00",
                        lunchEndTime: "00:00",
                        lunchPassingEndTime: "00:00",
                        totalStaffOnSiteMinutes: 0,
                        totalInstructionalMinutes: 0,
                        minutesInYear: 0,
                        timeblocks: [
                            {
                                position: 0,
                                id: 0,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 1,
                                id: 1,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 2,
                                id: 2,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 3,
                                id: 3,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            {
                                position: 4,
                                id: 4,
                                periodId: 0,
                                duration: 0,
                                passingDuration: 0,
                                isLocked: false,
                                isPassingLocked: false,
                                startTime: "00:00",
                                passingEndTime: "00:00",
                                endTime: "00:00"
                            },
                            
                        ]
                }],
                weeks: [{
                    id: 0,
                    days: [-1,-1,-1,-1,-1] 
                }],
                errors: {}
            }}
            onSubmit={handleSubmit}
        >
            
            <Form>
                <div className="designer" id="designer">
                    <ToastContainer />
                    <BEDataManager />
                    <DesignerHeader />
                    <DesignerTabMenu />
                    <AddTimeblockWindow />
                    <AddDayTypeWindow />
                    <ExportForm subText="to receive a CSV and PDF of this schedule in your inbox" />
                    <FreeingMinutesWindow />
                    <AddingMinutesWindow />
                    <CreateNewSchedule />
                    <ThankYouWindow 
                        subText="You&apos;ll receive a CSV and PDF of this schedule in your inbox shortly." 
                        restartText="No, I want to start a New Schedule" 
                        restartRoute="/wizard" 
                        otherToolText="We also have a free tool that helps school leaders understand how enrollment,<br> number of teachers and choice of base schedules impact class size." 
                        otherAppBtnText= "Yes! I want to experiment with my Resource Optimizer App" 
                        tryAppText="Would you like to try our Base Schedule Comparison Tool?" 
                        otherAppUrl={process.env.REACT_APP_RESOURCE_OPTIMIZER_URL}
                        />
                    <DeleteDayTypeModal /> 
                    <DeleteTimeblockModal />
                   
                    
                    <div className="designercontainer">
                        <Switch>
                            <Route path="/designer/daytype" component={DayTypeEditor} />
                            <Route path="/designer/overview" component={OverViewPage} />
                        </Switch>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

Designer.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withStore(Designer);