import React from 'react';
import ReactGA from 'react-ga';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import "core-js/stable";
import "regenerator-runtime/runtime";
import Wizard from './pages/wizard/';
import Designer from './pages/designer/';
import Pdf from './pages/pdf/';

import './App.scss';
import { createStore } from './store';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId);
const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App = () => {
  return (
    <Router history={history}>
      <div className="App">
        <div className="container-fluid">
          <Switch>
            <Route path="/wizard" component={Wizard} />
            <Route path="/designer" component={Designer} />
            <Route path='/pdf' component={Pdf} />
            <Redirect exact path='/' to='/wizard' />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default createStore(App);
