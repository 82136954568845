import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "formik";
import './LunchTimeblocksSelector.scss';
import LunchPositionSelector from '../LunchPositionSelector/LunchPositionSelector';

const LunchTimeblocksSelector = props => {

  const {selectedDayType, dayTypes} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
  const { lunches } = dayType;
  const {onTimeblocksChangeFunction} = props;

  const CreateLunchPositionSelectors = () => {
    let components = [];
    let validLunches = Number(lunches);
    if (validLunches > 5) validLunches = 5;
    for(let i = 0; i < validLunches; i++){
      if(i === 0){
        
        components.push(<LunchPositionSelector key={i} firstLunchPositionSelectorID="firstLunchPositionSelector" lunchId={i} onTimeblocksChangeFunction={onTimeblocksChangeFunction} />)
      }
      else{
        components.push(<LunchPositionSelector key={i} lunchId={i} />)
      }
    }
    
    return components;
  };

  return (
    <div className="lunchtimeblocksselector">
      <div className="title">
        AFTER WHICH TIMEBLOCKS?
      </div>
      <div className="row">
        <div className="row lunch_position_selectors">
          {CreateLunchPositionSelectors()}
          
        </div>
      </div>
    </div>
  );
}

LunchTimeblocksSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  onTimeblocksChangeFunction: PropTypes.func.isRequired,

};

export default connect(LunchTimeblocksSelector);
