import React from 'react';
import './DailyViewHeader.scss';
import EditIcon from '../../assets/edit_pencil.svg';
import LinkerButton from '@abl/components/src/LinkerButton/LinkerButton';
import { connect } from "formik";
import PropTypes from 'prop-types';

const DailyViewHeader = (props) => {
  const {daytypeid, daytype, dayTypes} = props;
  

  function onClickLinker(){
    props.formik.setFieldValue("selectedDayType",daytypeid);
    props.formik.setFieldValue("designerSelectedTab",daytypeid.toString());

    
 }


  return (

    <div className="dailyviewheader">

      <div className="header-box">
        <div className="row row-width">

          <div className="header-child">
            <div className="editbtn-properties"> 
              <LinkerButton id="daytypelinker_id" toRoute="/designer/daytype"  className="edit_button" text={`${daytype}`} imgpath={EditIcon} onClick={onClickLinker} />             
            </div>
            <div className="separator" ></div>
            
          </div>

          <div className= "yantramanav header-child" >
            <div className="row">Instructional Min Per Day</div>
            <div className="instructionalminutes row justify-content-center">{dayTypes[daytypeid].totalInstructionalMinutes}</div>
            <div className="separator" ></div>
          </div>

          <div className=" yantramanav header-child">
            <div className="row">Staff Onsite Min Per Day</div>
            <div className="instructionalminutes row justify-content-center">{dayTypes[daytypeid].totalStaffOnSiteMinutes}</div>
          </div>
          
          
        </div>

      </div>

    </div>
  );
}



DailyViewHeader.propTypes = {
  formik: PropTypes.object.isRequired,
  daytype: PropTypes.string.isRequired,
  daytypeid:PropTypes.number.isRequired,
  dayTypes:PropTypes.array.isRequired
};


export default connect(DailyViewHeader);
