import React from 'react';
import './WeeklyTimeBlock.scss';
import PropTypes from 'prop-types';

const WeeklyTimeBlock = ({ periodname, weeklyuniquekey, color, durationSum,duration }) => {


  return (
    <div className="weeklytimeblock">
      <div className="weeklytimeblock-box weeklytimeblock-reef">
        <div className="row weeklytimeblock-row">
          <div className="col weeklytimeblock-column-periodname"><div>{periodname}</div></div>
        </div>
      </div>
    </div>
  );
}


WeeklyTimeBlock.propTypes = {
  periodname: PropTypes.string.isRequired,
  weeklyuniquekey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  durationSum: PropTypes.number.isRequired,
  duration:PropTypes.number.isRequired,
};

export default WeeklyTimeBlock;
