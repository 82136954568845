import React from 'react';
import './SchoolSelector.scss';
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';
import { connect } from "formik";
import PropTypes from 'prop-types';
import InfoPopUp from "@abl/components/src/InfoPopUp/InfoPopUp";
import Highlighter from "react-highlight-words";
import API from '@abl/components/src/api/api';



const SchoolSelector = props => {
    const {tabIndex} = props;
    const {schools, state, schoolName, typingTimer, searchingSchool, noSchoolsFound} = props.formik.values;

    const stateCodes = {
        "Alabama":"AL",
        "Alaska":"AK",
        "Arizona":"AZ",
        "Arkansas":"AR",
        "California":"CA",
        "Colorado":"CO",
        "Connecticut":"CT",
        "Delaware":"DE",
        "District of Columbia":"DC",
        "Federated States Of Micronesia":"MH",
        "Florida":"FL",
        "Georgia":"GA",
        "Hawaii":"HI",
        "Idaho":"ID",
        "Illinois":"IL",
        "Indiana":"IN",
        "Iowa":"IA",
        "Kansas":"KS",
        "Kentucky":"KY",
        "Louisiana":"LA",
        "Maine":"ME",
        "Maryland":"MD",
        "Massachusetts":"MA",
        "Michigan":"MI",
        "Minnesota":"MN",
        "Mississippi":"MS",
        "Missouri":"MO",
        "Montana":"MT",
        "Nebraska":"NE",
        "Nevada":"NV",
        "New Hampshire":"NH",
        "New Jersey":"NJ",
        "New Mexico":"NM",
        "New York":"NY",
        "North Carolina":"NC",
        "North Dakota":"ND",
        "Ohio":"OH",
        "Oklahoma":"OK",
        "Oregon":"OR",
        "Pennsylvania":"PA",
        "Rhode Island":"RI",
        "South Carolina":"SC",
        "South Dakota":"SD",
        "Tennessee":"TN",
        "Texas":"TX",
        "Utah":"UT",
        "Vermont":"VT",
        "Virginia":"VA",
        "Washington":"WA",
        "West Virginia":"WV",
        "Wisconsin":"WI",
        "Wyoming":"WY",
    }

    const myTrim = (x) => {
      return x.replace(/^\s+|\s+$/gm,'');
    }

    const OnSchoolClick = (school) => {
      props.formik.setFieldValue("schoolName",myTrim(school.name));
      props.formik.setFieldValue("gsid", school.gsId);
      props.formik.setFieldValue("city", school.city);
      var schoolList = document.getElementById("school_list");
      schoolList.classList.remove("displaying");
    }

    

                    // Timer identifier
    var doneTypingInterval = 500;  // Time in ms, 5 second

    const getSchoolList = async() => {
      // eslint-disable-next-line no-return-await
      return await (await API.get(`/search-schools?state=${stateCodes[state]}&search_query=${schoolName}`)).data;
    };

    const openDisplay = () => {
      var schoolList = document.getElementById("school_list");
      schoolList.classList.add("displaying");
    }

    const closeDisplay = () => {
      setTimeout(() => {
        var schoolList = document.getElementById("school_list");
        schoolList.classList.remove("displaying");
      }, 200);
      
    }

    const doneTyping = () => {
      props.formik.setFieldValue("searchingSchool",true);
      props.formik.setFieldValue("noSchoolsFound",false);

      if(schoolName.length > 0) getSchoolList().then(value => {
        if(value.data.length > 0) props.formik.setFieldValue("schools",value.data);
        else{
          props.formik.setFieldValue("noSchoolsFound",true);
        }

        props.formik.setFieldValue("searchingSchool",false);
      });
      else{
        props.formik.setFieldValue("searchingSchool",false);
      }

    }

    
    
    return (
      <div className="schoolselector">
        <BasicFormField
          tabIndex={tabIndex}
          autocomplete="off"
          fieldName='schoolName'
          emptyText="select your school"
          labelText="YOUR SCHOOL NAME"
          type='text'
          optional="true_not_optional_text"
          isNumber={false}
          onKeyPressFunction = {(event) => {
            if (event.target.value.length > 50) event.preventDefault() 
          }}
          onKeyUpFunction={(event) => {
              if(event.key !== "ArrowLeft" && event.key !== "ArrowRight"){

                clearTimeout(typingTimer);
                props.formik.setFieldValue("typingTimer",setTimeout(doneTyping, doneTypingInterval));
              }
              
            }
          }
          onBlurFunction = {closeDisplay}
          onFocusFunction = {() => {
            openDisplay();
          }}
        />
        <div id="school_list" className="school_list header">
          {
             searchingSchool ?
              <div className="searching">Searching...</div>
              :
              noSchoolsFound ?
                <div className="no_schools_found">!   could not find school, please try again.</div>
                :
                schools.map((school,index) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return  <div key={school.name + index} className = "school_button" onClick={() => OnSchoolClick(school)}>
                            <Highlighter
                              autoEscape
                              highlightClassName="highlight"
                              searchWords={[schoolName]}
                              textToHighlight={school.name}
                            />
                            <div className="school_info">{`${school.city}, ${school.state}`}</div>
                          </div>
                  })
          }
            
          
        </div>
        <InfoPopUp 
          clickableMessage = "Not seeing your school? Drop us a note, and we’ll register you."
          link = "https://unlockingtime.org/contact-unlocking-time"
        />

      </div>
    );
}

SchoolSelector.defaultProps = {
  tabIndex: 0
}


SchoolSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  tabIndex: PropTypes.number
};


export default connect(SchoolSelector);
