import React from 'react';
import './DailyViewStack.scss';
import TimeTag from '../../components/TimeTag/TimeTag';
import PropTypes from 'prop-types';


const DailyViewStack = ({ timeblockarray, dailyviewstackkey, timetagsarray }) => {// Timeblockarray holds the periods of a single day.
  // The timetagsarray holds the starting hours of the periods in a whole day.
  
  return (

    <div className="dailyviewstack">
      
      {timeblockarray.map((value, index) => {
        return <div key={ dailyviewstackkey +'/' + value.props.dailyuniquekey + index.toString()}>{<TimeTag time={timetagsarray[index]} />}{value}</div>
      })}
      <TimeTag time={timetagsarray[timetagsarray.length - 1]} />

    </div>

  );
}

DailyViewStack.propTypes = {
  timeblockarray: PropTypes.array.isRequired,
  dailyviewstackkey: PropTypes.string.isRequired,
  timetagsarray: PropTypes.array.isRequired
};

export default DailyViewStack;
