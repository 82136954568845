import React, { useEffect } from "react";
import SchedulingConstraintsForm from "../../../components/SchedulingConstraintsForm/SchedulingConstraintsForm";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import { connect } from "formik";
import PropTypes from 'prop-types';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';

const StepOne = props => {
  useResizeHelper(false);



  useEffect(() => {
    var buttons = document.getElementById("next_btn");
    let { daysInSchoolYear, minAnnualMinutes, minDailyMinutes, maxDailyOnSiteMinutes } = props.formik.values.errors;
    if (daysInSchoolYear || minAnnualMinutes || minDailyMinutes || maxDailyOnSiteMinutes) {
      buttons.classList.add("disabled");
    }
    else {
      buttons.classList.remove("disabled");
    }
  });

  return (
    <div>
      <h1 className="pageFirstTitle">What are your scheduling constraints?</h1>
      <div className="centered_form">

        <SchedulingConstraintsForm />
      </div>
      <PageChangeButtons nextRoute="/wizard/two" />
    </div>
  );

}

StepOne.propTypes = {
  formik: PropTypes.object.isRequired
};

export default connect(StepOne);
