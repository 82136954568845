import React from 'react';
import './CreateNewSchedule.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import ExportImagePath from '../../assets/export_schedule_icon.svg';
import onClickOutside from "react-onclickoutside";
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';


class CreateNewSchedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.createNewScheduleHandler = this.createNewScheduleHandler.bind(this);
        this.exportScheduleHandler = this.exportScheduleHandler.bind(this);
        this.close = this.close.bind(this);
      }

    close = () => {
        var form = document.getElementById("createnewschedulewindow");
        form.classList.remove("active");
    };
      
    handleClickOutside = evt => {
      this.close();
    };

      
    createNewScheduleHandler(){
        window.location.href = '/wizard';
    }
    
    exportScheduleHandler(){
        var thisWindow = document.getElementById("createnewschedulewindow");
        thisWindow.classList.remove("active");

        var form = document.getElementById("exportform");
        form.classList.add("active");

        
    }

    render() { 
        
    return (
        <div  ref={node => { this.node = node; }} id="createnewschedulewindow" className="createnewschedulewindow">
            <KeyBindingEventComponent  keyname='esc' handlerFunction={this.handleClickOutside} />
         


            <div className="window">
               
                <div className="title">
                    Create a New Schedule?
                </div>
                <div className="subtitle">
                    Creating a new schedule will reset template settings
                </div>
                <div>If you want to save or share the schedule you&apos;re currently<br /> working on, make sure to export this schedule first.</div>
                
                <div className="buttons_container ">
                    <div className="row">
                        <FunctionalButton text="Cancel and continue experimenting" onclickhandler={this.close} className="col button_clickable_text_a " />
                        <FunctionalButton text="&#8203; &#8203;&#8203;Export Schedule" onclickhandler={this.exportScheduleHandler} className="col back_btn exportbtn-properties " imgpath={ExportImagePath} />
                        <FunctionalButton text="+ Create a New Schedule" onclickhandler={this.createNewScheduleHandler} className="col next_btn createschedulebtn-properties" />
                    </div>
                </div>
                
            </div>
            <div className="overlay">
            
            </div>
        </div>
        );
    }
}

export default onClickOutside(CreateNewSchedule);




