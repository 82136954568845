import React from 'react';
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField.jsx"
import './SchedulingConstraintsForm.scss'


const SchedulingConstraintsForm = () => {

   
    return (
        <div>
                         
            <BasicFormField
                fieldName='daysInSchoolYear'
                labelText='DAYS IN INSTRUCTIONAL SCHOOL YEAR'
                backgroundText='days'
                type='text'
                optional="false"
                errorMsg="Days must be between 1 and 365"
                minValue={1}
                maxValue={365}
                tooltipmessage="<b>How many days in a year are students receiving instruction?</b> Most schools in the U.S. offer <b>180 days</b> of instruction in a given school year."
                isTooltipText="true"      
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
                
            />
              
            <BasicFormField
                fieldName='minAnnualMinutes'
                labelText='MINIMUM ANNUAL INSTRUCTIONAL MINUTES'
                backgroundText='min'
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 525,600"
                minValue={1}
                maxValue={525600}
                tooltipmessage="<b>How many minutes of instruction are you targeting to offer students in a given school year?</b>  Most states require roughly <b>65,000 minutes</b> of instruction for students in a given school year."
                isTooltipText="true" 
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
            />
            <BasicFormField
                fieldName='minDailyMinutes'
                labelText='MINIMUM DAILY INSTRUCTIONAL MINUTES'
                backgroundText='min'
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 1,440"
                minValue={1}
                maxValue={1440}
                tooltipmessage="<b>How many minutes of instruction are you targeting to offer students in a given school day?</b>  Most states require roughly <b>360 minutes</b> of instruction for students in a given school day."
                isTooltipText="true" 
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
            />
            <BasicFormField
                fieldName='maxDailyOnSiteMinutes'
                labelText='MAXIMUM DAILY STAFF ONSITE MINUTES'
                backgroundText='min'
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 1,440"
                minValue={1}
                maxValue={1440}
                tooltipmessage="<b>How many minutes of daily onsite time for staff is the maximum you are targeting?</b>  If you do not know your school's requirements, a good estimate is <b>420 minutes</b> of time a teacher is onsite, including instruction and non-instructional time."
                isTooltipText="true" 
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
            />
        </div>
    );
}

export default SchedulingConstraintsForm;