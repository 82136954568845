import React from 'react';
import './TimeTag.scss';
import PropTypes from 'prop-types';
import ChangeHighlight from "react-change-highlight";

class TimeTag extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      time: props.time || '00:00',
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    // Make sure your book prop is different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        time: this.props.time,
      });
  }
  
  render() {

    const GetTimeString = () => {
      let [hour, minutes] = this.state.time.split(':');
      let dayPart = 'am';
  
      let hournum = Number(hour);
  
      if(hour && minutes) {
          if(hournum >= 12){
            if(hournum > 12){
              
              hournum -= 12;
              dayPart = 'pm';
            }
            else if(hournum === 12){
              dayPart = 'pm';
            }
            else{
              dayPart = 'am';
            }
          }
      
          return `${hournum}:${minutes} ${dayPart}`
      
      }
  
      return '';
    }
    
    return <div className="timetag">


            <div className="timetag-line"></div>
            <div className="hour-position hour-font">
            <ChangeHighlight
              showAfter={0}
              hideAfter={1000}
              containerClassName="container_timetag"
            >
              <div ref={React.createRef()}>{GetTimeString()}</div>
            </ChangeHighlight>
              
            </div>

          </div>
  }
}

TimeTag.propTypes = {
  time: PropTypes.string.isRequired
};

export default TimeTag;