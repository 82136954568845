import React, { useEffect } from 'react';
import { Field, connect } from "formik";
import PropTypes from 'prop-types';
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"
import TooltipIcon from "../assets/info_icon.svg"
import './BasicFormField.scss';
import OnChangeValueHighlight from '../OnChangeValueHighlight/OnChangeValueHighlight';


const BasicFormField = (props) => {
  const numberWithoutCommas = (x) => {
    x = x.split(",");
    x = x.join("");
    return x;
  }

  const numberWithCommas = (x) => {
    x = numberWithoutCommas(x);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  let { id,
        fieldName, 
        labelText, 
        backgroundText, 
        type,
        tabIndex,
        optional, 
        errorMsg, 
        minValue, 
        maxValue, 
        onKeyPressFunction, 
        tooltipmessage, 
        emptyText, 
        onBlurFunction, 
        displayOptional, 
        onKeyUpFunction, 
        onClickHandler,
        isDuration, 
        isNumber, 
        shouldHighlightOnChange, 
        changingValue,
        autocomplete,
        onFocusFunction,
        notEmpty,
        isTooltipText,
        extraInfo} = props;

  if(id === "") id = fieldName;
  const validateValue = (strvalue) => {
    var errorMsg = document.getElementById(`${id}_error`);
    var inputField = document.getElementById(`${id}`);
    
    if (maxValue !== minValue) {
      

      if (strvalue === "" && optional === "true") {
        errorMsg.classList.remove("active");
        inputField.classList.remove("error_input_field");
        props.formik.values.errors[fieldName] = false;
      } else if (strvalue === "" && optional === "false") {
        errorMsg.classList.add("active");
        inputField.classList.add("error_input_field");
        props.formik.values.errors[fieldName] = true;
      }
      else {
        let value = Number(numberWithoutCommas(strvalue));

        if (value < minValue || value > maxValue) {
          errorMsg.classList.add("active");
          inputField.classList.add("error_input_field");
          props.formik.values.errors[fieldName] = true;
        }
        else {
          errorMsg.classList.remove("active");
          inputField.classList.remove("error_input_field");
          props.formik.values.errors[fieldName] = false;
        }
      }
    }
    else if(optional === "false" && strvalue === ""){            
      props.formik.values.errors[fieldName] = true;
      errorMsg.classList.add("active"); 
      inputField.classList.add("error_input_field");
    }else if(optional !== "false" && strvalue === ""){            
      props.formik.values.errors[fieldName] = true;
    }else{
      
      errorMsg.classList.remove("active");
      inputField.classList.remove("error_input_field");
      props.formik.values.errors[fieldName] = false;
    }
  }

  useEffect(() => {
    let field = document.getElementById(`${id}`);
    if (field) validateValue(field.value);

    
  });

  useEffect(() => {
    let field = document.getElementById(`${id}`);
    if (isNumber) props.formik.setFieldValue(fieldName, numberWithCommas(field.value.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyUp = (e) => {
    if (type === "text") {
      if (maxValue !== minValue) {
        validateValue(e.target.value);
      }

      props.formik.setFieldValue(e.target.name, isNumber === true? ((notEmpty && e.target.value.length === 0) ? "0" : numberWithCommas(e.target.value)) : e.target.value);
          
    }

    onKeyUpFunction(e);
  };

  const handleKeyPressed = (event) => {

    if(event.target.value === "0" && event.target.selectionStart === 1 && (event.key >= '0' && event.key <= '9')){
      event.target.value = event.key;
      event.preventDefault();
    }

    if(event.key === "0" && event.target.value.length > 0 && event.target.selectionStart === 0 && event.target.selectionStart === event.target.selectionEnd){
      event.preventDefault();
    }
    
    if(event.keyCode !== 8 && event.keyCode !== 37 && event.keyCode !== 39 && event.key !== "Tab" && event.keyCode !== 46 && event.keyCode !== 13){
      onKeyPressFunction(event);
      
      
      
    }

    
    if(event.keyCode === 13 || event.keyCode === 9){
      if(isDuration){
        event.target.blur();
        event.preventDefault();
      }
    }
  

    

    
  }

  

  return (
    <div className="form_entry">
      {(tooltipmessage.length > 0) ?
        <div className="tooltip-properties" >
          {(isTooltipText === "true")?
            (
              (window.innerWidth <= 800)? 
                <div>
                  <div id={`whatsid_${fieldName.split(".").join("")}`} >What is this?</div>
                  <Tooltipcomp  tooltipmessage={tooltipmessage} idcomponent={`whatsid_${fieldName.split(".").join("")}`} tooltipplacement="bottom" />
                </div>
                :
                <div>
                  <div id={`whatsid_${fieldName.split(".").join("")}`}  >What is this?</div>
                  <Tooltipcomp  tooltipmessage={tooltipmessage} idcomponent={`whatsid_${fieldName.split(".").join("")}`}/>
                </div>
              )
            :
            (window.innerWidth <= 800)? 
              <Tooltipcomp imgpath={TooltipIcon} tooltipmessage={tooltipmessage} tooltipid={`${fieldName}`} tooltipplacement="bottom" />
              :
              <Tooltipcomp imgpath={TooltipIcon} tooltipmessage={tooltipmessage} tooltipid={`${fieldName}`} />
            
          }
          
          
        </div>
        :
        ""
      }
      {(labelText.length > 0) ?
        <div className="field_title"><label htmlFor={fieldName}>{labelText}</label>{(optional === "true" && displayOptional === "true") ? "optional" : ""}<br /></div>
        :
        ""
      }
      {(extraInfo.length > 0) ?
        <div className="extra-info">{extraInfo}</div>
        :
        ''
      }
      {(backgroundText.length > 0) ?
        <div className="input_background yantramanav">
          <p>{backgroundText}</p>
        </div>
        : ''}
      
      {shouldHighlightOnChange === true?
        <div>
        <Field tabIndex={tabIndex} id={`${id}`} className="input_field" type={type} placeholder={emptyText} name={fieldName} onBlur={onBlurFunction} onKeyUp={handleKeyUp} onKeyDown={handleKeyPressed} onClick={onClickHandler} />
        <OnChangeValueHighlight value = {changingValue} />
        </div>
      :
        <Field id={`${id}`} autoComplete = {autocomplete} className="input_field" type={type} tabIndex={tabIndex} placeholder={emptyText} name={fieldName} onBlur={onBlurFunction} onKeyUp={handleKeyUp} onKeyDown={handleKeyPressed} onClick={onClickHandler} onFocus = {onFocusFunction} />
      }
      <div id={`${id}_error`} className="error_msg">
        {errorMsg}
      </div>
      
    </div>
  );
}

BasicFormField.defaultProps = {
  labelText: '',
  backgroundText: '',
  errorMsg: '',
  minValue: -1,
  maxValue: -1,
  optional: "false",
  id: "",
  tooltipmessage:"",
  onKeyPressFunction: (event) => { },
  emptyText: "",
  onBlurFunction: (event) => {},
  onKeyUpFunction: (event) => {},
  displayOptional: "true",
  onClickHandler: (event) => {},
  isDuration: false,
  isNumber: true,
  shouldHighlightOnChange: false,
  changingValue: "",
  notEmpty: false,
  autocomplete: undefined,
  onFocusFunction: () => {},
  tabIndex: 0,
  extraInfo: '',
  isTooltipText: 'false'
};

BasicFormField.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  backgroundText: PropTypes.string,
  type: PropTypes.string.isRequired,
  optional: PropTypes.string,
  errorMsg: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onKeyPressFunction: PropTypes.func,
  id: PropTypes.string,
  tooltipmessage: PropTypes.string,
  emptyText: PropTypes.string,
  onBlurFunction: PropTypes.func,
  displayOptional: PropTypes.string,
  onKeyUpFunction: PropTypes.func,
  onClickHandler: PropTypes.func,
  isDuration: PropTypes.bool,
  isNumber: PropTypes.bool,
  shouldHighlightOnChange: PropTypes.bool,
  changingValue: PropTypes.string,
  notEmpty: PropTypes.bool,
  autocomplete: PropTypes.string,
  onFocusFunction: PropTypes.func,
  tabIndex: PropTypes.number,
  extraInfo: PropTypes.string,
  isTooltipText: PropTypes.string
};

export default connect(BasicFormField);