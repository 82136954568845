import React from 'react';
import './scheduletemplates.scss';
import TemplateCard from '@abl/components/src/TemplateCard/TemplateCard';
import blockscheduleimg from '../../assets/block_schedule.png'
import standardscheduleimg from '../../assets/standard_schedule.png'
import { connect } from "formik";
import PropTypes from 'prop-types';

const ScheduleTemplate = props => {
    var Standard = <TemplateCard
        linkRoute="/wizard/four"
        img_path={standardscheduleimg}
        clickableMessageparam="What is standard schedule?"
        link="https://unlockingtime.org/school-schedules/Standard-Periods-Schedule"
        btntext="Standard"
        onClick={(event) => {
            props.formik.setFieldValue("scheduleType", "Standard");
            props.formik.setFieldValue("dayTypesAmount", "1");
            props.formik.setFieldValue("scheduleTypeLink", "https://unlockingtime.org/school-schedules/Rotating-Standard-Periods-Schedule");
        }} />;

    var Block = <TemplateCard
        linkRoute="/wizard/four"
        img_path={blockscheduleimg}
        clickableMessageparam="What is a block schedule?"
        link="https://unlockingtime.org/school-schedules/AB-Block-Schedule"
        btntext="Block"
        onClick={(event) => {
            props.formik.setFieldValue("scheduleType", "Block");
            props.formik.setFieldValue("dayTypesAmount", "2");
            props.formik.setFieldValue("scheduleTypeLink", "https://unlockingtime.org/school-schedules/Rotating-Block-Schedule");
        }} />;

    const elements = [];    // This array holds the templatecards available.
    const keys = [];
    elements.push(Standard);
    elements.push(Block);
    keys.push("Standard");
    keys.push("Block");

    return (
        <div className="scheduletemplatescreen" >
            <div className="infocontainer">
                <h1 className='pageFirstTitle title'>What kind of bell schedule would you like to explore?</h1>
                <h1 className='subtitle' >
                    Select a template below as a starting point.
                    You&apos;ll be able to customize it afterwards.
                    </h1>
            </div>



            <div className="dynamictemplate">
                <div className="box">
                    {elements.map((value, index) => {
                        return <div key={keys[index]}>{value}</div>
                    })}
                </div>
            </div>
        </div >
    );
}

ScheduleTemplate.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default connect(ScheduleTemplate);
