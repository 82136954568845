import React from 'react';
import './SwitchFormField.scss';
import PropTypes from 'prop-types';
import NoImage from '../../assets/toggle_off.svg';
import YesImage from '../../assets/toggle_on.svg';
import { connect } from "formik";

const SwitchFormField = props => {
  const { text, fieldName } = props;
  const switchFunction = () => {
    let field = props.formik.values[fieldName];
    props.formik.setFieldValue(fieldName, !field);

    
  };

  return (
    <div className="switchformfield" onClick={switchFunction}>
      <div className="switch_text">
        {text}
      </div>
      <div className="switch_image">
        <img id={`${fieldName}_on`} className={(props.formik.values[fieldName])?"yes_image":"yes_image img_inactive"} src={YesImage} alt="error" />
      </div>
      <div className="switch_image">
        <img id={`${fieldName}_off`} className={(props.formik.values[fieldName])?"no_image img_inactive":"no_image"} src={NoImage} alt="error" />
      </div>
    </div>
  );
};

SwitchFormField.propTypes = {
  formik: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default connect(SwitchFormField);
