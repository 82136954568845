import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'formik';
import './BEDataManager.scss';
import PropTypes from 'prop-types';
import API from '@abl/components/src/api/api';
import {withStore} from '../../store';

const BEDataManager = props => {
  const isDevMode = process.env.REACT_APP_DEVMODE;

  const AddMinutes = (time, minutesToAdd) => {
    if (time !== undefined) {
      let pieces = time.split(':');
      let hour;
      let minute;

      if (pieces.length > 1) {
        hour = Number(pieces[0]);
        minute = Number(pieces[1]) + Number(minutesToAdd);
      }

      if (minute >= 60) {
        hour += Math.floor(minute / 60);
        if (hour > 23) hour = 0;
        minute %= 60;
      }

      let hourString = hour < 10 ? `0${hour}` : `${hour}`;
      let minuteString = minute < 10 ? `0${minute}` : `${minute}`;

      return `${hourString}:${minuteString}`;
    }

    return '00:00';
  };

  useEffect(() => {
    
    let route = props.location.pathname.split('/')[2];
    props.formik.setFieldValue('designerSelectedTab', route === "overview" ? route : "0");


    if (!isDevMode) {
      window.onbeforeunload = function() {
        return 'You have unsaved changes!';
      };
    }

    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 20) {
          if(props.formik.values.scrolling === false){
            props.formik.values.scrolling = true;
            props.formik.setFieldValue("scrolling",true);
          }
      }else if(props.formik.values.scrolling === true){
            props.formik.values.scrolling = false;
            props.formik.setFieldValue("scrolling",false);
      }
    }

    const getSchedule = async () => {
      let schedule = localStorage.getItem('schedule')
      const response = await API.get('schedules/' + schedule)
      return response.data;
    };

    getSchedule().then(value => {
      console.log(value);
      if(!value) return false;
      props.formik.setFieldValue('scheduleId', value.id);
      props.formik.setFieldValue('lunchesPerDay', value.lunchesPerDay);
      props.formik.setFieldValue('scheduleType', value.scheduleType);
      props.formik.setFieldValue('daysInSchoolYear', value.instructionalDays);
      props.formik.setFieldValue(
        'passingTimesInstructional',
        value.isPassingInstructional
      );
      props.formik.setFieldValue('isRotating', value.isRotating);
      props.formik.setFieldValue(
        'maxDailyOnSiteMinutes',
        value.maxDailyOnSiteMinutes === null ? '' : value.maxDailyOnSiteMinutes
      );
      props.formik.setFieldValue(
        'minAnnualMinutes',
        value.minAnnualMinutes === null ? '' : value.minAnnualMinutes
      );
      props.formik.setFieldValue(
        'minDailyMinutes',
        value.minDailyMinutes === null ? '' : value.minDailyMinutes
      );
      props.formik.setFieldValue('passingTime', value.passingDuration);
      props.formik.setFieldValue('periodDuration', value.periodDuration);
      props.formik.setFieldValue('scheduleType', value.scheduleType);
      props.formik.setFieldValue(
        'actualMinutesInYear',
        value.totalAnnualMinutes
      );

      let dayTypes = value.dayTypes;
      dayTypes.forEach((dayType, index) => {
        dayTypes[index].minutesInYear = dayTypes[index].minutesInYear || 0;
        dayTypes[index].lunchesPlaced = 0;
        dayTypes[index].lunchesPositions.forEach(lunch => {
          if(lunch !== "" && lunch !== -1) dayTypes[index].lunchesPlaced++;
        });

        
        dayTypes[index].lunchPassingDuration =
          dayTypes[index].lunchPassingDuration === null
            ? 5
            : dayTypes[index].lunchPassingDuration;

        let lunchPos = dayTypes[index].lunchesPlaced > 0 ? dayTypes[index].lunchesPositions[0] : -1;


        for (let i = 0; i < dayTypes[index].timeblocks.length; i++) {
          dayTypes[index].timeblocks[i].passingEndTime = AddMinutes(
            dayTypes[index].timeblocks[i].endTime,
            dayTypes[index].timeblocks[i].passingDuration
          );
        }

        let auxTime = dayTypes[index].timeblocks[0].startTime;

        for (let i = 0; i < dayTypes[index].timeblocks.length; i++) {
          if (i === lunchPos + 1) {
            dayTypes[index].timeblocks[i].startTime = AddMinutes(
              auxTime,
              Number(dayTypes[index].lunchDuration) +
                Number(dayTypes[index].lunchPassingDuration)
            );
          } else {
            dayTypes[index].timeblocks[i].startTime = auxTime;
          }

          dayTypes[index].timeblocks[i].endTime = AddMinutes(
            dayTypes[index].timeblocks[i].startTime,
            dayTypes[index].timeblocks[i].duration
          );
          dayTypes[index].timeblocks[i].passingEndTime = AddMinutes(
            dayTypes[index].timeblocks[i].endTime,
            dayTypes[index].timeblocks[i].passingDuration
          );
          auxTime = dayTypes[index].timeblocks[i].passingEndTime;
        }
        
        dayTypes[index].startTime = dayTypes[index].timeblocks[0].startTime;
        if(dayTypes[index].lunchesPlaced > 0) if(dayTypes[index].lunchesPositions[0] !== -1) dayTypes[index].lunchStartTime =
          dayTypes[index].timeblocks[dayTypes[index].lunchesPositions[0]].passingEndTime;
        dayTypes[index].lunchEndTime = AddMinutes(
          dayTypes[index].lunchStartTime,
          dayTypes[index].lunchDuration
        );
        dayTypes[index].lunchPassingEndTime = AddMinutes(
          dayTypes[index].lunchEndTime,
          dayTypes[index].lunchPassingDuration
        );

        let staffOnSiteMinutes = 0;
        let instructionalMinutes = 0;

        staffOnSiteMinutes +=
          Number(dayTypes[index].lunchDuration) +
          Number(dayTypes[index].lunchPassingDuration);

        for (let i = 0; i < dayTypes[index].timeblocks.length; i++) {
          staffOnSiteMinutes +=
            Number(dayTypes[index].timeblocks[i].duration) +
            Number(dayTypes[index].timeblocks[i].passingDuration);
          instructionalMinutes += Number(
            dayTypes[index].timeblocks[i].duration
          );
        }

        if (
          dayTypes[index].lunchesPositions[0] ===
          dayTypes[index].timeblocks.length - 1
        ) {
          staffOnSiteMinutes -= Number(dayTypes[index].lunchPassingDuration);
        } else {
          staffOnSiteMinutes -= Number(
            dayTypes[index].timeblocks[dayTypes[index].timeblocks.length - 1]
              .passingDuration
          );
        }

        dayTypes[index].totalStaffOnSiteMinutes = staffOnSiteMinutes;
        dayTypes[index].totalInstructionalMinutes = instructionalMinutes;
      });

      props.formik.setFieldValue('dayTypes', dayTypes);
      props.formik.setFieldValue('periods', value.periods);
      props.formik.setFieldValue('weeks', value.weeks);

      let backup = {
        dayType: {},
        weeks: [],
        deletedWeeks: []
      };

      Object.assign(backup.dayType, dayTypes[0]);
      backup.dayType.lunchesPositions = [];
      for (let i = 0; i < dayTypes[0].lunchesPositions.length; i++) {
        backup.dayType.lunchesPositions.push(dayTypes[0].lunchesPositions[i]);
      }

      backup.dayType.timeblocks = [];
      for (let i = 0; i < dayTypes[0].timeblocks.length; i++) {
        let timeblock = {};
        Object.assign(timeblock, dayTypes[0].timeblocks[i]);
        backup.dayType.timeblocks.push(timeblock);
      }

      for (let i = 0; i < value.weeks.length; i++) {
        let week = {};
        Object.assign(week, value.weeks[i]);
        backup.weeks.push(week);
      }

      props.formik.setFieldValue('backup', backup);
      props.formik.setFieldValue('initialized', true);

      

      // Props.formik.setFieldValue("periods",value.periods);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return <div className="bedatamanager"></div>;
};

BEDataManager.propTypes = {
  formik: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStore(connect(BEDataManager)));
