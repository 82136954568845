import React from 'react';
import './DeleteDayTypeModal.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';
import onClickOutside from "react-onclickoutside";
import API from '@abl/components/src/api/api';
import DeleteImage from '../../assets/trashcan.svg';


const DeleteDayTypeModal = props => {
	const {dayTypes, dayTypesAmount, selectedDayType, weeks} = props.formik.values;

    const onCancelFunction = () => {
        var modal = document.getElementById("delete_daytype_modal");
        modal.classList.remove("active");
    }

    const onDeleteDayTypeFunction = async () => {
      onCancelFunction();
      let dayTypesAmountAux = Number(dayTypesAmount);
      if(dayTypesAmount > 1){
         

          let dayTypeId = dayTypes[selectedDayType].id;
          

          for(let i = 0; i < dayTypes[selectedDayType].timeblocks.length; i++){
              // eslint-disable-next-line no-await-in-loop
              await API.delete(`timeblocks/${dayTypes[selectedDayType].timeblocks[i].id}`);
              console.log("timeblock deleted");
          }

          

          await API.delete(`day-types/${dayTypeId}`);
          console.log("dayType deleted");

          let dayTypesAux = dayTypes;

          weeks.forEach((week,windex) => {
              week.days.forEach( (day,dindex) => {

                  if(day === dayTypesAux[Number(selectedDayType)].id) {
                      weeks[windex].days[dindex] = -1;
                  }
              })
          })

          for(let i = 0; i < weeks.length; i++){
            // eslint-disable-next-line no-await-in-loop
            await API.put(`weeks/${weeks[i].id}`, weeks[i]);
            console.log("week updated");
        }
        
          props.formik.setFieldValue("weeks", weeks);

          props.formik.setFieldValue("dayTypesAmount", (dayTypesAmountAux - 1).toString());
          props.formik.setFieldValue("selectedDayType", 0);
          props.formik.setFieldValue("designerSelectedTab", "0");
          props.formik.setFieldValue("selectedDayTypeToDuplicate", 0);
          dayTypesAux.splice(selectedDayType,1);
          let backupDayType = {};

          backupDayType.name = dayTypesAux[0].name;
          backupDayType.lunchesPlaced = dayTypesAux[0].lunchesPlaced;
          backupDayType.lunches = dayTypesAux[0].lunches;
          backupDayType.lunchesPositions = [];
          for(let i = 0; i < dayTypesAux[0].lunchesPositions.length; i++){
            backupDayType.lunchesPositions.push(dayTypesAux[0].lunchesPositions[i]);
          }

          backupDayType.timeblocks = [];
          for(let i = 0; i < dayTypesAux[0].timeblocks.length; i++){
            let timeblock = {};
            Object.assign(timeblock, dayTypesAux[0].timeblocks[i]);
            backupDayType.timeblocks.push(timeblock);
          }

          props.formik.setFieldValue("backup.dayType", backupDayType);
          props.formik.setFieldValue("dayTypes", dayTypesAux);

          
          
          return true;
      }

      return false;

      
  }

    return (
      <div id="delete_daytype_modal" className="delete_daytype_modal">
          <KeyBindingEventComponent  keyname='esc' handlerFunction={onCancelFunction} />
        <div className="container window">
            <div className="title mt-4">
                Are you sure you want to delete this Day Type?
            </div>
            
            
            <div className="buttons_container mr-3 mb-4">
                <FunctionalButton
                    id = "send_btn"
                    className="next_btn delete_btn"
                    text="Delete Day Type"
                    imgpath ={DeleteImage}
                    onclickhandler={() => {
                      onDeleteDayTypeFunction().then((value) => {
                          if(value){
                              console.log("day deleted");
                          }
                          
                      });
                  }
                      }
                />
                <FunctionalButton
                    className="button_clickable_text_a cancel_btn"
                    text="Cancel"
                    onclickhandler={onCancelFunction}
                />
            </div>
        </div>
        <div className="overlay">
          
        </div>
      </div>
    );
}

DeleteDayTypeModal.propTypes = {
    formik: PropTypes.object.isRequired,
};



export default onClickOutside(connect(DeleteDayTypeModal), {
	handleClickOutside: function(instance){
	  return function(e) {
      var modal = document.getElementById("delete_daytype_modal");
      modal.classList.remove("active");
	}
}})