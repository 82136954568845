import React,{useEffect}  from 'react';
import './TimeLimitsForm.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"
import TooltipIcon from "../../assets/info_icon.svg"
import { connect } from "formik";

const TimeLimitsForm = props => {
  const { onCloseFunction, onSaveFunction } = props;
  const { actualMinutesInYear, selectedDayType, dayTypes} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
  const { totalStaffOnSiteMinutes, totalInstructionalMinutes } = dayType;
  

  useEffect(() => {
    var savebutton = document.getElementById("savebtn");
    let { daysInSchoolYear, minAnnualMinutes, minDailyMinutes, maxDailyOnSiteMinutes } = props.formik.values.errors;
    if (daysInSchoolYear || minAnnualMinutes || minDailyMinutes || maxDailyOnSiteMinutes) {
      savebutton.classList.add("disabled");
    }
    else {
      savebutton.classList.remove("disabled");
    }
  });
  return (
    <div id="timelimitsform" className="timelimitsform header">

      <div id="timecards" className="centeredbox_form d-flex flex-wrap justify-content-start">
        <div className="column flex-lg-grow-0 flex-sm-grow-1">
          <div className="d-flex">
            <div className="instr_min_title flex-glow-1">Minimum Instructional Min Per Year</div>           
            <Tooltipcomp imgpath={TooltipIcon} classnametooltip="instructionalyeartooltipeditor" tooltipmessage="This is the total number of instructional minutes per year across all day types." tooltipid="daytypeheadereditor_tooltipid" />          
          </div>
          <div className="actual_minutes"> {actualMinutesInYear} minutes</div>
          <div className="d-flex instr_min_title mb-5">
            <div className="pt-1">of</div>
            <div className="basicformfield-properties">   
                <BasicFormField
                      fieldName='minAnnualMinutes'
                      labelText=''
                      type='text'
                      optional="true"
                      errorMsg="Minutes must be between 1 and 525,600"
                      minValue={1}
                      maxValue={525600}
                      onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
                />     
            </div>   
            <div className="pt-1">min</div>

          </div>
        </div>
        <div className="separator"></div>
        <div className="column flex-lg-grow-0 flex-sm-grow-1">
          <div className="instr_min_title">Minimum Instructional Min Per Day</div>
          <div className="actual_minutes"> {totalInstructionalMinutes} minutes</div>
          <div className="row instr_min_title">
            <div className="pt-1">of</div>
            <div className="basicformfield-properties">   
              <BasicFormField
                fieldName='minDailyMinutes'
                labelText=''
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 1,440"
                minValue={1}
                maxValue={1440}
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
              />     
            </div>   
            <div className="pt-1">min</div>

          </div>
        </div>
        <div className="column flex-lg-grow-0 flex-sm-grow-1">
          <div className="instr_min_title">Maximum Staff Onsite Min Per Day</div>
          <div className="actual_minutes"> {totalStaffOnSiteMinutes} minutes</div>
          <div className="row instr_min_title">
            <div className="pt-1">of</div>
            <div className="basicformfield-properties">   
              <BasicFormField
                fieldName='maxDailyOnSiteMinutes'
                labelText=''
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 1,440"
                minValue={1}
                maxValue={1440}
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
              />     
            </div>   
            <div className="pt-1">min</div>

          </div>
        </div>
        <div className="separator d-none d-md-block"></div>
        <div className="column">
          <div className="instr_min_title">DAYS IN INSTRUCTIONAL SCHOOL YEAR</div>
         
          <div className="daysinstrschoolyear">   
              <BasicFormField
                    fieldName='daysInSchoolYear'
                    labelText=''
                    type='text'
                    optional="false"
                    backgroundText="days"
                    errorMsg="Days must be between 1 and 365"
                    minValue={1}
                    maxValue={365}
                    onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
              />
          </div>
          <div className="thisnumberaffects_text">This number affects our calculation for your Instructional Minutes Per Year</div>

          
        </div>
        
        
      </div>

      

      <div className="buttons_container d-flex mt-3">
      
        <div className="row">
          <FunctionalButton
            id="savebtn"
            className="button_type_a"
            text="Save"
            onclickhandler={onSaveFunction}
          />
      
          <FunctionalButton
            className="button_clickable_text_a"
            text="Cancel"
            onclickhandler={onCloseFunction}
          />
        </div>
        
        <div className="targetvaluestext">Changes to target values will apply to all day types</div>
      </div>

    </div>
  );
}

TimeLimitsForm.propTypes = {
  formik: PropTypes.object.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  onSaveFunction: PropTypes.func.isRequired,
};

export default connect(TimeLimitsForm);







