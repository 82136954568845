import React from 'react';
import './TemplateCard.scss';
import '../GeneralButton/Generalbutton';
import InfoPopUp from "@abl/components/src/InfoPopUp/InfoPopUp";
import LinkerButton from '@abl/components/src/LinkerButton/LinkerButton';
import PropTypes from 'prop-types';

const TemplateCard = ({ img_path, onClick, clickableMessageparam, link, btntext, linkRoute }) => ( // General component consisting of image+linker button+ button.
	<div className="TemplateCard">
		<img className="padding-image" src={img_path} alt="Card"></img>
		<InfoPopUp clickableMessage={clickableMessageparam} link={link} />
		<LinkerButton className="next_btn" toRoute={linkRoute} text={btntext} onClick={onClick} />
	</div>
);

TemplateCard.defaultProps = {
	linkRoute: '',
	onClick: () => { },
};

TemplateCard.propTypes = {
	img_path: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	clickableMessageparam: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	btntext: PropTypes.string.isRequired,
	linkRoute: PropTypes.string
};

export default TemplateCard;
