import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AvailableMinutesBox from '../AvailableMinutesBox/AvailableMinutesBox';
import './DayTypeEditor.scss';
import EditableDayType from '../EditableDayType/EditableDayType';
import DayTypeSettings from '../DayTypeSettings/DayTypeSettings';
import { connect } from "formik";
import API from '@abl/components/src/api/api';
import ReactGA from 'react-ga';


const DayTypeEditor = props => {
    const { selectedDayType , passingTimesInstructional, periods, dayTypes, daysInSchoolYear, askAfterEdit, selectedPeriodsToModify, passingTimeToApply, periodDurationToApply, takingOption, distributeOption, initialized, readyToExport} = props.formik.values;
    let {actualMinutesInYear, selectedMinuteChangeMode} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
    const timeblocksNum = dayType.timeblocks.length;
    let {selectedTimeblock} = props.formik.values;
    const {weeks} = props.formik.values;
    const weeksNum = weeks.length;

    const AddMinutes = (time, minutesToAdd) => {
        
        if(time !== undefined){
            let pieces = time.split(':');
		    let hour;
		    let minute;

            if(pieces.length > 1) {
                hour = Number(pieces[0]);
                minute = Number(pieces[1]) + Number(minutesToAdd);
            }

            if(minute >= 60){
                hour += Math.floor(minute / 60);
                if(hour > 23) hour = 0;
                minute %= 60;
            }

            let hourString = (hour < 10) ? `0${hour}`:`${hour}`;
            let minuteString = (minute < 10) ? `0${minute}`:`${minute}`;

            return `${hourString}:${minuteString}`
        }

        return "00:00";
    }

    const onSendFunction = async () => {

        
        props.formik.setFieldValue("readyToExport",false);

        let schedule = {
            totalAnnualMinutes: props.formik.values.actualMinutesInYear,
        }

        await API.put(`schedules/${props.formik.values.scheduleId}`, schedule);

        props.formik.values.dayTypes.forEach(async daytype => {
            daytype.lunchesPositions = daytype.lunchesPositions.filter((pos) => pos !== -1 && pos !== '').map((pos) => Number(pos));
            await API.put(`day-types/${daytype.id}`,daytype);
            return daytype;
        });
        
        await API.post("contact",{
            url:window.location.origin,
            firstName: props.formik.values.firstName,
            email: props.formik.values.email,
            lastName: props.formik.values.lastName,
            schoolName: props.formik.values.schoolName,
            state: props.formik.values.state,
            role: props.formik.values.role,
            scheduleId: props.formik.values.scheduleId,
        });

        
        ReactGA.event({
            category: "Conversion",
            action: "User submitted contact form",
        }); 
    }
    
    const StartMinutesChange = (timeblockId, mode) => {
        
        props.formik.setFieldValue("changeInProgress", true);

        let dayType =  dayTypes[selectedDayType];

        if(timeblockId === "all"){
            UpdateTimeblockTimes(0);
        }
        else{

            let startTime;
            let endTime;
            let lastDuration;
            let updatedDuration;

            props.formik.setFieldValue("selectedMinuteChangeMode",mode);
            selectedMinuteChangeMode = mode;

            switch (mode) {
                case "timeblock":
                    startTime = dayType.timeblocks[timeblockId].startTime;
                    endTime = dayType.timeblocks[timeblockId].endTime;
                    startTime = startTime.split(':');
                    endTime = endTime.split(':');
                    lastDuration = (Number(endTime[0]) * 60 + Number(endTime[1])) - (Number(startTime[0]) * 60 + Number(startTime[1]))
                    updatedDuration = Number(dayType.timeblocks[timeblockId].duration);    
                    
                    break;
                case "passing":
                    startTime = dayType.timeblocks[timeblockId].endTime;
                    endTime = dayType.timeblocks[timeblockId].passingEndTime;
                    startTime = startTime.split(':');
                    endTime = endTime.split(':');
                    lastDuration = (Number(endTime[0]) * 60 + Number(endTime[1])) - (Number(startTime[0]) * 60 + Number(startTime[1]))
                    updatedDuration = Number(dayType.timeblocks[timeblockId].passingDuration);    
                    
                    break;
                case "lunchblock":
                    startTime = dayType.lunchStartTime;
                    endTime = dayType.lunchEndTime;
                    startTime = startTime.split(':');
                    endTime = endTime.split(':');
                    lastDuration = (Number(endTime[0]) * 60 + Number(endTime[1])) - (Number(startTime[0]) * 60 + Number(startTime[1]))
                    updatedDuration = Number(dayType.lunchDuration);    
                    
                    break;
                case "lunchpassing":
                    startTime = dayType.lunchEndTime;
                    endTime = dayType.lunchPassingEndTime;
                    startTime = startTime.split(':');
                    endTime = endTime.split(':');
                    lastDuration = (Number(endTime[0]) * 60 + Number(endTime[1])) - (Number(startTime[0]) * 60 + Number(startTime[1]))
                    updatedDuration = Number(dayType.lunchPassingDuration);    
                    
                    break;
                default:
                    break;
            }

            props.formik.setFieldValue("selectedTimeblock",timeblockId);
            selectedTimeblock = timeblockId;

            
            if(updatedDuration > lastDuration){

                if(askAfterEdit){
                    props.formik.setFieldValue("minutesToAdd", updatedDuration - lastDuration);

                    props.formik.setFieldValue("noDistributeFunction",() => UpdateTimeblockTimes(selectedTimeblock));
                    props.formik.setFieldValue("distributeTimeblocksFunction",() => DistributeInTimeblocks("Take", updatedDuration - lastDuration));
                    props.formik.setFieldValue("distributePeriodsFunction",() => DistributeInPeriods("Take", updatedDuration - lastDuration));
                    props.formik.setFieldValue("distributePassingsFunction",() => DistributeInPassings("Take", updatedDuration - lastDuration));

                    var addingWindow = document.getElementById("addingminuteswindow");
                    addingWindow.classList.add("active");
                }
                else{

                    switch(takingOption){
                        case "noTake":
                            UpdateTimeblockTimes(selectedTimeblock);
                            break;
                        case "takeInstructional":
                            DistributeInTimeblocks("Take", updatedDuration - lastDuration);
                            break;
                        case "takePeriods":
                            DistributeInPeriods("Take", updatedDuration - lastDuration);
                            break;
                        case "takeNonInstructional":
                            DistributeInPassings("Take", updatedDuration - lastDuration);
                            break;
                        default:
                            break;
                    }
                }

                
            }
            else if (updatedDuration < lastDuration){

                if(askAfterEdit){
                    props.formik.setFieldValue("minutesToFree", lastDuration - updatedDuration);

                    props.formik.setFieldValue("noDistributeFunction",() => UpdateTimeblockTimes(selectedTimeblock));
                    props.formik.setFieldValue("distributeTimeblocksFunction",() => DistributeInTimeblocks("Distribute", lastDuration - updatedDuration));
                    props.formik.setFieldValue("distributePeriodsFunction",() => DistributeInPeriods("Distribute", lastDuration - updatedDuration));
                    props.formik.setFieldValue("distributePassingsFunction",() => DistributeInPassings("Distribute", lastDuration - updatedDuration));

                    var freeing = document.getElementById("freeingminuteswindow");
                    freeing.classList.add("active");
                }
                else{
                    
                    switch(distributeOption){
                        case "noDistribute":
                            UpdateTimeblockTimes(selectedTimeblock);
                            break;
                        case "distributeInstructional":
                            DistributeInTimeblocks("Distribute", lastDuration - updatedDuration);
                            break;
                        case "distributePeriods":
                            DistributeInPeriods("Distribute", lastDuration - updatedDuration);
                            break;
                        case "distributeNonInstructional":
                            DistributeInPassings("Distribute", lastDuration - updatedDuration);
                            break;
                        default:
                            break;
                    }
                }
            }
            else{
                props.formik.setFieldValue("changeInProgress", false);
            }
        }

    }

    const periodPosition = (periodId) => {
		for (let x = 0; x < periods.length; x++){
			if (periods[x].id === periodId) return x;
		}
		
		return 0;
	};


    const UpdateDayTypeMinutes = async () => {
        
        // Update selected day type used minutes per day.


        let staffOnSiteMinutes = 0;
        let instructionalMinutes = 0;
        
        staffOnSiteMinutes += (Number(dayType.lunchDuration) + Number(dayType.lunchPassingDuration));

        if(passingTimesInstructional){
            instructionalMinutes += Number(dayType.lunchPassingDuration);
        }

        let periodIndex = 0;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            periodIndex = periodPosition(dayType.timeblocks[i].periodId);
            staffOnSiteMinutes += (Number(dayType.timeblocks[i].duration) + Number(dayType.timeblocks[i].passingDuration));
            let passingTime = passingTimesInstructional? Number(dayType.timeblocks[i].passingDuration) : 0;
            let periodTime = periods[periodIndex].isInstructional ? Number(dayType.timeblocks[i].duration) : 0;
            instructionalMinutes += passingTime + periodTime;
        }


        if(Number(dayTypes[selectedDayType].lunchesPositions[0]) === dayTypes[selectedDayType].timeblocks.length - 1){
            staffOnSiteMinutes -= Number(dayTypes[selectedDayType].lunchPassingDuration);
        }else{
            staffOnSiteMinutes -= Number(dayTypes[selectedDayType].timeblocks[dayTypes[selectedDayType].timeblocks.length-1].passingDuration);
        }

        if(passingTimesInstructional){
            if(Number(dayTypes[selectedDayType].lunchesPositions[0]) === dayTypes[selectedDayType].timeblocks.length - 1){
                instructionalMinutes -= Number(dayTypes[selectedDayType].lunchPassingDuration);
            }else{
                instructionalMinutes -= Number(dayTypes[selectedDayType].timeblocks[dayTypes[selectedDayType].timeblocks.length-1].passingDuration);
            }
        }

        dayTypes[selectedDayType].totalInstructionalMinutes = instructionalMinutes;
        dayTypes[selectedDayType].totalStaffOnSiteMinutes = staffOnSiteMinutes;


        

        props.formik.setFieldValue(`dayTypes[${selectedDayType}].totalInstructionalMinutes`,instructionalMinutes);
        props.formik.setFieldValue(`dayTypes[${selectedDayType}].totalStaffOnSiteMinutes`,staffOnSiteMinutes);


        // Update all day types used minutes per year.

        let dayTypeMinutesInYear;
        let timePerCicle;

        for(let i = 0; i < dayTypes.length; i++){
            dayTypeMinutesInYear = 0;
            timePerCicle = 0;

            for(let j = 0; j < weeks.length; j++){
                for(let day = 0; day < 5; day++){
                    if(dayTypes[i].id === weeks[j].days[day]){
                        timePerCicle += dayTypes[i].totalInstructionalMinutes;
                    }
                }
            }

            dayTypeMinutesInYear = timePerCicle * (Number(daysInSchoolYear) / (weeks.length * 5));
           
            dayTypes[i].minutesInYear = dayTypeMinutesInYear;

            props.formik.setFieldValue(`dayTypes[${i}].minutesInYear`,dayTypeMinutesInYear);
        }

        let auxMinutesInYear = 0;
        
        for(let i = 0; i < dayTypes.length; i++){
            auxMinutesInYear += dayTypes[i].minutesInYear;
        }

        

        // eslint-disable-next-line react-hooks/exhaustive-deps

        

        if(actualMinutesInYear !== auxMinutesInYear){
            
            actualMinutesInYear = auxMinutesInYear;

            let schedule = {
                totalAnnualMinutes: actualMinutesInYear,
            }


            props.formik.setFieldValue(`actualMinutesInYear`,Math.round(actualMinutesInYear));
            await API.put(`schedules/${props.formik.values.scheduleId}`, schedule);
            console.log("schedule updated");
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        UpdateDayTypeMinutes();
        UpdateTimeblockTimes(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[weeks, weeksNum,timeblocksNum,selectedDayType, periods, passingTimesInstructional]);

    

    useEffect(() => {

        UpdateTimeblockTimes(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if(readyToExport) onSendFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[readyToExport]);

    useEffect(() => {
        UpdateTimeblockTimes(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDayType]);
  
    const UpdateTimeblockTimes = async (timeblockId) => {
        
        UpdateDayTypeMinutes();

        let auxTime = (timeblockId === 0) ? dayType.startTime || "00:00" : dayType.timeblocks[timeblockId - 1].passingEndTime;

        let lunchPos = ((dayType.lunchesPlaced <= 0 || dayType.lunchesPositions[0] === "" || dayType.lunchesPositions[0] === -1) ? -2 : Number(dayType.lunchesPositions[0]));
        
        for(let i = timeblockId; i < dayType.timeblocks.length; i++){
            if(i === lunchPos + 1){
                dayType.timeblocks[i].startTime = AddMinutes(auxTime, Number(dayType.lunchDuration) + Number(dayType.lunchPassingDuration));
            }
            else{
                dayType.timeblocks[i].startTime = auxTime;
            }

            dayType.timeblocks[i].endTime = AddMinutes(dayType.timeblocks[i].startTime, dayType.timeblocks[i].duration);
            dayType.timeblocks[i].passingEndTime = AddMinutes(dayType.timeblocks[i].endTime, dayType.timeblocks[i].passingDuration);
            auxTime = dayType.timeblocks[i].passingEndTime;
    
        }

        if(lunchPos !== -2){
            dayType.lunchStartTime = dayType.timeblocks[lunchPos].passingEndTime;
            dayType.lunchEndTime = AddMinutes(dayType.lunchStartTime,dayType.lunchDuration);
            dayType.lunchPassingEndTime = AddMinutes(dayType.lunchEndTime,dayType.lunchPassingDuration);

            if(initialized === true){
                dayType.lunchesPositions = dayType.lunchesPositions.map(lunch => lunch === "" ? -1:Number(lunch));
                await API.put(`day-types/${dayType.id}`, dayType);
                console.log("dayType updated")
            }
            

            props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchStartTime`,dayType.lunchStartTime);
            props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchEndTime`,dayType.lunchEndTime);
            props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchPassingEndTime`,dayType.lunchPassingEndTime);

        }

        if(initialized === true){

            const dayTypeRequests = dayType.timeblocks.map( async (timeblock)=>{
                return API.put(`timeblocks/${timeblock.id}`, timeblock);
            });

            await Promise.all(dayTypeRequests);
        }
        
        props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);

       
        props.formik.setFieldValue("changeInProgress", false);

    }

    const ModifyAll = (type) => {
        if(type === "passingDuration" && passingTimeToApply.length > 0){
            let newValue = Number(passingTimeToApply);
            props.formik.setFieldValue("passingTimeToApply","");
            if(!dayType.isLunchPassingLocked) dayType.lunchPassingDuration = newValue;

            for(let i = 0; i < dayType.timeblocks.length; i++){

                if(!dayType.timeblocks[i].isPassingLocked){
                    
                    dayType.timeblocks[i].passingDuration = newValue;
                }
            
            }

            props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchPassingDuration`,dayType.lunchPassingDuration);
            props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);

            UpdateTimeblockTimes(0);
        }
        else if(type === "periodDuration" && periodDurationToApply.length > 0){

            let newValue = Number(periodDurationToApply);
            props.formik.setFieldValue("periodDurationToApply","");
            if(!dayType.isLunchLocked) dayType.lunchDuration = newValue;

            for(let i = 0; i < dayType.timeblocks.length; i++){
                if(!dayType.timeblocks[i].isLocked){
                    dayType.timeblocks[i].duration = newValue;
                }
            
            }

            props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchDuration`,dayType.lunchDuration);
            props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);

            UpdateTimeblockTimes(0);
        }

        
       
    }

    const DistributeInTimeblocks = (mode, minutes) => {

        let totalMinutes = minutes;
        let sign;
        if(mode === "Distribute"){
            sign = 1;
        }
        else if(mode === "Take"){
            sign = -1;
        }

        let validTimeblocks = 0;

        let periodIndex = 0;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            periodIndex = periodPosition(dayType.timeblocks[i].periodId);

            if(periods[periodIndex].isInstructional && !dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblock" || selectedTimeblock !== i)){
                validTimeblocks++;
            }
        
        }


        let minutesForEachTimeblock = (validTimeblocks > totalMinutes)? 1 : Math.ceil(totalMinutes / validTimeblocks) ;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            periodIndex = periodPosition(dayType.timeblocks[i].periodId);

            if(periods[periodIndex].isInstructional && !dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblock" || selectedTimeblock !== i)){
                
                let minutes = 0;
                
                dayType.timeblocks[i].duration = Number(dayType.timeblocks[i].duration);

                if(minutesForEachTimeblock < totalMinutes){
                    minutes = minutesForEachTimeblock
                }
                else{
                    minutes = totalMinutes;
                }


                if(mode === "Take" && dayType.timeblocks[i].duration < minutes){
                    minutes = dayType.timeblocks[i].duration - 1;
                }

                dayType.timeblocks[i].duration += minutes * sign;

                totalMinutes -= minutes;
                if(totalMinutes <= 0) break;

            }
        
        }

        props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);

        UpdateTimeblockTimes(0);
    }

    // eslint-disable-next-line complexity
    const DistributeInPassings = (mode, minutes) => {

        let totalMinutes = minutes;
        let sign;
        if(mode === "Distribute"){
            sign = 1;
        }
        else if(mode === "Take"){
            sign = -1;
        }

        let validTimeblocks = 0;

        let periodIndex = 0;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            periodIndex = periodPosition(dayType.timeblocks[i].periodId);

            if(!periods[periodIndex].isInstructional && !dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblock" || selectedTimeblock !== i)){
                validTimeblocks++;
            }

            if(!dayType.timeblocks[i].isPassingLocked && (selectedMinuteChangeMode !== "passing" || selectedTimeblock !== i) && (i < dayType.timeblocks.length - 1 || Number(dayType.lunchesPositions[0]) === i)){
                validTimeblocks++;
            }
        
        }

        if(dayType.lunchesPositions[0] !== ""){
            if(!dayType.isLunchLocked)validTimeblocks++;
            if(!dayType.isLunchPassingLocked && Number(dayType.lunchesPositions[0]) !== dayType.timeblocks.length - 1)validTimeblocks++;
        }

        let minutesForEachTimeblock = (validTimeblocks > totalMinutes)? 1 : Math.ceil(totalMinutes / validTimeblocks) ;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            periodIndex = periodPosition(dayType.timeblocks[i].periodId);

            if(!periods[periodIndex].isInstructional && !dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblock" || selectedTimeblock !== i)){
                
                let minutes = 0;

                dayType.timeblocks[i].duration = Number(dayType.timeblocks[i].duration);

                if(minutesForEachTimeblock < totalMinutes){
                    minutes = minutesForEachTimeblock
                }
                else{
                    minutes = totalMinutes;
                }


                if(mode === "Take" && dayType.timeblocks[i].duration < minutes){
                    minutes = dayType.timeblocks[i].duration - 1;
                }

                dayType.timeblocks[i].duration += minutes * sign;

                totalMinutes -= minutes;
                if(totalMinutes <= 0) break;
                
            }

            if(!dayType.timeblocks[i].isPassingLocked && (selectedMinuteChangeMode !== "passing" || selectedTimeblock !== i) && (i < dayType.timeblocks.length - 1 || Number(dayType.lunchesPositions[0]) === i)){
                
                let minutes = 0;

                dayType.timeblocks[i].passingDuration = Number(dayType.timeblocks[i].passingDuration);

                if(minutesForEachTimeblock < totalMinutes){
                    minutes = minutesForEachTimeblock
                }
                else{
                    minutes = totalMinutes;
                }


                if(mode === "Take" && dayType.timeblocks[i].passingDuration < minutes){
                    minutes = dayType.timeblocks[i].passingDuration;
                }

                dayType.timeblocks[i].passingDuration += minutes * sign;

                totalMinutes -= minutes;
                if(totalMinutes <= 0) break;
                
            }

            if(i === Number(dayType.lunchesPositions[0])){
                if(selectedMinuteChangeMode !== "lunchblock" && !dayType.isLunchLocked){

                    let minutes = 0;

                    dayType.lunchDuration = Number(dayType.lunchDuration);

                    if(minutesForEachTimeblock < totalMinutes){
                        minutes = minutesForEachTimeblock
                    }
                    else{
                        minutes = totalMinutes;
                    }


                    if(mode === "Take" && dayType.lunchDuration < minutes){
                        minutes = dayType.lunchDuration - 1;
                    }

                    dayType.lunchDuration += minutes * sign;
                    props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchDuration`,dayType.lunchDuration);

                    totalMinutes -= minutes;
                    if(totalMinutes <= 0) break;

                }

                if(selectedMinuteChangeMode !== "lunchpassing" && !dayType.isLunchPassingLocked && Number(dayType.lunchesPositions[0]) !== dayType.timeblocks.length - 1){
                    
                    let minutes = 0;

                    dayType.lunchPassingDuration = Number(dayType.lunchPassingDuration);

                    if(minutesForEachTimeblock < totalMinutes){
                        minutes = minutesForEachTimeblock
                    }
                    else{
                        minutes = totalMinutes;
                    }


                    if(mode === "Take" && dayType.lunchPassingDuration < minutes){
                        minutes = dayType.lunchPassingDuration;
                    }

                    dayType.lunchPassingDuration += minutes * sign;
                    props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchPassingDuration`,dayType.lunchPassingDuration);

                    totalMinutes -= minutes;
                    if(totalMinutes <= 0) break;

                }
            }
        
        }

        props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);

        UpdateTimeblockTimes(0);
    }

    // eslint-disable-next-line complexity
    const DistributeInPeriods = (mode, minutes) => {

        let totalMinutes = minutes;
        let sign;
        if(mode === "Distribute"){
            sign = 1;
        }
        else if(mode === "Take"){
            sign = -1;
        }

        let validTimeblocks = 0;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            if(!dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblock" || selectedTimeblock !== i) && selectedPeriodsToModify[periodPosition(Number(dayType.timeblocks[i].periodId))] !== undefined){
                validTimeblocks++;
            }
        
        }

        if(selectedPeriodsToModify.lunch !== undefined){

            validTimeblocks++;
        }

        let minutesForEachTimeblock = (validTimeblocks > totalMinutes)? 1 : Math.ceil(totalMinutes / validTimeblocks) ;

        for(let i = 0; i < dayType.timeblocks.length; i++){

            if(!dayType.timeblocks[i].isLocked && (selectedMinuteChangeMode !== "timeblocks" || selectedTimeblock !== i) && selectedPeriodsToModify[periodPosition(Number(dayType.timeblocks[i].periodId))] !== undefined){
                
                let minutes = 0;

                dayType.timeblocks[i].duration = Number(dayType.timeblocks[i].duration);

                if(minutesForEachTimeblock < totalMinutes){
                    minutes = minutesForEachTimeblock
                }
                else{
                    minutes = totalMinutes;
                }


                if(mode === "Take" && dayType.timeblocks[i].duration < minutes){
                    minutes = dayType.timeblocks[i].duration - 1;
                }

                dayType.timeblocks[i].duration += minutes * sign;

                totalMinutes -= minutes;
                if(totalMinutes <= 0) break;
                
            }
            
            if(i === Number(dayType.lunchesPositions[0]) && selectedPeriodsToModify.lunch !== undefined){

                let minutes = 0;

                dayType.lunchDuration = Number(dayType.lunchDuration);

                if(minutesForEachTimeblock < totalMinutes){
                    minutes = minutesForEachTimeblock
                }
                else{
                    minutes = totalMinutes;
                }


                if(mode === "Take" && dayType.lunchDuration < minutes){
                    minutes = dayType.lunchDuration - 1;
                }

                dayType.lunchDuration += minutes * sign;
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchDuration`,dayType.lunchDuration);


                totalMinutes -= minutes;
                if(totalMinutes <= 0) break;
            }
        
        }

        props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);
        props.formik.setFieldValue("selectedPeriodsToModify",[]);


        UpdateTimeblockTimes(0);
    }



    return (
        <div className="day_type_editor">
            <AvailableMinutesBox actualMinutesInYear={actualMinutesInYear} />
            
            <div className="editor_container">
                <div className="info mt-4">
                    Edit start time, duration, timeblock order, or timeblock names. Find more adjustments in “Change Settings”.
                </div>
                <div className="row day_editor">
                    <div id="day_type_settings" className="day_type_settings_container">
                        <DayTypeSettings modifyAllFunction = {ModifyAll} onDeleteFunction={() => {
                            var modal = document.getElementById("delete_daytype_modal");
                            modal.classList.add("active");
                        }
                            } onTimeblocksChangeFunction = {StartMinutesChange} onMinutesChangeFunction = {UpdateDayTypeMinutes} />
                    </div>
                    <div className="editor_col">
                        <EditableDayType onTimeblocksChangeFunction = {StartMinutesChange} />
                    </div>
                    

                </div>
            </div>
        </div>
    );
}

DayTypeEditor.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default connect(DayTypeEditor);