import React from 'react';
import './ThankYouWindow.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
var parse = require('html-react-parser');

const ThankYouWindow = props => {

    
    const onCancelFunction = () => {
        var form = document.getElementById("thankyou_window");
        form.classList.remove("active");
    }

    const ExportBtnHandler = () => {
        window.location.href = props.restartRoute;
    }
    

    return (
      <div id="thankyou_window" className="thankyou_window">
        <div className="window">
            
            <div className="title">
                Thank You
            </div>
            <div className="subtitle">
                {props.subText}
            </div>

            <div className="otherToolText">
                {parse(props.otherToolText)}
            </div>

            <div className="tryapptext">
                {props.tryAppText}
            </div>

            <div className="buttons_container mr-3 mb-4">
           
                <a href={props.otherAppUrl} className="back_btn otherappbutton-properties link-pointer" >
                    {props.otherAppBtnText}
                </a>
                <FunctionalButton text={props.restartText} onclickhandler={ExportBtnHandler} className=" back_btn startnewschedulebtn-properties link-pointer"  />

            </div>
            <FunctionalButton text="Close" onclickhandler={onCancelFunction} className=" next_btn close_btn" />
        </div>
        
        <div className="overlay">
          
        </div>
      </div>
    );
}

ThankYouWindow.propTypes = {
    subText: PropTypes.string.isRequired,
    restartText: PropTypes.string.isRequired,
    restartRoute: PropTypes.string.isRequired,
    otherToolText: PropTypes.string,
    otherAppBtnText: PropTypes.string,
    tryAppText: PropTypes.string,
    otherAppUrl: PropTypes.string


};


export default ThankYouWindow;


