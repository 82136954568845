import React from 'react';
import { connect } from "formik";
import PropTypes from 'prop-types';
import './EditableTimeblock.scss';
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import LockedImage from '../../assets/locked.svg';
import UnlockedImage from '../../assets/unlocked.svg';
import DeleteImage from '../../assets/trashcan.svg';
import GrabbyBar from '../../assets/grabby_bar.svg';
import TimeTag from '../TimeTag/TimeTag';
import TooltipComp from '@abl/components/src/ToolTip/Tooltip';
import StartTimeEditor from '../StartTimeEditor/StartTimeEditor';
import API from '@abl/components/src/api/api';


// eslint-disable-next-line complexity
const EditableTimeblock = props => {
	const { isLunch, dragTimeblockFunction, className, isTheFirstTimeblock, isTheLastTimeblock, id, updateTimeblockTimes, dragging } = props;
	const {timeblockId} = props;
	const { periods, selectedDayType, hoveredTimeblock, selectedTimeblock, dayTypes, changeInProgress} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
	const {timeblocks} = dayType;


	const periodPosition = (periodId) => {
		for (let x = 0; x < periods.length; x++){
			if (periods[x].id === periodId) return x;
		}
		
		return 0;
	};

	let periodIndex = (isLunch === "true")?undefined:periodPosition(timeblocks[timeblockId].periodId);
	const timeblock = (isLunch === "true")?
		{
		duration: dayType.lunchDuration,
		passingDuration: dayType.lunchPassingDuration,
		isLocked: dayType.isLunchLocked,
		isPassingLocked: dayType.isLunchPassingLocked,
		startTime:  dayType.lunchStartTime,
		endTime: dayType.lunchEndTime,
		}
		:
		timeblocks[timeblockId];
	

	const fieldNames = (isLunch === "true")?
	{
		isLocked: `dayTypes[${selectedDayType}].isLunchLocked`,
		isPassingLocked: `dayTypes[${selectedDayType}].isLunchPassingLocked`,
		duration: `dayTypes[${selectedDayType}].lunchDuration`,
		passingDuration: `dayTypes[${selectedDayType}].lunchPassingDuration`
	}
	:
	{
		isInstructional: `periods[${periodIndex}].isInstructional`,
		isLocked: `dayTypes[${selectedDayType}].timeblocks[${timeblockId}].isLocked`,
		isPassingLocked: `dayTypes[${selectedDayType}].timeblocks[${timeblockId}].isPassingLocked`,
		name: `periods[${periodIndex}].name`,
		duration: `dayTypes[${selectedDayType}].timeblocks[${timeblockId}].duration`,
		passingDuration: `dayTypes[${selectedDayType}].timeblocks[${timeblockId}].passingDuration`,
		periodIDFieldName: `dayTypes[${selectedDayType}].timeblocks[${timeblockId}].periodId`
	}

	let color = (isLunch === "true")?
		{
			backgroundColor: "#E4E5EB" + ((hoveredTimeblock === "Lunch" || (dragging && selectedTimeblock === timeblockId)) ? "FF" : "B2")
		}
		:
		{
			backgroundColor: periods[periodIndex].color + ((hoveredTimeblock === timeblockId || (dragging && selectedTimeblock === timeblockId)) ? "FF" : "B2")
		};

	let passingColor = (isLunch === "true")?
		{
			backgroundColor: "#E4E5EB" + ((hoveredTimeblock === "Lunch" || (dragging && selectedTimeblock === timeblockId)) ? "FF" : "B2")
		}
		:
		{
			backgroundColor: "#E4E5EB" + ((hoveredTimeblock === timeblockId || (dragging && selectedTimeblock === timeblockId)) ? "FF" : "B2")
		};
		

	const handleInstructionalChange = async (e) => {
		let checkbox = e.target;

		if (checkbox.checked === true) {
		props.formik.setFieldValue(fieldNames.isInstructional, true);
		periods[periodIndex].isInstructional = true;
		} else {
		props.formik.setFieldValue(fieldNames.isInstructional, false);
		periods[periodIndex].isInstructional = false;
		}

		await API.put(`periods/${periods[periodIndex].id}`, periods[periodIndex]);
		console.log("period updated")
	}

	const savePeriodName = async () => {
		await API.put(`periods/${periods[periodIndex].id}`, periods[periodIndex]);
		console.log("period updated")
	}

	const onLockClick = async () => {
		

		if(isLunch === "true"){
			dayType.isLunchLocked = !dayType.isLunchLocked;
			props.formik.setFieldValue(fieldNames.isLocked, dayType.isLunchLocked);
			timeblock.isLocked = !dayType.isLunchLocked;

			dayType.lunchesPositions = dayType.lunchesPositions.map(lunch => lunch === "" ? -1:Number(lunch));
			await API.put(`day-types/${dayType.id}`,dayType);
			console.log("daytype updated")
		}
		else{
			dayType.timeblocks[timeblockId].isLocked = !dayType.timeblocks[timeblockId].isLocked;
			props.formik.setFieldValue(fieldNames.isLocked, dayType.timeblocks[timeblockId].isLocked);
			timeblock.isLocked = dayType.timeblocks[timeblockId].isLocked;
		}

	}

	const onPassingLockClick = async () => {

		if(isLunch === "true"){
			dayType.isLunchPassingLocked = !dayType.isLunchPassingLocked;
			props.formik.setFieldValue(fieldNames.isPassingLocked, dayType.isLunchPassingLocked);
			timeblock.isPassingLocked = !dayType.isLunchPassingLocked;

			dayType.lunchesPositions = dayType.lunchesPositions.map(lunch => lunch === "" ? -1:Number(lunch));
			await API.put(`day-types/${dayType.id}`,dayType);
			console.log("daytype updated")
		}
		else{
			dayType.timeblocks[timeblockId].isPassingLocked = !dayType.timeblocks[timeblockId].isPassingLocked;
			props.formik.setFieldValue(fieldNames.isPassingLocked, dayType.timeblocks[timeblockId].isPassingLocked);
			timeblock.isPassingLocked = dayType.timeblocks[timeblockId].isPassingLocked;
		}

	}

	function getOffset(el) {
		const rect = el.getBoundingClientRect();
		return {
		  left: rect.left + window.scrollX,
		  top: rect.top + window.scrollY
		};
	  }

	const OnClickArrow = (e) => {
		props.formik.setFieldValue("selectedTimeblock",timeblockId);
		
		let periodsSelector = document.getElementById("period_selector_window");
		if(periodsSelector.classList.contains("active")){
			props.formik.setFieldValue("modifyingTimeblockPeriod",false);
			periodsSelector.classList.remove("active");
		}
		else{
			var rect = getOffset(e.target);
			periodsSelector.style.top = `${rect.top - 80}px`;
			props.formik.setFieldValue("modifyingTimeblockPeriod",true);
			periodsSelector.classList.add("active"); 
		}

	}

	let finalTimeblockId = (id.length > 0)? id: `editabletimeblock_${(isLunch === "true") ? "lunch" : timeblockId}`;
	

	const TimeblockHover = () => {
		if(!dragging){
			props.formik.setFieldValue("hoveredTimeblock", (isLunch === "true")? "Lunch" : timeblockId)
		}
	}

	const GetDeleteButton = () => {
		if(timeblocks.length > 1 && isLunch === "false"){
			return <div className="delete_btn">

						<div>
							<div href="#" id={ `timeblock_${finalTimeblockId}` } >
								<FunctionalButton onclickhandler={()=>{
									props.formik.setFieldValue("selectedTimeblock", timeblockId);
									var modal = document.getElementById("delete_timeblock_modal");
									modal.classList.add("active");
								}} imgpath={DeleteImage} />
							</div>
							<TooltipComp tooltipmessage="Delete timeblock" idcomponent={ `timeblock_${finalTimeblockId}` }></TooltipComp>
						</div>						
					
					
					</div>
		}
 
		return "";
						
	}

	const openSettingsPanel = () => {
		var settings = document.getElementById("daytype_settings");
		var open = document.getElementById("open");
		var close = document.getElementById("close");
	
		settings.classList.add("displayed");
		close.classList.add("displayed");
		open.classList.remove("displayed");

		document.getElementById("firstLunchPositionSelector").focus();
		
	  }

	return (
		<div 
			id={finalTimeblockId} 
			className={`editabletimeblock positioned${(className.length > 0)?` ${className}`:''}`} 
			onMouseOver = {TimeblockHover}
			>
			{(isLunch === "true")?
				""
				:
				<div data-movable-handle className="drag_btn" onMouseDown={() => dragTimeblockFunction(timeblockId) }>
					<img src={GrabbyBar} alt="grab img"></img>
				</div>
			}
			{(isTheFirstTimeblock === "true" && !dragging)?
				<StartTimeEditor 
					onBlurFunction = {() => updateTimeblockTimes("all")}
				/>
			:
				<TimeTag time={timeblock.startTime} />
			}
			<div className="timeblock row">
				<div style = {color} className="timeblock_body">
					
					{GetDeleteButton()}
					
					<div className="timeblock_name">
						{(isLunch === "true")?
						<div className="lunch_timeblock row">
							<div className ="lunch">Lunch</div>
							<div className ="info" onClick={openSettingsPanel}>
								Edit In Settings
							</div>
						</div>
						:
						<div>
							<div className="dropdown_btn" onMouseDown={OnClickArrow}>

							</div>
							<BasicFormField
								key={`name_${finalTimeblockId}`}
								id = {`name_${finalTimeblockId}`}
								isNumber = {false}
								fieldName={fieldNames.name}
								type='text'
								onKeyPressFunction={(event) => { 
									if(event.keyCode === 32){
										props.formik.setFieldValue(fieldNames.name, event.target.value + " ");
									}

									if (event.target.value.length > 20){
										event.preventDefault()
								}}}
								onBlurFunction={() => savePeriodName()}
							/>
						</div>
						}
					</div>
					{(isLunch === "true")?
						""
						:
						<div className="is_instructional row">
						<input type="checkbox" name={`${finalTimeblockId}`} value="instructional" checked={(periods[periodIndex].isInstructional === true) ? "checked" : ""} onChange={(e) => handleInstructionalChange(e)} /><p className="text"> Instructional </p>
						</div>
					}
				</div>
				<hr />
				<div className="duration row">
				<BasicFormField
					key={`duration_${finalTimeblockId}`}
					notEmpty
					// eslint-disable-next-line react/jsx-boolean-value
					shouldHighlightOnChange={selectedTimeblock !== timeblockId}
					changingValue={`${dayType.timeblocks[timeblockId].duration}`}
					fieldName={fieldNames.duration}
					type='text'
					optional="true"
					errorMsg="Minutes must be between 1 and 400"
					minValue={1}
					maxValue={400}
					// eslint-disable-next-line react/jsx-boolean-value
					isDuration={true}
					onBlurFunction = {() => {
						if(!changeInProgress){
							updateTimeblockTimes(timeblockId,(isLunch === "true") ? "lunchblock" : "timeblock")
						} 
					}}
					onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || (event.target.value.length > 2 && event.target.selectionStart === event.target.selectionEnd) || changeInProgress) event.preventDefault() }}
					onClickHandler={(e) => { 
						if(changeInProgress){
							e.preventDefault();
						}
					}}
				/>
				<div className="min_text">min</div>
				</div>
				<div className="lock">
				<FunctionalButton id={`period_lock_${finalTimeblockId}`} className = "lock_btn" onclickhandler={onLockClick} />
				{(timeblock.isLocked === true)?
					<div className="lock_img_tooltip" >
						<img className="lock_img img_active" src={LockedImage} alt=""></img>
					</div>
					:
					<div className="lock_img_tooltip" >
						<img className="lock_img img_active" src={UnlockedImage} alt=""></img>
					</div>	
				}

				
				{(window.innerWidth <= 885)? 
					<TooltipComp 
						tooltipmessage={(timeblock.isLocked === false)?
								"Lock duration to prevent minutes from being redistributed"
								:
								"Unlock duration so minutes can be redistributed"
							} 
						idcomponent= {
							`period_lock_${finalTimeblockId}`
						} 
						tooltipplacement="bottom"
					/>
				:
					<TooltipComp 
						tooltipmessage={(timeblock.isLocked === false)?
								"Lock duration to prevent minutes from being redistributed"
								:
								"Unlock duration so minutes can be redistributed"
							} 
						idcomponent= {
							`period_lock_${finalTimeblockId}`
						} 
					/>
				}

				
				</div>
				
			</div>
			<div className="end_time_tag">
				<TimeTag time={timeblock.endTime} />
			</div>
			

			

			{(isTheLastTimeblock === "false" || dragging)?
				<div className="passing_time row">
					<div style={passingColor} className="passing_time_body">
						Passing Time
					</div>
					<hr />
					<div className="duration row">
						<BasicFormField
							key={`passingtime_${finalTimeblockId}`}
							notEmpty
							// eslint-disable-next-line react/jsx-boolean-value
							shouldHighlightOnChange={selectedTimeblock !== timeblockId}
							changingValue={`${dayType.timeblocks[timeblockId].passingDuration}`}
							fieldName={fieldNames.passingDuration}
							type='text'
							optional="false"
							errorMsg="Minutes must be between 0 and 60"
							minValue={0}
							maxValue={60}
							// eslint-disable-next-line react/jsx-boolean-value
							isDuration={true}
							onBlurFunction = {() => {
								if(!changeInProgress) updateTimeblockTimes(timeblockId,(isLunch === "true") ? "lunchpassing" : "passing")
							}}
							onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || (event.target.value.length > 2 && event.target.selectionStart === event.target.selectionEnd) || changeInProgress) event.preventDefault() }}
							onClickHandler={(e) => { 
								if(changeInProgress){
									e.preventDefault();
								}
							}}
						/>
						<div className="min_text">min</div>
					</div>
					<div className="lock">
						<FunctionalButton id={`passing_lock_${finalTimeblockId}`} className = "lock_btn" onclickhandler={onPassingLockClick} />
						{(timeblock.isPassingLocked === true)?
							<div className="lock_img_tooltip" >
								<img className="lock_img img_active" src={LockedImage} alt=""></img>
							</div>
							:
							<div className="lock_img_tooltip">
								<img className="unlock_img img_active" src={UnlockedImage} alt=""></img>
							</div>
						}


						{(window.innerWidth <= 885)? 
							<TooltipComp 
								tooltipmessage={(timeblock.isPassingLocked === false)?
										"Lock duration to prevent minutes from being redistributed"
										:
										"Unlock duration so minutes can be redistributed"
									} 
								idcomponent= {
									`passing_lock_${finalTimeblockId}`
								} 
								tooltipplacement="bottom"
							/>
						:
							<TooltipComp 
								tooltipmessage={(timeblock.isPassingLocked === false)?
										"Lock duration to prevent minutes from being redistributed"
										:
										"Unlock duration so minutes can be redistributed"
									} 
								idcomponent= {
									`passing_lock_${finalTimeblockId}`
								} 
							/>
						}

						
					</div>
				</div>
			:
				""
			}
		</div>
	);
}

EditableTimeblock.defaultProps = {
	timeblockId: -1,
	dragTimeblockFunction: () => {},
	className: "",
	isTheLastTimeblock: "false",
	id: "",
	isTheFirstTimeblock: "false",
	updateTimeblockTimes: (timeblockId) => {},
};

EditableTimeblock.propTypes = {
	formik: PropTypes.object.isRequired,
	timeblockId: PropTypes.number,
	isLunch: PropTypes.string.isRequired,
	dragTimeblockFunction: PropTypes.func,
	className: PropTypes.string,
	isTheLastTimeblock: PropTypes.string,
	id: PropTypes.string,
	isTheFirstTimeblock: PropTypes.string,
	updateTimeblockTimes: PropTypes.func,
	dragging: PropTypes.bool.isRequired
};

export default connect(EditableTimeblock);
