import React, {useEffect} from 'react';
import { connect } from "formik";
import PropTypes from 'prop-types';
import './WeekDayTypesSelector.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import DeleteImage from '../../assets/trashcan.svg';
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"


// eslint-disable-next-line complexity
const WeekDayTypesSelector = props => {
  const { weekID, canDelete, onMinutesChangeFunction } = props;
  const {selectedDayType, dayTypes} = props.formik.values;
  const week = props.formik.values.weeks[weekID];


  const getDayTypeIndex = (dayTypeId) => {
    for(let i = 0; i < dayTypes.length; i++){
      if(dayTypeId === dayTypes[i].id){
        
        return i;
      } 
    }

    return -1;
  }
 

  const handleChange = ( day ) => {
    if(week.days[day] === dayTypes[selectedDayType].id || week.days[day] === -1){
      if(week.days[day] === dayTypes[selectedDayType].id){
        props.formik.setFieldValue(`weeks[${weekID}].days[${day}]`,-1);
      }
      else{
        props.formik.setFieldValue(`weeks[${weekID}].days[${day}]`,dayTypes[selectedDayType].id);
      }
  
    }
  }

  const onDelete = (e) => {
    let {weeks, backup} = props.formik.values;
    const deletedWeek = weeks.splice(weekID,1)[0];

    backup.deletedWeeks.push(deletedWeek);
    props.formik.setFieldValue("backup.deletedWeeks", backup.deletedWeeks);

    props.formik.setFieldValue("weeks", weeks);
    onMinutesChangeFunction();
  }

  useEffect(() => {
    var Mon = document.getElementById(`Mon_${weekID}`);
    var MonName = document.getElementById(`Mon_name_${weekID}`);
    var tooltipMon = document.getElementById(`tooltip_Mon_${weekID}`);
    if(week.days[0] === dayTypes[selectedDayType].id || week.days[0] === -1){
      Mon.disabled = false;
      MonName.classList.remove("disabled_day_name");
      tooltipMon.classList.remove("checkbox_disabled");

    }
    else{
      MonName.classList.add("disabled_day_name");
      Mon.disabled = true;
      tooltipMon.classList.add("checkbox_disabled");
      
    }

    var Tues = document.getElementById(`Tues_${weekID}`);
    var TuesName = document.getElementById(`Tues_name_${weekID}`);
    var tooltipTues = document.getElementById(`tooltip_Tues_${weekID}`);

    if(week.days[1] === dayTypes[selectedDayType].id || week.days[1] === -1){
      Tues.disabled = false;
      TuesName.classList.remove("disabled_day_name");
      tooltipTues.classList.remove("checkbox_disabled");

    }
    else{
      Tues.disabled = true;
      TuesName.classList.add("disabled_day_name");
      tooltipTues.classList.add("checkbox_disabled");

    }

    var Wed = document.getElementById(`Wed_${weekID}`);
    var WedName = document.getElementById(`Wed_name_${weekID}`);
    var tooltipWed = document.getElementById(`tooltip_Wed_${weekID}`);

    if(week.days[2] === dayTypes[selectedDayType].id || week.days[2] === -1){
      Wed.disabled = false;
      WedName.classList.remove("disabled_day_name");
      tooltipWed.classList.remove("checkbox_disabled");

    }
    else{
      Wed.disabled = true;
      WedName.classList.add("disabled_day_name");
      tooltipWed.classList.add("checkbox_disabled");

    }

    var Thurs = document.getElementById(`Thurs_${weekID}`);
    var ThursName = document.getElementById(`Thurs_name_${weekID}`);
    var tooltipThurs = document.getElementById(`tooltip_Thurs_${weekID}`);

    if(week.days[3] === dayTypes[selectedDayType].id || week.days[3] === -1){
      Thurs.disabled = false;
      ThursName.classList.remove("disabled_day_name");
      tooltipThurs.classList.remove("checkbox_disabled");

    }
    else{
      Thurs.disabled = true;
      ThursName.classList.add("disabled_day_name");
      tooltipThurs.classList.add("checkbox_disabled");

    }

    var Fri = document.getElementById(`Fri_${weekID}`);
    var FriName = document.getElementById(`Fri_name_${weekID}`);
    var tooltipFri = document.getElementById(`tooltip_Fri_${weekID}`);

    if(week.days[4] === dayTypes[selectedDayType].id || week.days[4] === -1){
      Fri.disabled = false;
      FriName.classList.remove("disabled_day_name");
      tooltipFri.classList.remove("checkbox_disabled");

    }
    else{
      Fri.disabled = true;
      FriName.classList.add("disabled_day_name");
      tooltipFri.classList.add("checkbox_disabled");

    }

  });

  function addPaddingStyle(number) {
    let style = {
      paddingRight: `${(weekID>=9)?'15':'21'}px`,    
    };
    return style;
  }

  return (
    
    <div className="weekdaytypesselector">
      <div className="row">

        <div style={addPaddingStyle(weekID)} className="week_name">
          {`WEEK ${weekID + 1}`}
        </div>

      <div  id={`tooltip_Mon_${weekID}`} className="row eventsall" >       
          <div  className="row checkbox_container" onClick={() => handleChange(0)} >     
              <input className="eventsnone"
                id = {`Mon_${weekID}`}
                type="checkbox"
                value={week.days[0] !== -1 && week.days[0] !== ""} 
                checked={week.days[0] !== -1 && week.days[0] !== ""}  
                onChange={() => handleChange(0)} 
                />   
              <div id={`Mon_name_${weekID}`} className="day_name eventsnone">Mon</div>                
          </div>    
          
      </div>
      <Tooltipcomp  idcomponent={`tooltip_Mon_${weekID}`}  tooltipmessage={(week.days[0] === -1 || week.days[0] === dayTypes[selectedDayType].id ) ? "" : `${dayTypes[getDayTypeIndex(week.days[0])].name} Day Type is assigned to this day`} tooltipplacement="top"  />
     

        <div id={`tooltip_Tues_${weekID}`} className="row eventsall">
          <div className="row checkbox_container" onClick={() => handleChange(1)}  >
            <input className="eventsnone"
              id = {`Tues_${weekID}`}
              type="checkbox" 
              value={week.days[1] !== -1 && week.days[1] !== ""} 
              checked={week.days[1] !== -1 && week.days[1] !== ""} 
              onChange={() => handleChange(1)} />
            <div id={`Tues_name_${weekID}`} className="day_name eventsnone">Tues</div>
          </div>
          
        </div>
        <Tooltipcomp  idcomponent={`tooltip_Tues_${weekID}`}  tooltipmessage={(week.days[1] === -1 || week.days[1] === dayTypes[selectedDayType].id ) ? "" : `${dayTypes[getDayTypeIndex(week.days[1])].name} Day Type is assigned to this day`} tooltipplacement="top" />

        <div id={`tooltip_Wed_${weekID}`} className="row eventsall">
          <div className="row checkbox_container" onClick={() => handleChange(2)}>
            <input className="eventsnone"
              id = {`Wed_${weekID}`}
              type="checkbox" 
              value={week.days[2] !== -1 && week.days[2] !== ""} 
              checked={week.days[2] !== -1 && week.days[2] !== ""} 
              onChange={() => handleChange(2)} />
              <div id={`Wed_name_${weekID}`} className="day_name eventsnone">Wed</div>
          </div>   
          
        </div>
        <Tooltipcomp  idcomponent={`tooltip_Wed_${weekID}`}  tooltipmessage={(week.days[2] === -1 || week.days[2] === dayTypes[selectedDayType].id )? "" : `${dayTypes[getDayTypeIndex(week.days[2])].name} Day Type is assigned to this day`} tooltipplacement="top" /> 

        <div id={`tooltip_Thurs_${weekID}`} className="row eventsall">
          <div className="row checkbox_container" onClick={() => handleChange(3)}>
            <input  className="eventsnone"
              id = {`Thurs_${weekID}`}
              type="checkbox" 
              value={week.days[3] !== -1 && week.days[3] !== ""} 
              checked={week.days[3] !== -1 && week.days[3] !== ""} 
              onChange={() => handleChange(3)} />
            <div id={`Thurs_name_${weekID}`} className="day_name eventsnone">Thurs</div>
          </div>         
        </div>
        <Tooltipcomp  idcomponent={`tooltip_Thurs_${weekID}`}  tooltipmessage={(week.days[3] === -1 || week.days[3] === dayTypes[selectedDayType].id ) ? "" : `${dayTypes[getDayTypeIndex(week.days[3])].name} Day Type is assigned to this day`} tooltipplacement="top" />

        <div id={`tooltip_Fri_${weekID}`} className="row eventsall">
          <div className="row checkbox_container" onClick={() => handleChange(4)}>
            <input className="eventsnone"
              id = {`Fri_${weekID}`}
              type="checkbox" 
              value={week.days[4] !== -1 && week.days[4] !== ""} 
              checked={week.days[4] !== -1 && week.days[4] !== ""} 
              onChange={() => handleChange(4)} />
            <div id={`Fri_name_${weekID}`} className="day_name eventsnone">Fri</div>
          </div>
          
        </div>
        <Tooltipcomp  idcomponent={`tooltip_Fri_${weekID}`}  tooltipmessage={(week.days[4] === -1 || week.days[4] === dayTypes[selectedDayType].id ) ? "" : `${dayTypes[getDayTypeIndex(week.days[4])].name} Day Type is assigned to this day`} tooltipplacement="top" />


        {canDelete === "true" ?
          <FunctionalButton className="delete_btn" imgpath={DeleteImage} onclickhandler={onDelete} />
        :
          ""
        }
      </div>
    </div>
  );
}

WeekDayTypesSelector.defaultProps = {
  canDelete: "true",
};

WeekDayTypesSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  weekID: PropTypes.number.isRequired,
  canDelete: PropTypes.string,
  onMinutesChangeFunction: PropTypes.func.isRequired,
};
export default connect(WeekDayTypesSelector);

