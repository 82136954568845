import React from 'react';
import './PeriodMultiSelector.scss';
import { connect, Field } from "formik";
import PropTypes from 'prop-types';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import SelectedPeriodToModifyBox from '../SelectedPeriodToModifyBox/SelectedPeriodToModifyBox';
import onClickOutside from "react-onclickoutside";

const PeriodMultiSelector = props => {
	let {selectedPeriodsToModify, selectedDayType, dayTypes, periods} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
	const {id, disabled} = props;


	const OnPeriodClick = (periodId) => {
		if(selectedPeriodsToModify[periodId] === undefined){
			selectedPeriodsToModify[periodId] = periodId;
			props.formik.setFieldValue(`selectedPeriodsToModify`,selectedPeriodsToModify);
		}
		else{
			selectedPeriodsToModify[periodId] = selectedPeriodsToModify[periodId] === undefined ? periodId : undefined;
			props.formik.setFieldValue(`selectedPeriodsToModify`,selectedPeriodsToModify);
		}

	}

	const periodPosition = (periodId) => {
		for (let x = 0; x < periods.length; x++){
			if (periods[x].id === periodId) return x;
		}
		
		return 0;
	};
	

	const clickHandler = () => {
        var selector = document.getElementById(id);
        if(selector.classList.contains("active")){
            selector.classList.remove("active");
        } 
        else{
            selector.classList.add("active");
        }
	}

	PeriodMultiSelector.Close = () => {
		var aselector = document.getElementById("adding_period_multiselector");
		var fselector = document.getElementById("freeing_period_multiselector");
        aselector.classList.remove("active");
        fselector.classList.remove("active");
	}
	
	const GetSelectedPeriods = () => {
		let selectedPeriods = [];
	
		if (disabled==="false") {	
			
			selectedPeriodsToModify.forEach( (period, index) => {
				// eslint-disable-next-line react/no-array-index-key
				if(period !== undefined) selectedPeriods.push(<SelectedPeriodToModifyBox key = {periods[period].id} periodId = {index} />);
			})

			if(selectedPeriodsToModify.lunch !== undefined) selectedPeriods.push(<SelectedPeriodToModifyBox key = "lunch_selected_period_box" periodId = "lunch" />);
		}
			
		return selectedPeriods;
	}

	const GetPeriodsToSelect = () => {
		let periodsToSelect = [];
		let periodsInDayType = [];

		dayType.timeblocks.forEach((timeblock, index) => {

			if(!periodsInDayType.includes(timeblock.periodId)){
				periodsInDayType.push(timeblock.periodId);

				let periodIndex = periodPosition(timeblock.periodId);

				periodsToSelect.push(
					<div key = {timeblock.periodId} className = "radio row" >
						<Field className = "period_option" type="checkbox" name={`selectedPeriodsToModify[${periodIndex}]`} value="selected" checked = {selectedPeriodsToModify[periodIndex] === undefined ? undefined : "checked"} onChange={(e) => OnPeriodClick(periodIndex)} />
						<div className="period">{periods[periodIndex].name}</div><br />
					</div>
					)
			}
			
		})

		if(dayType.lunchesPositions[0] !== ""){
			periodsToSelect.push(
				<div key = "lunch_period_to_select" className = "radio row" >
					<Field className = "period_option" type="checkbox" name={`selectedPeriodsToModify["lunch"]`} value="selected" checked = {selectedPeriodsToModify.lunch === undefined ? undefined : "checked"} onChange={(e) => OnPeriodClick("lunch")} />
					<div className="period">Lunch</div><br />
				</div>
				)
		}

		return periodsToSelect;
	}

    return (
      	<div id="multiselector_id" className="periodmultiselector">
			
			<FunctionalButton className={(disabled === "true") ? "select_periods_btn_disabled" : "period_multiselector_dropdown_btn select_periods_btn"} text="select periods" onclickhandler={clickHandler} />
			<div id={id} className="periods_display">
				<div className="periods_container">
				{GetPeriodsToSelect()}
				</div>
				
			</div>

			<div className="selected_periods_display row">
				{GetSelectedPeriods()}
			</div>
        </div>
    );
}

PeriodMultiSelector.propTypes = {
	formik: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	disabled: PropTypes.string.isRequired,
};

export default onClickOutside(connect(PeriodMultiSelector), {
	handleClickOutside: function(instance){
		return function(e) {
			
			if((e.target.classList.length < 1 || e.target.classList[1] !== "period_multiselector_dropdown_btn") && e.target.classList[0] !== "period_option" && e.target.classList[0] !== "periods_display" && e.target.classList[0] !== "radio" && e.target.classList[0] !== "selected_periods_display" && e.target.classList[0] !== "period"){
				PeriodMultiSelector.Close();
			}
		};
	}
})
