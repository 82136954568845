import React from 'react';
import './PdfView.scss';
import DailyTimeBlock from '../DailyTimeBlock/DailyTimeBlock';
import WeeklyTimeBlock from '../WeeklyTimeBlock/WeeklyTimeBlock';
import DailyViewHeader from '../DailyViewHeader/DailyViewHeader';
import PdfHeader from '../PdfHeader/PdfHeader';
import WeeklyViewHeader from '../WeeklyViewHeader/WeeklyViewHeader';
import PassingTime from '../../components/PassingTime/PassingTime';
import WeeklyViewSchedule from '../WeeklyViewSchedule/WeeklyViewSchedule';
import { isPromise } from "formik";
import PdfDailyView from '../PdfDailyView/PdfDailyView';
import ABLImg from '@abl/components/src/assets/Abl_Logo.png';
import UnlockingImg from '@abl/components/src/assets/UT_Logo.png';

const PdfView = (data) => {

  while(isPromise(data));
  
  const {totalAnnualMinutes,scheduleType, dayTypes, periods, weeks, isRotating} = data.data;

  const AddMinutes = (time, minutesToAdd) => {
        
    if(time !== undefined){
        let pieces = time.split(':');
    let hour;
    let minute;

        if(pieces.length > 1) {
            hour = Number(pieces[0]);
            minute = Number(pieces[1]) + Number(minutesToAdd);
        }

        if(minute >= 60){
            hour += Math.floor(minute / 60);
            if(hour > 23) hour = 0;
            minute %= 60;
        }

        let hourString = (hour < 10) ? `0${hour}`:`${hour}`;
        let minuteString = (minute < 10) ? `0${minute}`:`${minute}`;

        return `${hourString}:${minuteString}`
    }

    return "00:00";
}

  dayTypes.map((dayType) => {
    dayType.lunchesPositions = dayType.lunchesPositions.filter((x) => x >= 0).sort();
    dayType.lunchesPlaced = dayType.lunchesPositions.length;
    dayType.startTime = dayType.timeblocks[0].startTime;
    dayType.lunchStartTime = dayType.timeblocks[dayType.lunchesPositions[0]].passingEndTime;
    dayType.lunchEndTime = AddMinutes(dayType.lunchStartTime, dayType.lunchDuration);
    dayType.lunchPassingEndTime = AddMinutes( dayType.lunchEndTime, dayType.lunchPassingDuration);
    return dayType;
  });

  let dayTypeCountArray = [];



  const periodPosition = (periodId) => {
    for (let x = 0; x < periods.length; x++){
      if (periods[x].id === periodId) return x;
    }
    
    return 0;
  };
  
  
  const timetags=[];
  
  
  const createDailyTimeblocks = () => {
    const daytypearrays = []; // Stacks from all day types.
    dayTypes.forEach((value, index) => {
        let lunchespositionsarray= value.lunchesPositions.filter(position => position !== "" && position !== -1);
        lunchespositionsarray.sort();
        let daytypelunchesStacks=[];
        let daytypelunchesTimeStacks=[];
        
        if(lunchespositionsarray.length > 0){
          lunchespositionsarray.forEach((lunchposition, lunchindex) => {
            const timeblockarraydaytype = []; // Timeblock stack from day type N and with lunch in 1 position.
            var daytypestacktimetags=[];
            let timeblockstack = []

            
            for(let i = 0; i < value.timeblocks.length; i++){
              let timeblock = {}
              Object.assign(timeblock,value.timeblocks[i]);
              timeblockstack.push(timeblock);
            }

            let luchposTonumber=Number(lunchposition);
            if (lunchposition !==""  ) {
              let lunch = {
                position: luchposTonumber,
                id: 10,
                isLunch: true,
                duration: value.lunchDuration,
                passingDuration: value.lunchPassingDuration,
                startTime: value.lunchStartTime,
                endTime: value.lunchEndTime,
                passingEndTime: value.lunchPassingEndTime,
              }
             
              timeblockstack.splice(luchposTonumber+1,0,lunch); // Inserts the lunch into the array of periods.  
                 
              timeblockstack.forEach((timeblock, index) => {

                  if (index>0) {// Position after the lunch
                    timeblock.startTime=AddMinutes(timeblockstack[index-1].endTime,timeblockstack[index-1].passingDuration);
                    timeblock.endTime= AddMinutes(timeblock.startTime,timeblock.duration);
                  }

                  let periodIndex = periodPosition(timeblock.periodId);
                  let course = (timeblock.isLunch===true) ? ({name: "Lunch",isInstructional:false, color:"#E4E5EB"} ): periods[periodIndex]; // Brings the course from all courses list associated with the current periodID.
                  var dailytimeblock = <DailyTimeBlock  periodname={course.name} instructionaltime={course.isInstructional} duration={Number(timeblock.duration)} dailyuniquekey={'dailytimeblock/'+ 1 } color={course.color} />
                  if (index < timeblockstack.length-1 ) { 
                    var passingtime = <PassingTime  duration={timeblock.passingDuration} passinguniquekey={'passingtimeblock/'+ 5 } />
                    timeblockarraydaytype.push(dailytimeblock,passingtime);
                    daytypestacktimetags.push(timeblock.startTime);
                    daytypestacktimetags.push(timeblock.endTime);
                   
                    
                  }else {
                      timeblockarraydaytype.push(dailytimeblock);
                      daytypestacktimetags.push(timeblock.startTime);
                      daytypestacktimetags.push(timeblock.endTime);
                  }          
              });
                                
              daytypelunchesStacks.push(timeblockarraydaytype); // Inserts 1 stack corresponding to 1 specific lunch in the same day type.
              daytypelunchesTimeStacks.push(daytypestacktimetags); // Inserts 1 time stack corresponding to the specific lunch in the same day type.
            }        
          });
        }
        else{

          const timeblockarraydaytype = []; // Timeblock stack from day type N and with lunch in 1 position.
          var daytypestacktimetags=[];
          let timeblockstack = []
          timeblockstack = Array.from(value.timeblocks);       
          
          
          timeblockstack.forEach((timeblock, index) => {
              let periodIndex = periodPosition(timeblock.periodId);
              let course = (timeblock.isLunch===true) ? ({name: "Lunch",isInstructional:false, color:"#E4E5EB"} ): periods[periodIndex]; // Brings the course from all courses list associated with the current periodID.
              var dailytimeblock = <DailyTimeBlock  periodname={course.name} instructionaltime={course.isInstructional} duration={Number(timeblock.duration)} dailyuniquekey={'dailytimeblock/'+ 1 } color={course.color} />
              if (index < timeblockstack.length-1 ) { 
                var passingtime = <PassingTime  duration={timeblock.passingDuration} passinguniquekey={'passingtimeblock/'+ 5 } />
                timeblockarraydaytype.push(dailytimeblock,passingtime);
                daytypestacktimetags.push(timeblock.startTime);
                daytypestacktimetags.push(timeblock.endTime);
                
                
              }else {
                  timeblockarraydaytype.push(dailytimeblock);
                  daytypestacktimetags.push(timeblock.startTime);
                  daytypestacktimetags.push(timeblock.endTime);
                  
              }          
          });
                            
          daytypelunchesStacks.push(timeblockarraydaytype); // Inserts 1 stack corresponding to 1 specific lunch in the same day type.
          daytypelunchesTimeStacks.push(daytypestacktimetags); // Inserts 1 time stack corresponding to the specific lunch in the same day type.
              
        
        }

        

        daytypearrays.push(daytypelunchesStacks);
        timetags.push(daytypelunchesTimeStacks);
    });

    return daytypearrays;
 }

 let daysarrays= [];
 daysarrays = createDailyTimeblocks();
   
 let weeklyviewsarrays = [];
 
 
 
 
 
 function createWeeklyTimeblocks() {
     let weeklyviewsarrays=[];
     weeks.forEach((week, index) => {
       let weeklystack=[];
      
 
       if(isRotating){
         let count = {};
 
         dayTypeCountArray.push([0,0,0,0,0,0,0,0,0,0]);
 
         for(let i = 0; i < 5; i++){
           if(week.days[i] !== -1){
             if(count[week.days[i]] === undefined){
               count[week.days[i]] = 0;
               dayTypeCountArray[index][i] = 0;
             }
             else{
               count[week.days[i]] ++;
               dayTypeCountArray[index][i] = count[week.days[i]];
             }
             
           }
         }
 
         for(let i = 0; i < 5; i++){
           if(week.days[i] !== -1){
             if(count[week.days[i]] === undefined){
               count[week.days[i]] = 0;
               dayTypeCountArray[index][i + 5] = 0;
             }
             else{
               count[week.days[i]] ++;
               dayTypeCountArray[index][i + 5] = count[week.days[i]];
             }
           }
         }
       } 
 
       week.days.forEach((daytypeid, dayindex) => {
         let timeblockstack = []
         let daytypeidNumber=Number(daytypeid);
 
         
         let dayType = dayTypes.filter(daytype => daytype.id === daytypeidNumber)[0];
         
         if(daytypeid !== -1){
 
           dayType.timeblocks.forEach((timeblock, index) => {
             let course = periods[periodPosition(timeblock.periodId)]; // Brings the course from all courses list associated with the current periodID.
             var weeklyblock= <WeeklyTimeBlock  periodname={course.name} weeklyuniquekey={'weeklytimeblock/'+ index } color={course.color}  durationSum={Number(timeblock.duration)+Number(timeblock.passingDuration)} duration={Number(timeblock.duration)} />
             timeblockstack.push(weeklyblock);
             
                     
           });
 
         }
         
         if(timeblockstack.length > 0){
           weeklystack.push(timeblockstack);
         }
 
       });
       if(weeklystack.length > 0){
         weeklyviewsarrays.push(weeklystack);
       }
       else{
         weeklyviewsarrays.push([]);
 
       }
     });
     return weeklyviewsarrays;
   }
 
   weeklyviewsarrays=createWeeklyTimeblocks();
   
 
   
   let weekkey=1;
  

  return (

    <div className="pdfview px-3"> 
      <PdfHeader key='pdfview' instructionalminutesperyear={totalAnnualMinutes ? totalAnnualMinutes.toString() : '0'} scheduletype={scheduleType} numberdaytypes={dayTypes.length.toString()} />
      <div className="dailyweekly-title">DAILY VIEW</div>
      {dayTypes.map((value, index) =>
        <div key={value.name} className="element-margin" >
          <div className="dailyviewheader"><DailyViewHeader key='dailyviewheader' daytypeid={index} daytype={dayTypes[index].name} dayTypes={dayTypes} /></div>
          <PdfDailyView dailystacksarray={daysarrays[index]} dailyviewschedulekey={value.name} hourarrays={timetags[index]} schedule={data.data} />

          <PdfHeader key='pdfview' instructionalminutesperyear={totalAnnualMinutes ? totalAnnualMinutes.toString() : '0'} scheduletype={scheduleType} numberdaytypes={dayTypes.length.toString()} />
        </div>
      )}

      <div className="dailyweekly-title ">WEEKLY VIEW</div>

      {weeklyviewsarrays.map((value, index) => {
        return  (
          <div key={'weeknumber/'+ weekkey}>
            {(index > 0) ? 
              <div className="mb-4">
                <PdfHeader key='pdfview' instructionalminutesperyear={totalAnnualMinutes ? totalAnnualMinutes.toString() : '0'} scheduletype={scheduleType} numberdaytypes={dayTypes.length.toString()} />
              </div>
              :
              <div></div>}
            {(isRotating === false)?
              <div key={'weeknumber/'+ weekkey} >
                <WeeklyViewHeader key='weeklyviewheader' weeknumber={weekkey} />
                <WeeklyViewSchedule weeknumber={weekkey} dayTypeCountArray = {[0,0,0,0,0]} weeklyviewschedulekey={'weeklyviewschedule/' + index } weeklyviewstacksarray={value} weekarray={weeks[index]} daytypes={dayTypes} />
                <div style={{display: "none"}}>
                  {weekkey++}
                </div>
              </div>
              :
              <div key={'weeknumber/'+ weekkey} >
                <WeeklyViewHeader key='weeklyviewheader' weeknumber={weekkey} />
                <WeeklyViewSchedule weeknumber={weekkey} dayTypeCountArray = {dayTypeCountArray[index].slice(0,5)} weeklyviewschedulekey={'weeklyviewschedule/' + index } weeklyviewstacksarray={weeklyviewsarrays[index]} weekarray={weeks[index]} daytypes={dayTypes} />
                <PdfHeader key='pdfview' instructionalminutesperyear={totalAnnualMinutes ? totalAnnualMinutes.toString() : '0'} scheduletype={scheduleType} numberdaytypes={dayTypes.length.toString()} />
                <WeeklyViewSchedule weeknumber={weekkey} dayTypeCountArray = {dayTypeCountArray[index].slice(5,10)} weeklyviewschedulekey={'weeklyviewschedule/' + index } weeklyviewstacksarray={weeklyviewsarrays[index]} weekarray={weeks[index]} daytypes={dayTypes} />
                <div style={{display: "none"}}>
                  {weekkey++}
                </div>
              </div>}
          </div>
        )}
        
      )}

      <div className="pdf_footer">
          <div className="row">
            <div className="unlocking">
              <div>
                <img src={UnlockingImg} alt="" />
              </div>
              <div>
                <a href='https://unlockingtime.org/' className="page link-pointer">UnlockingTime.org</a>
              </div>
              
              
            </div>
            <div className="abl">
              <div>
                <img src={ABLImg} alt="" />
              </div>
              <div>
                <a href='https://www.ablschools.com/' className="page link-pointer">Ablschools.com</a>
              </div>
              
              
            </div>
          </div>
      </div>
      <div className="page_break">

      </div>
    </div>
  );
}

export default PdfView;
