import React from 'react';
import PropTypes from 'prop-types';
import './FunctionalButton.scss';

const FunctionalButton = ({ id, className, text, onclickhandler, imgpath, tabIndex }) => {
  return (
    <div tabIndex={tabIndex} id= {(id.length > 0)?id:undefined} onClick={onclickhandler} className={`functionalbutton ${className}`}>
      {(imgpath.length > 0)?
        <div><img className="img" src={imgpath} alt=""></img></div>
        :
        ""
      }
      {(text.length > 0)?
      text
      :
      ""}
    </div>
  );
}

FunctionalButton.defaultProps = {
  className: "",
  text: "",
  imgpath: "",
  id: "",
  tooltipmsg:"",
  tabIndex: 0
};

FunctionalButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onclickhandler: PropTypes.func.isRequired,
  imgpath: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number
};

export default FunctionalButton;
