import React from 'react';
import './PeriodSelector.scss';
import { connect } from "formik";
import PropTypes from 'prop-types';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import onClickOutside from "react-onclickoutside";

const PeriodSelector = props => {
	const periods = props.formik.values.periods;
	const { selectedDayType, selectedTimeblock, dayTypes} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
	const periodId = dayType.timeblocks[selectedTimeblock].periodId;
	
	

    const OnPeriodNameClick = (periodId) => {
      props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${selectedTimeblock}].periodId`,Number(periods[periodId].id));
	  let periodsSelector = document.getElementById("period_selector_window");
	  periodsSelector.classList.remove("active");
	  props.formik.setFieldValue("modifyingTimeblockPeriod",false);
	}
	
	PeriodSelector.Close = (event) => {
		let periodsSelector = document.getElementById("period_selector_window");
	  	periodsSelector.classList.remove("active");
	  	props.formik.setFieldValue("modifyingTimeblockPeriod",false);
	}
	
	const FocusInputField = () => {
		var field = document.getElementById(`name_editabletimeblock_${selectedTimeblock}`);
		field.focus();
		field.select();
		PeriodSelector.Close();
	}

	

	

    return (
      	<div className="periodselector">
			<div id="period_selector_window" className="periods_display header">
				<FunctionalButton className="button_clickable_text_b" text="Edit Timeblock Name" onclickhandler={FocusInputField} />
				<hr />
				{periods.map((period, index) => {
				if(period.id !== periodId){
					return <FunctionalButton 
						key = {period.name} 
						className="period" 
						text={period.name} 
						onclickhandler={() => OnPeriodNameClick(index)} />;
				}

				return "";
				})}
			</div>
        </div>
    );
}

PeriodSelector.propTypes = {
	formik: PropTypes.object.isRequired,
};


export default onClickOutside(connect(PeriodSelector), {
	handleClickOutside: function(instance){
	  return function(e) {
		if(e.target.classList.length > 0 && e.target.classList[0] !== "dropdown_btn"){
			PeriodSelector.Close();
		}
		
	  };
	}
})
