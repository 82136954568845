import React from 'react';
import './PdfHeader.scss';
import PropTypes from 'prop-types';

const PdfHeader = ({ instructionalminutesperyear, scheduletype, numberdaytypes, className }) => {

  return (

    <div className={"pdfheader pt-3 " + className}>

      <div className="header-box">
        <div className="d-flex justify-content-center text-left">

          <div className="yantramanav header-child" >
            <div className="mx-1 d-inline-flex">
              <div className="flex-fill mr-2">Instructional Min Per Year</div>
            </div>
            <div className="font-properties row ml-2">{instructionalminutesperyear}</div>
            <div className="separator" ></div>
          </div>

          <div className="yantramanav header-child mx-1" >
            <div className="row mx-3">Schedule Type</div>
            <div className='font-properties row ml-3'>{scheduletype}</div>
            <div className="separator" ></div>
          </div>

          <div className="yantramanav header-child mx-1">
            <div className="row mx-3">Day Types</div>
            <div className='font-properties row ml-3'>{numberdaytypes}</div>
          </div>
        </div>

      </div>

    </div>
  );
}

PdfHeader.defaultProps = {
  className: ""
}

PdfHeader.propTypes = {
  instructionalminutesperyear: PropTypes.string.isRequired,
  scheduletype: PropTypes.string.isRequired,
  numberdaytypes: PropTypes.string.isRequired,
  className: PropTypes.string
};


export default PdfHeader;
