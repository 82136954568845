import React, { Component } from "react";
import ScheduleTemplate from "../../../components/scheduletemplates/scheduletemplates";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import { sendResizeMessage } from '@abl/components/src/hooks/useResizeHelper';

class StepThree extends Component {
  componentDidMount() { 
    sendResizeMessage(false);
  }
  
  render() {
    
    return (
      <div>
        <ScheduleTemplate />
        <PageChangeButtons backRoute="/wizard/two" />
      </div>
    );
  }
}

export default StepThree;
