import React from 'react';
import './DeleteTimeblockModal.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';
import onClickOutside from "react-onclickoutside";
import API from '@abl/components/src/api/api';
import DeleteImage from '../../assets/trashcan.svg';


const DeleteTimeblockModal = props => {
  const {dayTypes, selectedDayType, selectedTimeblock, backup} = props.formik.values;
  const dayType = dayTypes[selectedDayType];
    const onCancelFunction = () => {
        var modal = document.getElementById("delete_timeblock_modal");
        modal.classList.remove("active");
    }

  const onDeleteTimeblockFunction = async () => {
    onCancelFunction();
		await API.delete(`timeblocks/${dayType.timeblocks[selectedTimeblock].id}`);
		console.log("timeblock deleted")
    let timeblockToDelete = selectedTimeblock;
    props.formik.setFieldValue(`selectedTimeblock`, 0);

		dayType.timeblocks.splice(timeblockToDelete, 1);
		
		for(let i = timeblockToDelete; i < dayType.timeblocks.length; i++){
			dayType.timeblocks[i].position --;
		}

		for(let i = 0; i < dayType.lunchesPositions.length; i++){
			if(Number(dayType.lunchesPositions[i]) >= dayType.timeblocks.length){
				dayType.lunchesPositions[i] = -1;
				dayType.lunchesPlaced --;
			}

			if(dayType.lunchesPositions[i] === ""){
				dayType.lunchesPositions[i] = -1;
			}
		}

		backup.dayType.lunchesPlaced = dayType.lunchesPlaced;
		backup.dayType.lunchesPositions = [];

		for(let i = 0; i < dayType.lunchesPositions.length; i++){
			backup.dayType.lunchesPositions.push(dayType.lunchesPositions[i]);
		}

		props.formik.setFieldValue("backup.dayType.lunchesPlaced", backup.dayType.lunchesPlaced);
		props.formik.setFieldValue("backup.dayType.lunchesPositions", backup.dayType.lunchesPositions);


		props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchesPositions`, dayType.lunchesPositions);
		props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchesPlaced`, dayType.lunchesPlaced);
    props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`, dayType.timeblocks);
    
    for(let i = 0; i < dayType.timeblocks.length; i++){
      props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${i}]`, dayType.timeblocks[i]);

    }
    
		await API.put(`day-types/${dayType.id}`, dayType);
    console.log("daytype updated");
    

	}

    return (
      <div id="delete_timeblock_modal" className="delete_timeblock_modal">
          <KeyBindingEventComponent  keyname='esc' handlerFunction={onCancelFunction} />
        <div className="container window">
            <div className="title mt-4">
              Are you sure you want to delete this Timeblock?
            </div>
            <div className="text mt-4">
              <div>
                Deleting this Timeblock will only remove it from this Day Type.
              </div>
              <div>
                It will not be deleted from any other Day Types.
              </div>
            </div>
            
            <div className="buttons_container mr-3 mb-4">
                <FunctionalButton
                    id = "send_btn"
                    className="next_btn delete_btn"
                    text="Delete Timeblock"
                    imgpath ={DeleteImage}
                    onclickhandler={() => {
                      onDeleteTimeblockFunction().then((value) => {
                          if(value){
                              console.log("timeblock deleted")
                          }
                          
                      });
                  }
                      }
                />
                <FunctionalButton
                    className="button_clickable_text_a cancel_btn"
                    text="Cancel"
                    onclickhandler={onCancelFunction}
                />
            </div>
        </div>
        <div className="overlay">
          
        </div>
      </div>
    );
}

DeleteTimeblockModal.propTypes = {
    formik: PropTypes.object.isRequired,
};



export default onClickOutside(connect(DeleteTimeblockModal), {
	handleClickOutside: function(instance){
	  return function(e) {
      var modal = document.getElementById("delete_timeblock_modal");
      modal.classList.remove("active");
	}
}})