import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import API from '@abl/components/src/api/api';
import PropTypes from 'prop-types';
import { withStore } from '../../store';

import StepOne from "./steps/one";
import StepTwo from "./steps/two";
import StepThree from "./steps/three";
import StepFour from "./steps/four";
import ErrorPage from "./steps/error";

import './index.scss';

class Wizard extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeCommas = this.removeCommas.bind(this);
  }

  removeCommas(value) {
    return value.split(',').join('');
  };

  async handleSubmit(values) {
    const { history, store } = this.props;
    console.log("form values")
    console.log(values);
    try {
      let timeblockDuration = 0;
      let periodDuration = parseInt(values.periodDuration, 10);
      let passingDuration = parseInt(values.passingTime, 10);
      let dayTypesAmount = parseInt(values.dayTypesAmount, 10);
      let lunchDuration = parseInt(values.lunchDuration, 10);
      let daysInSchoolYear = parseInt(values.daysInSchoolYear, 10);
      let minAnnualMinutes = parseInt(this.removeCommas(values.minAnnualMinutes), 10);
      let validInputs = true;

      if(values.periodDuration.length > 0){
        timeblockDuration = periodDuration + passingDuration;

        for(let i = 0; i < dayTypesAmount; i++){
          if((timeblockDuration * parseInt(values.dayTypePeriods[i+1], 10)) -  passingDuration  + lunchDuration > 1440) {
            validInputs = false;
            break;
          }
        }
      }
      else if(values.minAnnualMinutes.length > 0){
        if(minAnnualMinutes / daysInSchoolYear > 1440){
          validInputs = false;
        }
      }

      this.setState(
        {
          error: null,
        }
      );

      if(validInputs){
        const dayTypes = [];

        for(let i = 0; i < dayTypesAmount; i++){
          dayTypes.push(parseInt(values.dayTypePeriods[i+1], 10));
        }

        values.dayTypes = dayTypes;

        values.daysInSchoolYear = parseInt(values.daysInSchoolYear, 10);
        values.minAnnualMinutes = values.minAnnualMinutes.length > 0 ? minAnnualMinutes : undefined;
        values.minDailyMinutes = values.minDailyMinutes.length > 0 ? parseInt(this.removeCommas(values.minDailyMinutes), 10) : undefined;
        values.maxDailyOnSiteMinutes = values.maxDailyOnSiteMinutes.length > 0 ? parseInt(this.removeCommas(values.maxDailyOnSiteMinutes), 10) : undefined;
        values.periodDuration = values.periodDuration.length > 0 ? periodDuration : undefined;
        values.passingDuration = passingDuration;
        values.isRotating = values.isRotating === 'true';
        values.lunchDuration = parseInt(values.lunchDuration, 10);

        delete values.passingTime;
        delete values.dayTypesAmount;

        const uuid = window.localStorage.getItem('uuid');

        const response = await API.post('templates', {
          uuid,
          raw: values
        })

        
        store.set('schedule', response.data.scheduleId);
        localStorage.setItem('schedule', response.data.scheduleId);

        history.push('/designer/daytype');
      }
      else{
        history.push('/wizard/error');
      }
    } catch (error) {
      this.setState({ error })
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div className="wizard">
        {error && (
          <div className="alert alert-danger">
            Some error occurred
          </div>
        )}
        
        <Formik
          initialValues={{
            daysInSchoolYear: "180",
            minAnnualMinutes: "",
            minDailyMinutes: "",
            maxDailyOnSiteMinutes: "",
            startTime: "08:45",
            lunchDuration: "30",
            lunchesPerDay: "2",
            passingTime: "5",
            periodDuration: "",
            scheduleType: "",
            dayTypesAmount: "2",
            dayTypePeriods: { 1: 5, 2: 5, 3: 5, 4: 5, 5: 5, 6: 5, 7: 5, 8: 5, 9: 5, 10: 5 },
            totalCoursesPerTerm: "10",
            isRotating: "false",
            scheduleTypeLink: "",
            errors: {
              dayTypePeriods: {}
            }
          }}
          onSubmit={this.handleSubmit}

        >
          <Form>
            <Switch>
              <Redirect exact from="/wizard" to="/wizard/one" />
              <Route path="/wizard/one" component={StepOne} />
              <Route path="/wizard/two" component={StepTwo} />
              <Route path="/wizard/three" component={StepThree} />
              <Route path="/wizard/four" component={StepFour} />
              <Route path="/wizard/error" component={ErrorPage} />
            </Switch>
          </Form>
        </Formik>
      </div>
    );
  }
}

Wizard.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStore(Wizard);