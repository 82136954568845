import React from 'react';
import { connect } from "formik";
import PropTypes from 'prop-types';
import './WeeksSettingsComponent.scss';
import WeekDayTypesSelector from '../WeekDayTypesSelector/WeekDayTypesSelector';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import uuid from 'uuid/v1'

const WeeksSettingsComponent = props => {
  let {weeks,scheduleId} = props.formik.values;
  let {onMinutesChangeFunction} = props;
  const AddWeek = () => {
    const deletedWeeks = props.formik.values.backup.deletedWeeks;
    const week = deletedWeeks.shift();

    props.formik.setFieldValue("backup.deletedWeeks", deletedWeeks);
    if(week){
      weeks.push(week);
    }
    else{
      weeks.push({
        scheduleId: scheduleId,
        id: uuid(),
        days: [-1,-1,-1,-1,-1]
      });
    }
    
    props.formik.setFieldValue("weeks", weeks);
  }

  return (
    <div className="weekssettingscomponent">
      <div className="title">
        THIS DAY TYPE OCCURS
      </div>
      {weeks.map((week, index) => {
        return <WeekDayTypesSelector key={`week_${week.id}`} canDelete={weeks.length > 1? "true":"false"} weekID={index} onMinutesChangeFunction={onMinutesChangeFunction} />;
      })}
      <FunctionalButton text="+ Add Another Week" className="button_clickable_text_b" onclickhandler={AddWeek} />
    </div>
  );
}

WeeksSettingsComponent.propTypes = {
  formik: PropTypes.object.isRequired,
  onMinutesChangeFunction: PropTypes.func.isRequired,
};
export default connect(WeeksSettingsComponent);
