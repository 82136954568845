import React from 'react';
import { Link } from "react-router-dom";
import './LinkerButton.scss';
import PropTypes from 'prop-types';

const LinkerButton = ({ toRoute, text, className, onClick, id, imgpath, tabIndex }) => {
    return (
        <div>
            {(imgpath.length > 0)?   
                <Link tabIndex={tabIndex} id={id} className={className + " linker_btn"} to={toRoute} onClick={onClick}>{text} 
                    <img className="img" src={imgpath} alt=""></img>
                </Link>
                :
                <Link tabIndex={tabIndex} id={id} className={className + " linker_btn"} to={toRoute} onClick={onClick}>{text}</Link>
            }
            
        </div>
    );
}

LinkerButton.defaultProps = {
    className: '',
    onClick: () => { },
    id: '',
    imgpath: "",
    tabIndex: 0
};

LinkerButton.propTypes = {
    toRoute: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    imgpath: PropTypes.string,
    tabIndex: PropTypes.number
};

export default LinkerButton;