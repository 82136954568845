import React from 'react';
import './WeeklyViewHeader.scss';
import PropTypes from 'prop-types';

const WeeklyViewHeader = ({ weeknumber }) => {


  return (

    <div className="weeklyviewheader">

      <div className="header-box">
        <div className="weektype-properties">
          WEEK {weeknumber.toString()}
        </div>


      </div>

    </div>
  );
}


WeeklyViewHeader.propTypes = {
  weeknumber: PropTypes.number.isRequired

};


export default WeeklyViewHeader;
