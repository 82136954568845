import React from 'react';
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';
import "./DayTypesForm.scss"
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField.jsx"
import PropTypes from 'prop-types';

import { connect } from 'formik';

const DayTypesForm = props => {
    let numDays = Number(props.formik.values.dayTypesAmount);
    let daysArr = [];
    for (let i = 1; i <= numDays; ++i) {
        daysArr.push(i);
    }

    const dayTypeOptions = [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" }];
       
    return (
        <div className="day_types_form">
                  
                <DropDownFormField 
                    fieldName="dayTypesAmount" 
                    backgroundText="day types"
                    options={dayTypeOptions}
                    isTooltipText="true"
                    tooltipmsgdropdown="<b>How many different schedules do you have in any given week?</b> Some schools have different bell schedules on different days, for example A/B days or some days with late starts or early dismissals.  How many different types of days do you have?"
                    />
                
            <div className="clean-space" />
            <div className="day_periods_fields yantramanav ">
                {daysArr.map(dayType => <BasicFormField
                    key={dayType}
                    fieldName={`dayTypePeriods.${dayType}`}
                    labelText={`# PERIODS IN DAY TYPE ${dayType}`}
                    backgroundText='periods'
                    type='text'
                    optional="false"
                    errorMsg="Number of periods must be between 1 and 12"
                    minValue={1}
                    maxValue={12}
                    tooltipmessage="<b>How many periods per day do you intend to schedule for students for a given day type?</b> For traditional standard schedules, schools typically offer between 6 and 8 periods per day.  For block schedules, schools typically schedule 4 to 5 periods per day that alternate between days."
                    isTooltipText="true"
                    onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 2 ) event.preventDefault() }}
                />)}
            </div>


        </div>
    );
}

DayTypesForm.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default connect(DayTypesForm);