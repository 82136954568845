import React, { useEffect } from 'react';
import './DayTypeSettings.scss';
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import SwitchFormField from '../SwitchFormField/SwitchFormField';
import LunchTimeblocksSelector from '../LunchTimeblocksSelector/LunchTimeblocksSelector';
import { connect } from "formik";
import WeeksSettingsComponent from '../WeeksSettingsComponent/WeeksSettingsComponent';
import DeleteImage from '../../assets/trashcan_light_blue.svg';
import CloseImage from '../../assets/settings_open.svg';
import OpenImage from '../../assets/settings_closed.svg';
import API from '@abl/components/src/api/api';


const DayTypeSettings = props => {
  const { onDeleteFunction, onTimeblocksChangeFunction, modifyAllFunction, onMinutesChangeFunction } = props;
  const {selectedDayType, weeks, dayTypes, saving, initialized} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
  const {backup} = props.formik.values;
  

  const OnArrowClick = () => {
    var settings = document.getElementById("daytype_settings");
    var open = document.getElementById("open");
    var close = document.getElementById("close");

    if(settings.classList.contains("displayed")){
      settings.classList.remove("displayed");
      close.classList.remove("displayed");
      open.classList.add("displayed");
    }else{
      settings.classList.add("displayed");
      close.classList.add("displayed");
      open.classList.remove("displayed");
    }
    
  }

  // Remove Saved style and change the message to the default
  const removeSaved = () => {
    let saveButtonUp = document.getElementById("save_button_up");
    let saveButtonDown = document.getElementById("save_button_down");

    saveButtonUp.classList.remove("saved");
    saveButtonDown.classList.remove("saved");
    saveButtonUp.innerHTML = "Save";
    saveButtonDown.innerHTML = "Save";
  }

  const OnSave = async () => {

    props.formik.setFieldValue("saving", true);
    
    
   
    
    // Save DayType

    Object.assign(backup.dayType,dayType);
    backup.dayType.lunchesPositions = [];
    for(let i = 0; i < dayType.lunchesPositions.length; i++){
      backup.dayType.lunchesPositions.push(dayType.lunchesPositions[i]);
    }

    backup.dayType.timeblocks = [];
    for(let i = 0; i < dayType.timeblocks.length; i++){
      let timeblock = {};
      Object.assign(timeblock,dayType.timeblocks[i]);
      backup.dayType.timeblocks.push(timeblock);
    }

    props.formik.setFieldValue("backup.dayType", backup.dayType);
    
    
    props.formik.setFieldValue(`dayTypes[${selectedDayType}].name`, props.formik.values.dayTypeNameInSettings);
    props.formik.setFieldValue(`backup.dayType.name`, props.formik.values.dayTypeNameInSettings);

    dayType.name = props.formik.values.dayTypeNameInSettings;
    

    dayType.lunchesPositions = dayType.lunchesPositions.map(lunch => lunch === "" ? -1:Number(lunch));
    await API.put(`day-types/${dayType.id}`,dayType);
    
    // Save week settings
    
    let backupWeekIds = backup.weeks.map(week => week.id);
    let weekIds = weeks.map(week => week.id);

    // Delete missing weeks
    for(let i = 0 ; i < backupWeekIds.length; i++){

      if(!weekIds.includes(backupWeekIds[i])){
        // eslint-disable-next-line no-await-in-loop
        await API.delete(`weeks/${backupWeekIds[i]}`);
        console.log("week deleted");
      }
      
    }
    
    // Create new weeks
    for(let i = 0; i < weekIds.length; i++){

      // Update weeks
      if(backupWeekIds.includes(weekIds[i])){
        // eslint-disable-next-line no-await-in-loop
        await API.put(`weeks/${weekIds[i]}`, weeks[i]);
        console.log("week updated");
      }
      else{
        // eslint-disable-next-line no-await-in-loop
        let response = await API.post('weeks', weeks[i]);
        weeks[i] = response.data;
        console.log("week added");
      }

      
    }

    backup.weeks = [];
    for(let i = 0; i < weeks.length; i++){
      let week = {};
      Object.assign(week,weeks[i]);
      backup.weeks.push(week);
    }

    modifyAllFunction("periodDuration");
    modifyAllFunction("passingDuration");

    props.formik.setFieldValue("backup.weeks", backup.weeks);
  
    props.formik.setFieldValue("saving", false);

    let saveButtonUp = document.getElementById("save_button_up");
    let saveButtonDown = document.getElementById("save_button_down");

    saveButtonUp.classList.add("saved");
    saveButtonDown.classList.add("saved");
    saveButtonUp.innerHTML = "Saved!";
    saveButtonDown.innerHTML = "Saved!";

    

    // Remove Saved after 10 seconds
    setTimeout(() => removeSaved(), 2000);

  }

  useEffect(() => {
    props.formik.setFieldValue("dayTypeNameInSettings",dayTypes[selectedDayType].name);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedDayType, initialized]);

  

  useEffect(() => {
    let buttonUp = document.getElementById("save_settings_btn_up");
    let buttonDown = document.getElementById("save_settings_btn_down");

    if (props.formik.values.errors[`dayTypes[${selectedDayType}].lunches`] || props.formik.values.errors[`dayTypes[${selectedDayType}].name`] ) {
      buttonUp.classList.add("disabled");
      buttonDown.classList.add("disabled");
    }
    else{
      buttonUp.classList.remove("disabled");
      buttonDown.classList.remove("disabled");
    }

   
  });


  useEffect(() => {
    let hasChanged = false;
    if(dayType.name !== backup.dayType.name) hasChanged = true;
    else if(weeks.length !== backup.weeks.length) hasChanged = true;
    else if(dayType.lunches !== backup.dayType.lunches) hasChanged = true;
    // eslint-disable-next-line no-negated-condition
    else if(dayType.lunchesPositions.length !== backup.dayType.lunchesPositions.length) hasChanged = true;
    else{
      for(let i = 0; i < dayType.lunchesPositions.length; i ++){

        if((dayType.lunchesPositions[i] === "" ? -1 : Number (dayType.lunchesPositions[i])) !== (backup.dayType.lunchesPositions[i] === "" ? -1 : Number (backup.dayType.lunchesPositions[i]))){
          
          hasChanged = true;
          break;
        }
        
      }

      if(!hasChanged){
        for(let i = 0; i < weeks.length; i ++){
            // eslint-disable-next-line max-depth
          if(weeks[i].days[0] !== backup.weeks[i].days[0]){
            
            hasChanged = true;
            break;
          }
          else if(weeks[i].days[1] !== backup.weeks[i].days[1]){
            
            hasChanged = true;
            break;
          }
          else if(weeks[i].days[2] !== backup.weeks[i].days[2]){
            
            hasChanged = true;
            break;
          }
          else if(weeks[i].days[3] !== backup.weeks[i].days[3]){
            
            hasChanged = true;
            break;
          }
          else if(weeks[i].days[4] !== backup.weeks[i].days[4]){
            
            hasChanged = true;
            break;
          }
          
        }
      }
    }


    props.formik.setFieldValue("saved", !hasChanged);

    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[backup, selectedDayType, dayType.name, weeks, dayType.lunches, dayType.lunchesPositions, saving]);

  

  return (
    <div className="daytypesettings">
      <div className="settings_header row">
        <div className="settings_text">
          Change Settings for this Day Type
        </div>
        <img id = "close" src={CloseImage} alt="settings arrow" className = "arrow_img"></img>
        <img id = "open" src={OpenImage} alt="settings arrow" className = "arrow_img displayed"></img>
        <div className="btn_container">
          <FunctionalButton className="arrow_btn" onclickhandler={OnArrowClick} />
        </div>
      </div>
      <div id="daytype_settings" className="settings">
        <div className="d-flex">
          <BasicFormField
            fieldName="dayTypeNameInSettings"
            labelText='DAY NAME'
            errorMsg="Daytype name should not be empty"
            type='text'
            optional="false"
            isNumber={false}
            onKeyPressFunction={(event) => {
              if (event.target.value.length > 30) event.preventDefault() 
            }}
          />

          <div id="save_settings_btn_up" className="ml-auto">
            <FunctionalButton
              id="save_button_up"
              className="next_btn save"
              text={saving? ". . ." : "Save"}
              onclickhandler={OnSave}
            />
          </div>
        </div>

        <WeeksSettingsComponent onMinutesChangeFunction={onMinutesChangeFunction} />

        <BasicFormField
          fieldName={`dayTypes[${selectedDayType}].lunches`}
          labelText='# OF LUNCHES PER DAY'
          type='text'
          errorMsg="Number of lunches must be between 1 and 5"
          minValue={1}
          maxValue={5}
          onKeyPressFunction={(event) => { 
            if (event.key < '0' || event.key > '9' || event.target.value.length > 8 || (event.target.value === "0" && event.key === '0')){
              event.preventDefault() 
            }}}

        />

        <LunchTimeblocksSelector onTimeblocksChangeFunction={onTimeblocksChangeFunction} />
        

        <BasicFormField
          fieldName='passingTimeToApply'
          labelText='PASSING TIME'
          backgroundText='min'
          type='text'
          optional="true"
          displayOptional = "false"
          onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 || (event.target.value === "0" && event.key === '0')) event.preventDefault() }}
        />
        <div className="info_text">This field will edit all unlocked passing times</div>

        <BasicFormField
          fieldName='periodDurationToApply'
          labelText='PERIOD DURATION'
          backgroundText='min'
          isNumber = {false}
          type='text'
          optional="true"
          displayOptional = "false"
          onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 || (event.target.value === "0" && event.key === '0')) event.preventDefault() }}
        />
        <div className="info_text">This field will edit all unlocked period durations</div>

        <SwitchFormField text="ALLOW PASSING TIMES TO BE COUNTED AS INSTRUCTIONAL MINUTES?" fieldName="passingTimesInstructional" />
        <SwitchFormField text="ASK ME HOW TO DISTRIBUTE MINUTES AFTER EACH DURATION EDIT" fieldName="askAfterEdit" />

        <div className="buttons_container row">


          <FunctionalButton
            imgpath ={DeleteImage}
            className={dayTypes.length > 1 ? "back_btn delete": "back_btn delete disabled"}
            text="Delete Day Type"
            onclickhandler={onDeleteFunction}
          />
          <div id = "save_settings_btn_down">
            <FunctionalButton
              id="save_button_down"
              className="next_btn save"
              text={saving? ". . ." : "Save"}
              onclickhandler={OnSave}
            />
          </div>
          
        </div>
      </div>
      
    </div>
  );
}

DayTypeSettings.propTypes = {
  formik: PropTypes.object.isRequired,
  onDeleteFunction: PropTypes.func.isRequired,
  onTimeblocksChangeFunction: PropTypes.func.isRequired,
  modifyAllFunction: PropTypes.func.isRequired,
  onMinutesChangeFunction: PropTypes.func.isRequired,
};

export default connect(DayTypeSettings);
