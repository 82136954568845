import React from 'react';
import './LunchPositionSelector.scss';
import { connect } from "formik";
import PropTypes from 'prop-types';
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';


const LunchPositionSelector = props => {
	const {lunchId, onTimeblocksChangeFunction, firstLunchPositionSelectorID} = props;
	const {selectedDayType, periods, dayTypes} = props.formik.values;
	const dayType = dayTypes[selectedDayType];
	const lunches = dayType.lunches;
	const lunchPosition = dayType.lunchesPositions[lunchId];
	const { timeblocks } = dayType;

	const periodPosition = (periodId) => {
		for (let x = 0; x < periods.length; x++){
			if (periods[x].id === periodId) return x;
		}
		
		return 0;
	};

	const CreateOverview = () => {
		let components = [];
		let lunchPos = lunchPosition === "" ? -1 : Number(lunchPosition);

		timeblocks.forEach((timeblock, index) => {
			
			let name = periods[periodPosition(timeblock.periodId)].name;
			if(name.length > 4) name = name.substring(0,4) + "...";
			components.push(<div key={`${timeblock.id}_${lunchId}`} className="period">{name}</div>);

			if(index === lunchPos){
				components.push(<div key={`lunch_${lunchId}`} className="lunch">Lunch</div>);
			}
		})

		return components;
	}
	
	const GetOptions = () => {
		let options = [{value:"", text :""}];
		let lunchesPositions = [];
		for(let i = 0; i < dayType.lunchesPositions.length ; i++){
			if(dayType.lunchesPositions[i] === ""){
				lunchesPositions.push(-100);
			}else{
				lunchesPositions.push(Number(dayType.lunchesPositions[i]));
			}
		}

		timeblocks.forEach((timeblock, index) => {
			if(index === lunchesPositions[lunchId] || !lunchesPositions.includes(index)){
				let name = periods[periodPosition(timeblock.periodId)].name;
				if(name.length > 4) name = name.substring(0,4) + "...";
				options.push({value: index , text: name });
			}
		})

		return options;
	}

	const OnLunchPositionSelect = () => {
		let numPositionedLunches = 0;
		for(let i = 0; i < lunches; i ++){
			if(dayType.lunchesPositions[i] !== "" && dayType.lunchesPositions[i] !== -1  && dayType.lunchesPositions[i] !== undefined) numPositionedLunches ++;
		}

		props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchesPlaced`,numPositionedLunches);
		onTimeblocksChangeFunction("all");
	}

	return (
		<div className="lunchpositionselector">
			<DropDownFormField 
				firstLunchPositionSelectorID={firstLunchPositionSelectorID}
				fieldName={`dayTypes[${selectedDayType}].lunchesPositions[${lunchId}]`} 
				options={GetOptions()}
				onBlurFunction = {OnLunchPositionSelect}
			/>
			<div className="overview">
				{CreateOverview()}
			</div>
		</div>
    );
}

LunchPositionSelector.defaultProps = {
	onTimeblocksChangeFunction: (timeblockId) => {},
	firstLunchPositionSelectorID: ''
};

LunchPositionSelector.propTypes = {
	formik: PropTypes.object.isRequired,
	lunchId: PropTypes.number.isRequired,
	onTimeblocksChangeFunction: PropTypes.func,
	firstLunchPositionSelectorID: PropTypes.string
  };

export default connect(LunchPositionSelector);
