import React from 'react';
import './DailyViewSchedule.scss';
import DailyViewStack from '../DailyViewStack/DailyViewStack';
import PropTypes from 'prop-types';


const DailyViewSchedule = ({ dailystacksarray, dailyviewschedulekey, hourarrays }) => {
  // The dailystacksarray is an array containing arrays, the latter corresponds to the periods of a single day.
  // The hourarrays is an array of arrays, the latter corresponds to the hours of a single day.

  return (

    <div className="dailyviewschedule">      
      <div className="dailyviewscroll-container">
        {dailystacksarray.map((value, index) => {
          return <div key={'div/' + dailyviewschedulekey + '/dailystack/' + index.toString() } className="dailyviewscroll-element"  >
            <DailyViewStack timeblockarray={value} dailyviewstackkey={ dailyviewschedulekey + '/dailystack/' + index.toString()} timetagsarray={hourarrays[index]} />
          </div>

        })}
      </div>
    </div>

  );
}

DailyViewSchedule.propTypes = {
  dailystacksarray: PropTypes.array.isRequired,
  dailyviewschedulekey: PropTypes.string.isRequired,
  hourarrays: PropTypes.array.isRequired
};

export default DailyViewSchedule;
