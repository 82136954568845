import React from "react";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";

const Error = () => {
  return (
    <div>
        <h1 className="pageFirstTitle">
            We can&apos;t generate this schedule because it&apos;s more than 24 hours.<br />
            Please adjust your settings.
        </h1>
      <PageChangeButtons backRoute="/wizard/two" />
    </div >
  );
}


export default Error;

