import React, { useState, useEffect } from 'react';
import PdfView from '../../components/PdfView/PdfView';
import API from '@abl/components/src/api/api';

const Pdf = () => {
  const [data, setData] = useState({res: {}, fetching: true});
  useEffect(() => {
    const getdata = async() => {
      // eslint-disable-next-line no-return-await
      const path = window.location.pathname.split('/');
      const response = await (await API.get('schedules/' + path[path.length - 1])).data;
      setData({res: response, fetching: false});
    };

    getdata();
  }, []);

  return (
    <div>
      {data.fetching ? <div>Wait...</div> : <PdfView data={data.res} />}
    </div>
  );
}

export default Pdf;
