import React from 'react';
import { Field } from "formik";
import "./DropDownFormField.scss"
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"
import TooltipIcon from "../assets/info_icon.svg"
import uuid from "uuid/v1"
import PropTypes from 'prop-types';
import { connect } from "formik";

const DropDownFormField = props => {
    const { fieldName, labelText, backgroundText, options, tooltipmsgdropdown, emptyText, onBlurFunction, tabIndex, onChangeFunction, isTooltipText, firstLunchPositionSelectorID} = props;
    const fieldValue = props.formik.values[fieldName];
    
    return (
        <div className="dropdown_form_entry">
    
            {(labelText.length > 0) ?
                <div className="field_title"><label htmlFor={fieldName}>{labelText}</label><br /></div>
                :
                ""
            }
            <label className="label">
                <div className="arrow_bg"></div>
                {(tooltipmsgdropdown.length > 0) ?
                    <div className="tooltip-properties-dropdown" >
                        {(isTooltipText === "true")?
                           (
                            (window.innerWidth <= 800)? 
                              <div>
                                <div id={`whatsid_${fieldName.split(".").join("")}`}  >What is this?</div>
                                <Tooltipcomp  tooltipmessage={tooltipmsgdropdown} idcomponent={`whatsid_${fieldName.split(".").join("")}`} tooltipplacement="bottom" />
                              </div>
                              :
                              <div>
                                <div id={`whatsid_${fieldName.split(".").join("")}`}  >What is this?</div>
                                <Tooltipcomp  tooltipmessage={tooltipmsgdropdown} idcomponent={`whatsid_${fieldName.split(".").join("")}`}  />
                              </div>
                            )
                        :
                        (window.innerWidth <= 800)? 
                            <Tooltipcomp imgpath={TooltipIcon} tooltipmessage={tooltipmsgdropdown} tooltipid={`${fieldName}`} tooltipplacement="bottom" />
                            :
                            <Tooltipcomp imgpath={TooltipIcon} tooltipmessage={tooltipmsgdropdown} tooltipid={`${fieldName}`} />
                        }

                    </div>
                    
                    :
                    ""
                }
                {onChangeFunction === undefined ?
                    (firstLunchPositionSelectorID.length > 0)?
                        (
                        <Field id={firstLunchPositionSelectorID} className="input_field" as="select" name={fieldName} tabIndex={tabIndex} onBlur={onBlurFunction} >
                            {options.map(option => <option key={uuid()} value={option.value} >{option.text}</option>)}
                        </Field>
                        )
                        :
                        <Field className="input_field" as="select" name={fieldName} tabIndex={tabIndex} onBlur={onBlurFunction} >
                            {options.map(option => <option key={uuid()} value={option.value} >{option.text}</option>)}
                        </Field>
                :
                    <Field className="input_field" as="select" name={fieldName} tabIndex={tabIndex} onBlur={onBlurFunction} onChange={onChangeFunction} >
                        {options.map(option => <option key={uuid()} value={option.value} >{option.text}</option>)}
                    </Field>
                }
                
                {(backgroundText.length > 0) ?
                    <div className="input_background yantramanav">
                        <p>{backgroundText}</p>
                    </div>
                    : ''}
                {(emptyText.length > 0 && fieldValue === "") ?
                    <div className="empty_text yantramanav">
                        <p>{emptyText}</p>
                    </div>
                    : ''}
            </label>
        </div>
    );
}

DropDownFormField.defaultProps = {
    backgroundText: '',
    labelText: "",
    tooltipmsgdropdown: "",
    emptyText: "",
    onBlurFunction: (event) => {},
    onChangeFunction: undefined,
    tabIndex: 0,
    isTooltipText:'false',
    firstLunchPositionSelectorID: ''
};

DropDownFormField.propTypes = {
    formik: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    backgroundText: PropTypes.string,
    options: PropTypes.array.isRequired,
    labelText: PropTypes.string,
    tooltipmsgdropdown: PropTypes.string,
    emptyText: PropTypes.string,
    onBlurFunction: PropTypes.func,
    onChangeFunction: PropTypes.func,
    tabIndex: PropTypes.number,
    isTooltipText: PropTypes.string,
    firstLunchPositionSelectorID: PropTypes.string
};

export default connect(DropDownFormField);