import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tooltip.scss';
import { Tooltip } from 'reactstrap';
var parse = require('html-react-parser');

class TooltipComp extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            tooltipOpen: false,
        }
    }

    toggle() {
        this.setState((prevState) => {
            return {tooltipOpen: !prevState.tooltipOpen}
        })
        
    }

  

    CreateTootip(props) {
        
        const { tooltipmessage, imgpath, idtoolstr, classnametooltip, idcomponent, tooltipplacement, fading, tooltipdelay } = props;
        if(tooltipmessage.length > 0){
            if(imgpath.length > 0){
                return  <div>
                            <div  href="#" id={idtoolstr}  >
                                <img className={classnametooltip} src={imgpath} alt=""></img>
                            </div> 
                            <Tooltip  getcontent={tooltipmessage} className="tooltipcomponent" placement={tooltipplacement} isOpen={this.state.tooltipOpen} target={idtoolstr} toggle={this.toggle}  fade={fading} delay={tooltipdelay} >  {parse(tooltipmessage)}</Tooltip>
                        </div>
            }

            return <Tooltip  className={`tooltipcomponent ${classnametooltip}`} placement={tooltipplacement} isOpen={this.state.tooltipOpen} target={idcomponent} toggle={this.toggle}  fade={fading} delay={tooltipdelay} > {parse(tooltipmessage)}</Tooltip>            
        }
    }

    render() {
        
        const { imgpath,tooltipmessage, classnametooltip, idcomponent, tooltipid,tooltipplacement, fading, tooltipdelay} = this.props;
        var idtoolstr=`tooltip_${tooltipid.split('.').join('')}`;
        return (
            <div >
            
                {this.CreateTootip({tooltipmessage,imgpath,idtoolstr,classnametooltip,idcomponent,tooltipplacement,fading, tooltipdelay})  
                }
            
            </div>
        )
    }
};

TooltipComp.defaultProps = {
    classnametooltip: "",
    imgpath:"",
    idcomponent:"",
    tooltipmessage: "",
    tooltipid: "",
    tooltipplacement:"right",
    fading: false,
    tooltipdelay: 100,
};

TooltipComp.propTypes = {
    imgpath: PropTypes.string,
    tooltipmessage: PropTypes.string,
    classnametooltip: PropTypes.string,
    idcomponent:PropTypes.string,
    tooltipid: PropTypes.string, 
    fading: PropTypes.bool,
    tooltipplacement: PropTypes.string,
    tooltipdelay: PropTypes.number,
};

export default TooltipComp;