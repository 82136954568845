import React from 'react';
import { connect } from "formik";
import PropTypes from 'prop-types';
import './EditableDayType.scss';
import EditableTimeblock from '../EditableTimeblock/EditableTimeblock';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import { List, arrayMove } from 'react-movable';
import PeriodSelector from '../../components/PeriodSelector/PeriodSelector';


const EditableDayType = props => {
  const {onTimeblocksChangeFunction} = props;
  let { dragging , dayTypes, selectedDayType} = props.formik.values;
	const dayType = dayTypes[selectedDayType];



  const AddTimeblock = () => {
    var form = document.getElementById("addtimeblockwindow");
    form.classList.add("active");
  }

  const dragTimeblockFunction = timeblockId => {
    document.addEventListener("mouseup", MouseUp);
    props.formik.setFieldValue("dragging", true);
    props.formik.setFieldValue("selectedTimeblock", timeblockId);
    dragging = true;
	}

  const MouseUp = (event) => {
    document.removeEventListener("mouseup", MouseUp);
    props.formik.setFieldValue("dragging", false);
  }


  const GetBlocks = () => {
    let lunchPosition = dayType.lunchesPositions[0];

      let blocks = dayType.timeblocks.map((timeblock, index) => {
        return (index === (lunchPosition === "" ? -1 : Number(lunchPosition)))?
          <div key={timeblock.id}>
            <EditableTimeblock 
              timeblockId={index}  
              isLunch = "false" 
              isTheFirstTimeblock = {(index === 0)? "true":"false"} 
              dragTimeblockFunction={dragTimeblockFunction} 
              updateTimeblockTimes = {onTimeblocksChangeFunction}
              dragging = {dragging}
              />
            <div className="lunch_block">
              <EditableTimeblock 
                isLunch = "true" 
                timeblockId = {index}
                isTheLastTimeblock = {(index === dayType.timeblocks.length - 1)? "true":"false"}
                updateTimeblockTimes = {onTimeblocksChangeFunction}
                dragging = {dragging}
                />
            </div>
          </div>
          :
          <div key={timeblock.id}>
            <EditableTimeblock 
              timeblockId={index}  
              isLunch = "false" 
              isTheFirstTimeblock = {(index === 0)? "true":"false"}
              isTheLastTimeblock = {(index === dayType.timeblocks.length - 1)? "true":"false"} 
              dragTimeblockFunction={dragTimeblockFunction} 
              updateTimeblockTimes = {onTimeblocksChangeFunction}
              dragging = {dragging}
              /></div>
      })
      return blocks;
  }

  const getLI = (value, props, isDragged, isSelected) => {
    return <li
    
            {...props}
            tabIndex = "-1"
            style={{
              ...props.style,
              dispaly: 'flex',
              listStyleType: 'none',
            }}
            
          >
          
          {value}
            
    </li>

    
  }

  

  


  
  return (
    <div className="editabledaytype">
      <PeriodSelector />
      <div className="timeblocks">
        <List
        lockVertically
        transitionDuration= '0'
        values={GetBlocks()}
        renderList={({ children, props }) => <ul id="draggable_list" {...props}>{children}</ul>}
        renderItem={({ value, props, isDragged, isSelected }) => (
          getLI(value, props, isDragged, isSelected)
        )}

        onChange={({ oldIndex, newIndex }) => {
          dayType.timeblocks = arrayMove(dayType.timeblocks, oldIndex, newIndex);
          for(let i = 0; i < dayType.timeblocks.length; i++){
            dayType.timeblocks[i].position = i;
          }

          props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,dayType.timeblocks);
          onTimeblocksChangeFunction("all");
        }}
      />
      </div>
      
      <FunctionalButton text="+ Add a Timeblock" onclickhandler = {AddTimeblock} className="button_clickable_text_b" />
    </div>
  );
}

EditableDayType.defaultProps = {
  style: {}
};

EditableDayType.propTypes = {
  formik: PropTypes.object.isRequired,
  style: PropTypes.object,
  onTimeblocksChangeFunction: PropTypes.func.isRequired
};

export default connect(EditableDayType);
