import React from 'react';
import './StartTimeEditor.scss';
import PropTypes from 'prop-types';
import { connect } from "formik";
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';

const StartTimeEditor = props => {

  const {onBlurFunction} = props;
  const {selectedDayType} = props.formik.values;

  return (
    <div className="starttimeeditor timetag">


      <div className="timetag-line"></div>
      <div className = "row time">
        <div className="hour_position_start_time hour-font row">
          <BasicFormField
                    fieldName={`dayTypes[${selectedDayType}].startTime`}
                    type='time'
                    optional="false"
                    onBlurFunction = {onBlurFunction}
                    
          />
        </div>
        {/* <div className="am">
          {(Number(dayTypes[selectedDayType].startTime.split(':')[0]) >= 12)? 'pm' : 'am'}
        </div> */}
      </div>
      
      
    </div>

  );
}

StartTimeEditor.propTypes = {
  formik: PropTypes.object.isRequired,
  onBlurFunction: PropTypes.func.isRequired,
};
export default connect(StartTimeEditor);