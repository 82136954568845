import React,{ useEffect } from 'react';
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField"
import './SchoolDayForm.scss';

const SchoolDayForm = () => {

    useEffect(() => { // Executes only once when rendering for the component for the first time.
        // Ignores focus on the back button, hence it continues to 'Next' button.
        var focusable = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        focusable[focusable.length - 2].tabIndex=-1;
    }, []);


    return (
        <div className="schooldayform">
            <BasicFormField
                fieldName='startTime'
                labelText='START TIME'
                backgroundText=''
                type='time'
                optional="false"
                isTooltipText="true"
                tooltipmessage="<b>When does the school day typically start for students on most days?</b> You will be able to edit this later, if you have different start times for different days."
            />
            <BasicFormField
                fieldName='lunchDuration'
                labelText='LUNCH DURATION'
                backgroundText='min'
                type='text'
                optional="false"
                errorMsg="Minutes must be between 1 and 240"
                minValue={1}
                maxValue={240}
                tooltipmessage="How many minutes do you designate for lunch in your bell schedule?"
                isTooltipText="true"
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}

            />
            <BasicFormField
                fieldName='lunchesPerDay'
                labelText='# OF LUNCHES PER DAY'
                backgroundText='lunches'
                type='text'
                optional="false"
                errorMsg="Number of lunches must be between 1 and 5"
                minValue={1}
                maxValue={5}
                tooltipmessage="<b>How many different lunch periods do you offer students?</b> Some schools designate lunch for groups of students at different times, perhaps if their cafeteria can not accommodate all students at one time.  You will be able to edit this selection later if needed."
                isTooltipText="true"
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}

            />
            <BasicFormField
                fieldName='passingTime'
                labelText='PASSING TIME'
                backgroundText='min'
                type='text'
                optional="false"
                errorMsg="Minutes must be between 1 and 60"
                minValue={1}
                maxValue={60}
                tooltipmessage="<b>How much time do you typically have for students to pass through the halls to get to their next period?</b>  If you have different passing times between periods, you will be able to edit specific passing times when you are editing your bell schedule."
                isTooltipText="true"
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}

            />
            <BasicFormField
                fieldName='periodDuration'
                labelText='PERIOD DURATION'
                backgroundText='min'
                type='text'
                optional="true"
                errorMsg="Minutes must be between 1 and 400"
                minValue={1}
                maxValue={400}
                tooltipmessage="<b>How many minutes long are MOST of your periods?</b>  Some schools have periods of different lengths.  Enter the most common length of time here. You will be able to edit specific period lengths when you are editing your bell schedule."
                isTooltipText="true"
                onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
            />
        </div>
    );
}

export default SchoolDayForm;