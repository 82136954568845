import React from 'react';
import './WeeklyViewSchedule.scss';
import WeeklyViewStack from '../WeeklyViewStack/WeeklyViewStack';


import PropTypes from 'prop-types';

const WeeklyViewSchedule = ({weeklyviewschedulekey, weeklyviewstacksarray, daytypes, weekarray, dayTypeCountArray }) => {
  var daynames = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  let timesarray = [];
  let startimestemparr=[];
  let endtimestemparr=[];
  let components=[];
  let ar=[];
  let minutedifferencearray=[];
  minutedifferencearray=getTimeDifferencearray();
 

function arrayRotate(arr, n) {
  for(let i = 0; i < n; i++){
    arr.push(arr.shift());
  }

  return arr;
}


function getStarthours(dayType,i) {   
    minutedifferencearray.forEach((value, i) =>{   
      if (dayType.id === value[1].id) { 
        startimestemparr.push([value,dayType]);
        endtimestemparr.push([value,dayType]);
        
      }
    });
}

const AddMinutes = (time, minutesToAdd) => {
  if(minutesToAdd === undefined) minutesToAdd = 0;
  if(time === undefined) time = "00:00";
  let pieces = time.split(':');
  let hour;
  let minute;

  if(pieces.length >= 2) {
    hour = Number(pieces[0]);
    minute = Number(pieces[1]) + Number(minutesToAdd);
  }

  if(minute >= 60){
    hour += Math.floor(minute / 60);
    if(hour > 23) hour = 0;
    minute %= 60;
  }

  let hourString = (hour < 10) ? `0${hour}`:`${hour}`;
  let minuteString = (minute < 10) ? `0${minute}`:`${minute}`;

  return `${hourString}:${minuteString}`
}



function removeStringDuplicates(myArr) {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[1]).indexOf(obj[1]) === pos;
  });
}

function removeDuplicates(myArr) {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[0][1].startTime).indexOf(obj[0][1].startTime) === pos;
  });
}

function removeEndTimeDuplicates(myArr) {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[0][1].timeblocks[mapObj[0][1].timeblocks.length-1].endTime).indexOf(  obj[0][1].timeblocks[obj[0][1].timeblocks.length-1].endTime  ) === pos;
  });
}

const removeTimeZeros = (time) => {   
    if(time !== undefined){
        let pieces = time.split(':');
        let hour=pieces[0];
        let minutes=pieces[1];
        let hourString = (hour < 10) ? `${hour[1]}`:((hour>12 && hour<=24)?`${hour-12}`:`${hour}`);
        let minuteString = (minutes < 10) ? `${minutes}`:`${minutes}`;
        let daytime=(hour >=12) ? 'pm':'am';
        return `${hourString}:${minuteString} ${(hour < 10) ? ' ':''}${daytime}`
    }

    return "00:00";
}

function getTimeDifferenceinMinutes(start, end) {
  start = start.split(":");
  end = end.split(":");
  var startDate = new Date(0, 0, 0, start[0], start[1], 0);
  var endDate = new Date(0, 0, 0, end[0], end[1], 0);
  var diff = endDate.getTime() - startDate.getTime();
  let minutesdifference= Math.floor(diff / 60000);
  return Math.abs(minutesdifference);
}

function strtimeToMinutes(time) {
  time = time.split(":");
  var startDate = new Date(0, 0, 0, time[0], time[1], 0);
  let minutes= Math.floor(startDate.getTime() /1000 / 60);
  return minutes;// Minutes
}



function getDaytypesStartTimes(daytypesarray) { // Gets all the daytypes' start times.
  let starttimes=[];
  daytypesarray.forEach((daytypeelement, index) => {
    starttimes.push(daytypeelement.startTime);      
    ar.push(daytypeelement.name);    
    
  });
  return starttimes;  
}

function getMinStartTime(arr) {
  var len = arr.length;
  var min = Infinity;
  var time="";
  while (len--) {
    if (strtimeToMinutes(arr[len]) < min) {
      min = strtimeToMinutes(arr[len]);
      time=arr[len];
    }
  }

  return time;
}


function getTimeDifferencearray() { // Array with time differences between earliest start time and other start times. 
  let starttimesarr=[];
  let differencearray=[];
  let daytypesInThatWeek=daytypes.filter((daytype,i,arr) => weekarray.days.some((weekdayid) => daytype.id === weekdayid));

  starttimesarr=getDaytypesStartTimes(daytypesInThatWeek); // ["07:00","08:00","13:00"]

  let initialtime=getMinStartTime(starttimesarr);
  starttimesarr.forEach((time, index) => {


    let startTime = getTimeDifferenceinMinutes(initialtime,time);
    


    let endTimeDurationDifference = 0;
    for(let i = 0; i < daytypesInThatWeek[index].timeblocks.length; i++){
      endTimeDurationDifference += Math.floor(Number(daytypesInThatWeek[index].timeblocks[i].duration));
      if( i < daytypesInThatWeek[index].timeblocks.length - 1){
        endTimeDurationDifference += Number(daytypesInThatWeek[index].timeblocks[i].passingDuration);
      }
    }

    

    if(daytypesInThatWeek[index].lunchesPlaced > 0)endTimeDurationDifference += Math.floor((Number(daytypesInThatWeek[index].lunchDuration) + Number(daytypesInThatWeek[index].lunchPassingDuration)));
    
    if ( Number(daytypesInThatWeek[index].lunchesPositions[0]) === daytypesInThatWeek[index].timeblocks.length-1 ) {
      let size=daytypesInThatWeek[index].timeblocks.length;
      endTimeDurationDifference += Number(daytypesInThatWeek[index].timeblocks[size-1].passingDuration) - Number(daytypesInThatWeek[index].lunchPassingDuration);
    }

    endTimeDurationDifference=Math.floor(endTimeDurationDifference*1.6);
    endTimeDurationDifference += Math.floor(startTime*1.6);

    differencearray.push([Math.floor(startTime*1.6),daytypesInThatWeek[index], endTimeDurationDifference]);    
          
  });

  return differencearray; // [0,60, 360] time difference in minutes between earlier start time and latest other start times.

}
  
  const GetWeeklyDayStacks = () => {
    let weekdaytypeidarray=weekarray.days;
    let daytypename="";
    let count = 0;
    timesarray=[]
   
    
    for( let i = 0; i < 5; i ++) {
      let weekdayid=weekdaytypeidarray[i];
      if (weekdayid===-1) {
        components.push(
          <div key={'weeklydiv/' +  weeklyviewschedulekey + '/weeklystack/' + i.toString()} className="weeklyviewschedule-element">
            <WeeklyViewStack timeblockarray={[]} weeklyviewstackkey={{ weeklyviewschedulekey } + '/weeklystack/' + { i }} dayname={daynames[i]} lunchesPositions={[]} lunchesPlaced={0} daytypename="NO DAYTYPE" dayType={{}}  />
          </div>
        )               
      }else{
        let daytypeidNumber=Number(weekdayid);
        let dayType = daytypes.filter(daytype => daytype.id === daytypeidNumber)[0];
        daytypename=dayType.name;
        
        let timeblocks=arrayRotate([...weeklyviewstacksarray[count]],dayTypeCountArray[i]);
       
        

        getStarthours(dayType,i);

        components.push(
          <div key={'weeklydiv/' +  weeklyviewschedulekey + '/weeklystack/' + i.toString()} className="weeklyviewschedule-element">
            <WeeklyViewStack timeblockarray={timeblocks} weeklyviewstackkey={{ weeklyviewschedulekey } + '/weeklystack/' + { i }} dayname={daynames[i]} lunchesPositions={dayType.lunchesPositions} lunchesPlaced={(weekdayid===-1)?0:dayType.lunchesPlaced} daytypename={daytypename} dayType={dayType} startimesdiffarray={minutedifferencearray}  />
          </div>
        ) 
        count++;
      }
    
    }

    startimestemparr = removeDuplicates(startimestemparr);
    endtimestemparr= removeEndTimeDuplicates(endtimestemparr);
    
  

    let finaltimesarray=[]; // This array holds start and end times altogether.
    // Inserts start  times.
    for (let index = 0; index < startimestemparr.length; index++) {
      let element=startimestemparr[index][0];
      let starttimemargintop=Math.floor(element[0])+53;
      let starttimehourstring=removeTimeZeros(element[1].startTime);
  
      finaltimesarray.push([starttimemargintop,starttimehourstring]);
    }

    
    // Inserts end times.
    for (let index = 0; index < endtimestemparr.length; index++) {
      let endtimeelement=endtimestemparr[index][0];
      let endtimemargintop=Math.floor(endtimeelement[2])+53;
      let dayType=endtimeelement[1];
      let endtimehourstring="";
      if (Number(dayType.lunchesPositions.filter(lunch => lunch !== -1 && lunch !== "" && lunch !== undefined)[0]) === dayType.timeblocks.length-1) {

        let endTime= AddMinutes(AddMinutes(dayType.timeblocks[dayType.timeblocks.length-1].endTime, dayType.timeblocks[dayType.timeblocks.length-1].passingDuration), dayType.lunchDuration )
        endtimehourstring=removeTimeZeros(endTime);

        
      }
      else{
        endtimehourstring=removeTimeZeros(dayType.timeblocks[dayType.timeblocks.length-1].endTime);
      }

      
      
      finaltimesarray.push([endtimemargintop ,endtimehourstring]);
    }



    // Insertion of start and end times for html display on page.
    let timesarr=finaltimesarray.sort((a, b) => a[0] - b[0]);
    timesarr = removeStringDuplicates(timesarr)
    if (timesarr.length>0) {
      let timestyle = {marginTop: `${(timesarr[0][0])}px`, position: `absolute`, };
      timesarray.push( 
        <div key={'time/'+0 } id= {'time/'+0} style={timestyle}  >{timesarr[0][1]}</div>
      ); 
      for (let index = 1; index < timesarr.length; index++) {
        let difference=Math.abs(timesarr[index][0] - timesarr[index-1][0]);
        if (difference <= 13 ) timesarr[index][0]+=(13-difference); 

        timestyle = {marginTop: `${(timesarr[index][0])}px`, position: `absolute`, };
        timesarray.push( 
            <div key={'time/'+index.toString()} id= {'time/'+index.toString()} style={timestyle}  >{timesarr[index][1]}</div>
        ); 
      }
    }
    
  
  }


   
  return (
    <div className="weeklyviewschedule">
      <div className="weeklyviewschedule-container">
        {GetWeeklyDayStacks()}
        <div className="weeklyviewschedule-element">
          <div className="starttimehours">{timesarray}</div>
          
        </div>
        {components}
        
        
      </div>
    </div>
  );
}



WeeklyViewSchedule.propTypes = {
  weeklyviewschedulekey: PropTypes.string.isRequired,
  weeklyviewstacksarray: PropTypes.array.isRequired,
  weekarray: PropTypes.object.isRequired,
  daytypes: PropTypes.array.isRequired,
  dayTypeCountArray: PropTypes.array.isRequired,
  
};


export default WeeklyViewSchedule;
