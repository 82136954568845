import React from 'react';
import './WeeklyViewStack.scss';
import PropTypes from 'prop-types';


const WeeklyViewStack = ({ timeblockarray, weeklyviewstackkey, dayname, lunchesPositions, lunchesPlaced, daytypename, dayType, startimesdiffarray}) => {// Timeblockarray holds the periods of a single day.

  const minDuration = 15;

  function truncateName(name) {
    if(name.length > 5) name = name.substring(0,5) + "...";
    return name;
  }

  // eslint-disable-next-line complexity
  const getDayView = () => {

    let daytypeMargin = 0;

    for(let i = 0; i < startimesdiffarray.length; i++){
      if(dayType.id === startimesdiffarray[i][1].id){
        daytypeMargin = startimesdiffarray[i][0];
        
      }

      
    }

    let timeblockStartMinutes = [];

    let lunchDurationSum = Number(dayType.lunchDuration) + Number(dayType.lunchPassingDuration);
    
    if (Number.isNaN(lunchDurationSum)) {
      lunchDurationSum=0;
    }

    lunchesPositions = lunchesPositions.filter(position => position !== "" && position !== -1 && position !== undefined).map(lunch => Number(lunch));
    lunchesPositions.sort();
    
    for(let i = 0; i < timeblockarray.length; i++){
      timeblockStartMinutes.push([]);

      if(lunchesPlaced > 0){
        for(let j = 0; j < lunchesPlaced; j++){
          timeblockStartMinutes[i].push(0);
        }
      }
      else{
        timeblockStartMinutes[i].push(0);
      }
      
    }

    for(let i = 1; i < timeblockarray.length; i++){

      if(lunchesPlaced > 0){
        for(let j = 0; j < lunchesPlaced; j++){
          let timeblockDuration = timeblockarray[i-1].props.durationSum > minDuration ? timeblockarray[i-1].props.durationSum : minDuration;
          timeblockStartMinutes[i][j] = timeblockStartMinutes[i-1][j] + timeblockDuration;
          
          if(lunchesPositions[j] === i-1){
            let lunchDuration = lunchDurationSum > minDuration ? lunchDurationSum : minDuration;
            timeblockStartMinutes[i][j] += lunchDuration;
          }
          
        }
      }
      else{
        timeblockStartMinutes[i][0] = timeblockStartMinutes[i-1][0] + timeblockarray[i-1].props.durationSum;
      }

      
    }

    let blocks = [];

    let extra = lunchesPlaced > 1 ? (lunchesPlaced - 2) * 70 : 0;
    let stackWidth = 140 + extra;
    
    for(let i = 0; i < timeblockarray.length; i++){
      let count = 0;

      if(lunchesPlaced > 0){
        for(let j = 0; j < lunchesPlaced; j++){
          count ++;
          if(j === lunchesPlaced - 1 || timeblockStartMinutes[i][j] !== timeblockStartMinutes[i][j+1]){
            let duration = timeblockarray[i].props.durationSum > minDuration ? timeblockarray[i].props.durationSum : minDuration;
            let durationSum = duration;
            // eslint-disable-next-line max-depth
            if( i === dayType.timeblocks.length - 1 && lunchesPositions[j] !== dayType.timeblocks.length - 1) durationSum -= Number(dayType.timeblocks[i].passingDuration);

            blocks.push({
              name: timeblockarray[i].props.periodname,
              width: (stackWidth / lunchesPlaced) * count,
              height: Math.floor(durationSum * 1.6),
              offSetX: (j - (count - 1)) * (stackWidth / lunchesPlaced),
              offSetY: Math.floor(timeblockStartMinutes[i][j] * 1.6),
              color: timeblockarray[i].props.color
            });
            count = 0;
          }
        }
      }
      else{
        let duration = timeblockarray[i].props.durationSum > minDuration ? timeblockarray[i].props.durationSum : minDuration;
        let durationSum = duration;
        if( i === dayType.timeblocks.length - 1) durationSum -= Number(dayType.timeblocks[i].passingDuration);

        blocks.push({
          name: timeblockarray[i].props.periodname,
          width: stackWidth,
          height: Math.floor(durationSum * 1.6),
          offSetX: 0,
          offSetY: Math.floor(timeblockStartMinutes[i][0] * 1.6),
          color: timeblockarray[i].props.color
        });
      }

      
    }

    for(let i = 0; i < lunchesPlaced; i++){
      let timeblockduration = timeblockarray[lunchesPositions[i]].props.durationSum > minDuration ? timeblockarray[lunchesPositions[i]].props.durationSum : minDuration;
      let durationSum = lunchDurationSum;
      if(lunchesPositions[i] === dayType.timeblocks.length - 1) durationSum -= Number(dayType.lunchPassingDuration);

      let duration = durationSum > minDuration ? durationSum : minDuration;

      blocks.push({
        name: "Lunch",
        width: stackWidth / lunchesPlaced,
        height: Math.floor(duration * 1.6),
        offSetX: i * (stackWidth / lunchesPlaced),
        offSetY:  Math.floor((timeblockStartMinutes[lunchesPositions[i]][i] + timeblockduration) * 1.6),
        color: "#E4E5EB"
      });
    }

  
    let blockComponents = blocks.map((block,index) => 
      // eslint-disable-next-line react/no-array-index-key
      

      // eslint-disable-next-line react/no-array-index-key
      <div key={`block_${block.name}_${index}`} style={{
        position: "absolute",
        minWidth: block.width - 3,
        maxWidth: block.width - 3,
        minHeight: block.height - 3,
        maxHeight: block.height - 3,
        backgroundColor: block.color,
        top: block.offSetY + daytypeMargin,
        left: block.offSetX,
      }} className = "block">
        {truncateName(block.name)}
      </div>
    )

    let divHeight = 0;

    for(let i = 0; i < timeblockarray.length; i++){
      let duration = timeblockarray[i].props.durationSum > minDuration ? timeblockarray[i].props.durationSum : minDuration;
      divHeight += Math.floor(duration * 1.6);
    }

    let duration = lunchDurationSum > minDuration ? lunchDurationSum : minDuration;
    divHeight += Math.floor(duration * 1.6)  + daytypeMargin;
    
    let divWidth = blocks.length > 0? blocks[0].width - 2 : 140;


  
    return <div style = {{
      position: "relative",
      width: divWidth,
      height: divHeight,
    }}>{blockComponents}</div>
    
  }

  return (

    <div className="weeklyviewstack">
      <div className='weekday-spacing'>{dayname}</div>
      <div className='daytypename'>{daytypename}</div>
      
      {getDayView()}
    </div> 

  );
}


WeeklyViewStack.defaultProps = {
  startimesdiffarray: []

};

WeeklyViewStack.propTypes = {
  timeblockarray: PropTypes.array.isRequired,
  weeklyviewstackkey: PropTypes.string.isRequired,
  dayname: PropTypes.string.isRequired,
  lunchesPositions: PropTypes.array.isRequired,
  lunchesPlaced:PropTypes.number.isRequired,
  daytypename: PropTypes.string.isRequired,
  dayType: PropTypes.object.isRequired,
  startimesdiffarray: PropTypes.array,
};

export default WeeklyViewStack;
