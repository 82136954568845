import React from 'react';
import PropTypes from 'prop-types';
import './RadiusFormField.scss';
import { connect } from "formik";


const RadiusFormField = props => {
    const {className, fieldName, value, text, secondaryText} = props;
    const fieldValue = props.formik.values[fieldName];

    const handleOnChange = () => {
      props.formik.setFieldValue(fieldName,value);
    }

    return (
      <div className="radiusformfield">
          <div className={`row ${className}`} onClick={handleOnChange}>
            <input className="radio" type="radio" name={fieldName} value={value} checked={(fieldValue === value)?"checked":undefined} onChange={handleOnChange} />
            {(text.length > 0)?
              <div className="radio_text">{text}</div>
              :
              ""
            }
            {(secondaryText.length > 0)?
              <div className="radio_secondary_text">{secondaryText}</div>
              :
              ""
            }
          </div>
      </div>
    );
}

RadiusFormField.defaultProps = {
  className: "",
  text: "",
  secondaryText: "",
};

RadiusFormField.propTypes = {
  formik: PropTypes.object.isRequired,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  text: PropTypes.string,
  secondaryText: PropTypes.string,
  value: PropTypes.string.isRequired,
};
export default connect(RadiusFormField);
