import React from 'react';
import './OverviewHeader.scss';
import Tooltipcomp from "@abl/components/src/ToolTip/Tooltip"
import TooltipIcon from "../../assets/info_icon.svg"
import PropTypes from 'prop-types';

const OverviewHeader = ({ instructionalminutesperyear, scheduletype, numberdaytypes }) => {

  const numberWithoutCommas = (x) => {
    if (x === null) return '';
    x = x.toString();
    x = x.split(",");
    x = x.join("");
    return x;
  }

  const numberWithCommas = (x) => {
    x = numberWithoutCommas(x);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  instructionalminutesperyear = Number(numberWithoutCommas(instructionalminutesperyear));

  return (

    <div className="overviewheader">

      <div className="header-box">
        <div className="d-flex justify-content-center text-left">

          <div className="yantramanav header-child" >
            <div className="mx-1 d-inline-flex">
              <div className="flex-fill mr-2">Instructional Min Per Year</div>
              {(window.innerWidth <= 885)? 
                <Tooltipcomp imgpath={TooltipIcon} classnametooltip="instructionalyeartooltip flex-fill mr-1" tooltipmessage="This is the total number of instructional minutes per year across all day types." tooltipid="overviewheader_tooltipid" tooltipplacement="bottom" />
              :
                <Tooltipcomp imgpath={TooltipIcon} classnametooltip="instructionalyeartooltip flex-fill mr-1" tooltipmessage="This is the total number of instructional minutes per year across all day types." tooltipid="overviewheader_tooltipid" />

              }
              
            </div>
            <div className="font-properties row ml-2">{`${numberWithCommas(instructionalminutesperyear)}`}</div>
            <div className="separator" ></div>
          </div>

          <div className="yantramanav header-child mx-1" >
            <div className="row mx-3">Schedule Type</div>
            <div className='font-properties row ml-3'>{scheduletype}</div>
            <div className="separator" ></div>
          </div>

          <div className="yantramanav header-child mx-1">
            <div className="row mx-3">Day Types</div>
            <div className='font-properties row ml-3'>{numberdaytypes}</div>
          </div>

          
        </div>

      </div>

    </div>
  );
}



OverviewHeader.propTypes = {
  instructionalminutesperyear: PropTypes.string.isRequired,
  scheduletype: PropTypes.string.isRequired,
  numberdaytypes: PropTypes.string.isRequired

};


export default OverviewHeader;
