import React from 'react';
import './AddTimeblockWindow.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect, Field } from "formik";
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';
import onClickOutside from "react-onclickoutside";
import API from '@abl/components/src/api/api';


const AddTimeblockWindow = props => {
	const {dayTypes, selectedDayType} = props.formik.values;

	const dayType = dayTypes[selectedDayType];
	const {selectedPeriodToCreate, createTimeBlockOption, periods, scheduleId} = props.formik.values;

	const { timeblocks } = dayType;

	const handleClickOutside = evt => {
        var form = document.getElementById("addtimeblockwindow");
        form.classList.remove("active");
	};
	
    const onCancelFunction = () => {
        var window = document.getElementById("addtimeblockwindow");
        window.classList.remove("active");
	}
	
	function getRandomColor() {
		var letters = '6789AB';
		var color = '#';
		for (var i = 0; i < 6; i++) {
		  color += letters[Math.floor(Math.random() * 6)];
		}

		return color;
	  }

	const AddMinutes = (time, minutesToAdd) => {
		let pieces = time.split(':');
		let hour;
		let minute;

		if(pieces.length >= 2) {
			hour = Number(pieces[0]);
			minute = Number(pieces[1]) + Number(minutesToAdd);
		}

		if(minute >= 60){
			hour += Math.floor(minute / 60);
			if(hour > 23) hour = 0;
			minute %= 60;
		}

		let hourString = (hour < 10) ? `0${hour}`:`${hour}`;
		let minuteString = (minute < 10) ? `0${minute}`:`${minute}`;

		return `${hourString}:${minuteString}`
	}

    const onSaveFunction = async () => {

		if(createTimeBlockOption === "existing"){
			let timeblock = {
				dayTypeId: dayType.id,
				position: timeblocks.length,
				periodId: periods[Number(selectedPeriodToCreate)].id,
				duration: Number(props.formik.values.periodDuration),
				passingDuration: Number(props.formik.values.passingTime),
				isLocked: false,
				isPassingLocked: false,
				startTime: timeblocks[timeblocks.length - 1].passingEndTime,
				endTime: AddMinutes(timeblocks[timeblocks.length - 1].passingEndTime, Number(props.formik.values.periodDuration)),
				passingEndTime: AddMinutes(timeblocks[timeblocks.length - 1].passingEndTime, Number(props.formik.values.periodDuration) + Number(props.formik.values.passingTime)),
			}
			let response = await API.post('timeblocks', timeblock);
			timeblock.id = response.data.id;
			timeblocks.push(timeblock);
			console.log("timeblock created")
			
			props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,timeblocks);
		}
    else if(createTimeBlockOption === "new"){
			

			let response = await API.post('periods', {
				scheduleId: scheduleId,
				id: periods.length,
				name: `${periods.length + 1}`,
				isInstructional: true,
				color: getRandomColor()
			});

			let period = response.data;
			periods.push(period);
			console.log("period created")

			let timeblock = {
				dayTypeId: dayType.id,
				position: timeblocks.length,
				periodId: period.id,
				duration: Number(props.formik.values.periodDuration),
				passingDuration: Number(props.formik.values.passingTime),
				isLocked: false,
				isPassingLocked: false,
				startTime: timeblocks[timeblocks.length - 1].passingEndTime,
				endTime: AddMinutes(timeblocks[timeblocks.length - 1].passingEndTime, Number(props.formik.values.periodDuration)),
				passingEndTime: AddMinutes(timeblocks[timeblocks.length - 1].passingEndTime, Number(props.formik.values.periodDuration) + Number(props.formik.values.passingTime)),

			}
			response = await API.post('timeblocks', timeblock);
			timeblock.id = response.data.id;
			timeblocks.push(timeblock);
			console.log("timeblock created")

			props.formik.setFieldValue(`dayTypes.periods`,periods);
			props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks`,timeblocks);
		}

        onCancelFunction();
    }

    return (
      <div  id="addtimeblockwindow" className="addtimeblockwindow">
		<KeyBindingEventComponent  keyname='esc' handlerFunction={handleClickOutside} />
        <div className="window">
            <div className="title">
                Add a Timeblock
            </div>

			<div className = "centered_form">
				<div className = "radios">
					<div className = "radio row" >
						<Field type="radio" name="createTimeBlockOption" value="new" />
						<div className="radio_text">Create a new Timeblock</div><br />
					</div>
					<div className = "radio row" >
						<Field type="radio" name="createTimeBlockOption" value="existing" />
						<div className="radio_text">Add an existing Timeblock</div><br />
					</div>
				</div>
				<div className={createTimeBlockOption === "new" ? "dropdown_disabled" : undefined}>
					<DropDownFormField fieldName="selectedPeriodToCreate" options={props.formik.values.periods.map(((period,index) => { return {value: index , text: period.name }}))} />
				</div>
			</div>
            
            
            <div className="buttons_container">
                <FunctionalButton
                    id = "send_btn"
                    className="next_btn save_btn"
                    text="Save"
                    onclickhandler={onSaveFunction}
                />
                <FunctionalButton
                    className="button_clickable_text_a cancel_btn"
                    text="Cancel"
                    onclickhandler={onCancelFunction}
                />
            </div>
        </div>
        <div className="overlay">
          
        </div>
      </div>
    );
}

AddTimeblockWindow.propTypes = {
    formik: PropTypes.object.isRequired
};


export default onClickOutside(connect(AddTimeblockWindow), {
	handleClickOutside: function(instance){
	  return function(e) {
		var form = document.getElementById("addtimeblockwindow");
        form.classList.remove("active");
	  };
	}
})