import React, { useEffect } from 'react';

import './ExportForm.scss';
import BasicFormField from '@abl/components/src/BasicFormField/BasicFormField.jsx';
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';
import SchoolSelector from '@abl/components/src/SchoolSelector/SchoolSelector';
import onClickOutside from "react-onclickoutside";

const ExportForm = props => {

    const states = [
        undefined,
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ]

    const handleClickOutside = evt => {
        var form = document.getElementById("exportform");
        form.classList.remove("active");
    }


    const onCancelFunction = () => {
        var form = document.getElementById("exportform");
        form.classList.remove("active");
    }

    

    useEffect(() => {
        var button = document.getElementById("send_btn_export");
        if(button){
            let { email, role, lastName, firstName, schoolName, state } = props.formik.values.errors;
            if (email || role || lastName || firstName || schoolName || state) {
              button.classList.add("disabled");
            }
            else {
              button.classList.remove("disabled");
            }
        }
        
    });

    let tabIndex = 10;

    return (
      <div id="exportform" className="exportform">
        <KeyBindingEventComponent keyname="esc" handlerFunction={handleClickOutside} />
        <div className="form_window">
            <div className="title">
                Fill out this form
            </div>
            <div className="subtitle">
                {props.subText}
            </div>
            <div className="centered_form">
                <DropDownFormField
                    tabIndex={tabIndex++}
                    fieldName='state'
                    labelText='STATE YOUR SCHOOL IS IN'
                    emptyText="select a state"
                    options ={states.map( (state) => {return { value: state, text: state }})}
                    onChangeFunction = {(event) => {
                        // eslint-disable-next-line react/no-this-in-sfc
                        props.formik.setFieldValue("state",event.target.value);
                        props.formik.setFieldValue("schoolName","");
                        props.formik.setFieldValue("schools",[]);
                        props.formik.setFieldValue("searching",false);
                    }}
                />
                <SchoolSelector tabIndex={tabIndex++} />
                <BasicFormField
                    tabIndex={tabIndex++}
                    fieldName='firstName'
                    labelText='FIRST NAME'
                    type='text'
                    emptyText="first name"
                    optional="true_not_optional_text"
                    isNumber={false}
                    onKeyPressFunction={(event) => { if (event.target.value.length > 50) event.preventDefault() }}
                    
                />
                <BasicFormField
                    tabIndex={tabIndex++}
                    fieldName='lastName'
                    labelText='LAST NAME'
                    type='text'
                    emptyText="last name"
                    optional="true_not_optional_text"
                    isNumber={false}
                    onKeyPressFunction={(event) => { if (event.target.value.length > 50) event.preventDefault() }}
                />
                <DropDownFormField
                    tabIndex={tabIndex++}
                    fieldName='role'
                    labelText='YOUR ROLE'
                    emptyText="select your role"
                    options ={[
                                {},
                                {value:"Principal", text: "Principal"},
                                {value:"Assistant Principal", text: "Assistant Principal"},
                                {value:"Guidance Counselor", text: "Guidance Counselor"},
                                {value:"Registrar/Programmer", text: "Registrar/Programmer"},
                                {value:"Lead Teacher", text: "Lead Teacher"},
                                {value:"District Admin", text: "District Admin"},
                                {value:"Other", text: "Other"}
                            ]}
                />
                <BasicFormField
                    tabIndex={tabIndex++}
                    fieldName='email'
                    labelText='EMAIL'
                    type='text'
                    emptyText="email"
                    optional="true_not_optional_text"
                    isNumber={false}
                    onKeyPressFunction={(event) => { if (event.target.value.length > 50) event.preventDefault() }}
                />
            </div>
            
            <div className="buttons_container">
                <FunctionalButton
                    tabIndex={tabIndex++}
                    id = "send_btn_export"
                    className="next_btn send_btn"
                    text="Send"
                    onclickhandler={() => {
                        onCancelFunction();
                        props.formik.setFieldValue("readyToExport",true);
                        var form = document.getElementById("thankyou_window");
                        form.classList.add("active");
                    }}
                />
                <FunctionalButton
                    tabIndex={tabIndex++}
                    className="button_clickable_text_a cancel_btn"
                    text="Cancel"
                    onclickhandler={onCancelFunction}
                />
            </div>
        </div>
        <div className="overlay">
          
        </div>
      </div>
    );
}

ExportForm.propTypes = {
    formik: PropTypes.object.isRequired,
    subText: PropTypes.string.isRequired,
};




export default onClickOutside(connect(ExportForm), {
	handleClickOutside: function(instance){
	  return function(e) {
		var form = document.getElementById("exportform");
        form.classList.remove("active");
	  };
	}
})