import React from 'react';
import './AddingMinutesWindow.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import RadiusFormField from '../RadiusFormField/RadiusFormField';
import PeriodMultiSelector from '../PeriodMultiSelector/PeriodMultiSelector';
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';

const AddingMinutesWindow = props => {

    const {takingOption, selectedDayType, selectedTimeblock, dayTypes, minutesToAdd, selectedMinuteChangeMode, askAfterEdit} = props.formik.values;

    AddingMinutesWindow.onCancelFunction = () => {

        var form = document.getElementById("addingminuteswindow");
        form.classList.remove("active");
        var selector = document.getElementById("adding_period_multiselector");
        selector.classList.remove("active");   
        
        

        let mode = selectedMinuteChangeMode;
        switch (mode) {
            case "timeblock":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${selectedTimeblock}].duration`,Number(dayTypes[selectedDayType].timeblocks[selectedTimeblock].duration) - minutesToAdd);
                break;
            case "passing":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].timeblocks[${selectedTimeblock}].passingDuration`,Number(dayTypes[selectedDayType].timeblocks[selectedTimeblock].passingDuration) - minutesToAdd);
                break;
            case "lunchblock":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchDuration`,Number(dayTypes[selectedDayType].lunchDuration) - minutesToAdd);
                break;
            case "lunchpassing":
                props.formik.setFieldValue(`dayTypes[${selectedDayType}].lunchPassingDuration`,Number(dayTypes[selectedDayType].lunchPassingDuration) - minutesToAdd);
                break;
            default:
                break;
        }

        props.formik.setFieldValue("minutesToAdd",0);
        props.formik.setFieldValue("changeInProgress", false);

    }

    const onSaveFunction = () => {
        switch(takingOption){
            case "noTake":
                props.formik.values.noDistributeFunction();
                break;
            case "takeInstructional":
                    props.formik.values.distributeTimeblocksFunction();
                    break;
            case "takePeriods":
                    props.formik.values.distributePeriodsFunction();
                    break;
            case "takeNonInstructional":
                    props.formik.values.distributePassingsFunction();
                    break;
            default:
                break;
        }

        var form = document.getElementById("addingminuteswindow");
        form.classList.remove("active");
        var selector = document.getElementById("adding_period_multiselector");
        selector.classList.remove("active");
        props.formik.setFieldValue("minutesToAdd",0);

    }

    const DoNotShowAgain = (e) => {
        props.formik.setFieldValue("askAfterEdit",!askAfterEdit);
    }

    return (
      <div id="addingminuteswindow" className="addingminuteswindow">
          <KeyBindingEventComponent  keyname='esc' handlerFunction={AddingMinutesWindow.onCancelFunction} />
        <div className="window">
            <div className="title">
                You&apos;re adding {props.formik.values.minutesToAdd} minutes to this timeblock
            </div>
            <div className="subtitle">
              Where should the minutes come from?
            </div>
            <div className="radios mb-1">
                <RadiusFormField
                    fieldName = "takingOption"
                    value = "takeInstructional"
                    text = "Take minutes as evenly as possible from instructional periods."
                />
                <RadiusFormField
                    fieldName = "takingOption"
                    value = "noTake"
                    text = "Do not take minutes from other periods, and allow the school day to end at a different time."
                    secondaryText = "The start and end times following this period will be adjusted."
                />
                <RadiusFormField
                    fieldName = "takingOption"
                    value = "takePeriods"
                    text = "Take minutes as evenly as possible from the following periods."
                />
                
                <PeriodMultiSelector id="adding_period_multiselector" disabled={takingOption === "takePeriods" ? "false" : "true"} />
                
                <RadiusFormField
                    fieldName = "takingOption"
                    value = "takeNonInstructional"
                    text = "Take minutes as evenly as possible from passing time and non-instructional periods."
                />
            </div>
            <div className="d-flex">
              <div className="donotshowagain flex-grow-1 d-inline-flex">
                <input type="checkbox" name="notShowAgainFreeing" value={!askAfterEdit} checked={!askAfterEdit} onChange={(e) => DoNotShowAgain(e)} />
                <p className="text align-middle flex-glow-1"> Do not show this message again. Always use this setting.</p>
              </div>

              <FunctionalButton
                className="button_clickable_text_a cancel_btn ml-auto"
                text="Cancel"
                onclickhandler={AddingMinutesWindow.onCancelFunction}
              />
              <FunctionalButton
                id = "send_btn"
                className="next_btn send_btn align-center"
                text="Save"
                onclickhandler={onSaveFunction}
              />
            </div>
        </div>
        <div className="overlay" onClick = {AddingMinutesWindow.onCancelFunction}>
          
        </div>
      </div>
    );
}

AddingMinutesWindow.propTypes = {
    formik: PropTypes.object.isRequired
};

export default connect(AddingMinutesWindow)
