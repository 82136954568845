import React from 'react';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const KeyBindingEventComponent = ({ keyname, handlerFunction}) =>   {

    let keylist=[];
    keylist.push(keyname);

    return (
            <KeyboardEventHandler handleKeys={keylist} onKeyEvent={handlerFunction} /> 
    );
    
}


KeyBindingEventComponent.propTypes = {
    keyname: PropTypes.string.isRequired,
    handlerFunction: PropTypes.func.isRequired
};

export default KeyBindingEventComponent;