import React from 'react';
import './AddDayTypeWindow.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { connect } from "formik";
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';
import KeyBindingEventComponent from '@abl/components/src/KeyBindingEventComponent/KeyBindingEventComponent';
import onClickOutside from "react-onclickoutside";
import uuid from 'uuid/v1'
import API from '@abl/components/src/api/api';

const AddDayTypeWindow = props => {
	const {selectedDayTypeToDuplicate, dayTypes} = props.formik.values;

    const handleClickOutside = evt => {
        var form = document.getElementById("adddaytypewindow");
        form.classList.remove("active");
    };

    const onCancelFunction = () => {
        var window = document.getElementById("adddaytypewindow");
        window.classList.remove("active");
    }

    const onAddDayTypeFunction = async () => {
        onCancelFunction();
        let dayTypeToDuplicate = dayTypes[selectedDayTypeToDuplicate];
        props.formik.setFieldValue("selectedDayTypeToDuplicate", 0);
        let newDayType = {};
        newDayType = Object.assign(newDayType, dayTypeToDuplicate);
        newDayType.id = uuid();
        newDayType.name = `NEW DAY ${dayTypes.length + 1}`;
        newDayType.lunchesPositions = [];
        for(let i = 0; i < dayTypeToDuplicate.lunchesPositions.length; i++){
            newDayType.lunchesPositions.push(dayTypeToDuplicate.lunchesPositions[i]);
        }

        newDayType.timeblocks = undefined;

        newDayType.minutesInYear = 0;
        newDayType.lunchesPositions = newDayType.lunchesPositions.map(lunch => lunch === "" ? -1:Number(lunch));

        let response = await API.post("day-types/", newDayType);

        let backendDayType = response.data;
        newDayType.id = backendDayType.id;
        newDayType.timeblocks = [];

        for(let i = 0; i < dayTypeToDuplicate.timeblocks.length; i++){
            let newTimeblock = {};
            Object.assign(newTimeblock, dayTypeToDuplicate.timeblocks[i]);
            newTimeblock.dayTypeId = newDayType.id;
            // eslint-disable-next-line no-await-in-loop
            let response = await API.post("timeblocks/", newTimeblock);
            newDayType.timeblocks.push(response.data);
        }
        
        dayTypes.push(newDayType);
        console.log("dayType created");

		props.formik.setFieldValue("dayTypes", dayTypes);
		props.formik.setFieldValue("dayTypesAmount", dayTypes.length.toString());
        onCancelFunction();
    }

    return (
      <div id="adddaytypewindow" className="adddaytypewindow">
          <KeyBindingEventComponent  keyname='esc' handlerFunction={handleClickOutside} />
        <div className="container window">
            <div className="title mt-4">
                Add a Day Type
            </div>
            
            <div className="centered_form">
                <DropDownFormField
                    labelText="Duplicate existing Day Type" 
                    fieldName="selectedDayTypeToDuplicate" 
                    options={dayTypes.map(((dayType, index) => { return {value: index , text: dayType.name }}))} />
            </div>
            
            
            <div className="buttons_container mr-3 mb-4">
                <FunctionalButton
                    id = "send_btn"
                    className="next_btn save_btn"
                    text="Save"
                    onclickhandler={onAddDayTypeFunction}
                />
                <FunctionalButton
                    className="button_clickable_text_a cancel_btn"
                    text="Cancel"
                    onclickhandler={onCancelFunction}
                />
            </div>
        </div>
        <div className="overlay">
          
        </div>
      </div>
    );
}

AddDayTypeWindow.propTypes = {
    formik: PropTypes.object.isRequired
};



export default onClickOutside(connect(AddDayTypeWindow), {
	handleClickOutside: function(instance){
	  return function(e) {
		var form = document.getElementById("adddaytypewindow");
        form.classList.remove("active");
	  };
	}
})