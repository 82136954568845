import React from 'react';
import './AvailableMinutesBox.scss';
import AvailableMinutesCard from '../AvailableMinutesCard/AvailableMinutesCard';
import PropTypes from 'prop-types';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import TimeLimitsForm from '../TimeLimitsForm/TimeLimitsForm';
import EditImage from '../../assets/edit_pencil.svg';
import {connect} from 'formik';
import API from '@abl/components/src/api/api';

const AvailableMinutesBox = props => {
  const {actualMinutesInYear} = props;
  const {
    daysInSchoolYear,
    minDailyMinutes,
    maxDailyOnSiteMinutes,
    minAnnualMinutes,
    backup,
    selectedDayType,
    dayTypes,
    scrolling
  } = props.formik.values;
  const dayType = dayTypes[selectedDayType];
  const {totalStaffOnSiteMinutes, totalInstructionalMinutes} = dayType;

  const numberWithoutCommas = (x) => {
    x = x.split(",");
    x = x.join("");
    return x;
  }

  const OpenTimeLimitsForm = () => {
    var form = document.getElementById('timelimitsform');
    form.classList.add('active');
    backup.daysInSchoolYear = daysInSchoolYear;
    backup.minAnnualMinutes = minAnnualMinutes;
    backup.minDailyMinutes = minDailyMinutes;
    backup.maxDailyOnSiteMinutes = maxDailyOnSiteMinutes;
    props.formik.setFieldValue('backup', backup);
  };

  const CloseTimeLimitsForm = () => {
    var form = document.getElementById('timelimitsform');
    form.classList.remove('active');
    props.formik.setFieldValue('daysInSchoolYear', backup.daysInSchoolYear);
    props.formik.setFieldValue('minAnnualMinutes', backup.minAnnualMinutes);
    props.formik.setFieldValue('minDailyMinutes', backup.minDailyMinutes);
    props.formik.setFieldValue(
      'maxDailyOnSiteMinutes',
      backup.maxDailyOnSiteMinutes
    );
  };

  const SaveTimeLimitsForm = async () => {
    let schedule = {
      totalAnnualMinutes: props.formik.values.actualMinutesInYear,
      daysInSchoolYear: props.formik.values.daysInSchoolYear.length > 0? Number(numberWithoutCommas(props.formik.values.daysInSchoolYear)) : undefined,
      minDailyMinutes: props.formik.values.minDailyMinutes.length > 0? Number(numberWithoutCommas(props.formik.values.minDailyMinutes)) : undefined,
      maxDailyOnSiteMinutes: props.formik.values.maxDailyOnSiteMinutes.length > 0? Number(numberWithoutCommas(props.formik.values.maxDailyOnSiteMinutes)) : undefined,
      minAnnualMinutes: props.formik.values.minAnnualMinutes.length > 0? Number(numberWithoutCommas(props.formik.values.minAnnualMinutes)) : undefined,
    }
    await API.put(`schedules/${props.formik.values.scheduleId}`, schedule);
    console.log("schedule updated");
    var form = document.getElementById('timelimitsform');
    form.classList.remove('active');

  };

  return (
    <div
      className={
        scrolling
          ? 'availableminutesbox header yantramanav scrolling'
          : 'availableminutesbox header yantramanav'
      }
    >
      <div className="row">
        <TimeLimitsForm
          onCloseFunction={CloseTimeLimitsForm}
          onSaveFunction={SaveTimeLimitsForm}
        />
      </div>
      <div id="timecards" className="centeredbox d-flex flex-wrap">
        <div className="flex-md-grow-0 flex-sm-grow-1">
          <AvailableMinutesCard
            cardTitle="Instructional Time Per Year"
            tooltipmessage="This is the total number of instructional minutes per year across all day types."
            actualMinutes={actualMinutesInYear.toString() || ''}
            totalMinutes={ minAnnualMinutes.toString() || ''}
            isMax={0}
          />
        </div>
        <div className="separator"></div>
        <div className="flex-md-grow-0 flex-sm-grow-1">
          <AvailableMinutesCard
            cardTitle="Instructional Time Per Day"
            actualMinutes={
              totalInstructionalMinutes
                ? totalInstructionalMinutes.toString() || ''
                : '0'
            }
            totalMinutes={minDailyMinutes.toString() || ''}
            isMax={0}
          />{' '}
        </div>
        <div className="flex-md-grow-0 flex-sm-grow-1">
          <AvailableMinutesCard
            cardTitle="Staff Onsite Time Per Day"
            actualMinutes={
              totalStaffOnSiteMinutes ? totalStaffOnSiteMinutes.toString() || '' : '0'
            }
            totalMinutes={maxDailyOnSiteMinutes.toString() || ''}
            isMax={1}
          />{' '}
        </div>

        <div className="">
          <div className="editbuttoncontainer  raleway">
            <FunctionalButton
              imgpath={EditImage}
              className="button_clickable_text_b"
              text="Edit Target Values"
              onclickhandler={OpenTimeLimitsForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AvailableMinutesBox.propTypes = {
  formik: PropTypes.object.isRequired,
  actualMinutesInYear: PropTypes.number.isRequired
};

export default connect(AvailableMinutesBox);
