import { useEffect } from 'react';

export const sendResizeMessage = (autoHeight) => { 
    var height = autoHeight ? 'null' : window.document.body.getBoundingClientRect().height; 
    window.parent.postMessage("resize::" + height,"*");
}
export default (autoSize) => { 
    useEffect(()=> {
        sendResizeMessage(autoSize);
    }, [autoSize])

}