import React from 'react';
import './DailyTimeBlock.scss';
import PropTypes from 'prop-types';

const DailyTimeBlock = ({ periodname, instructionaltime, duration, dailyuniquekey, color }) => {
  var Instructionaltimestring = (instructionaltime === true) ? "Instructional Time" : "";// Converts boolean to string for display.

  
  let periodcolorstyle;
  return (
 
    <div className="timeblock">
      
      {(() => {
        periodcolorstyle = {
          backgroundColor: `${color}`,
          
        }
      })()
      }
      <div style = {periodcolorstyle} className="timeblock-box">
        <div className="row timeblock-row">
          <div className="col timeblock-column">
            <div className="col timeblock-column-periodname"><div>{periodname}</div></div>
            <div className="col timeblock-column-instructional"><div>{(Instructionaltimestring)}</div></div>
          </div>
          <div className="col timeblock-column">
            <div className="timeblock-column-text">
              {duration.toString() + ' min'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DailyTimeBlock.defaultProps = {
  duration: 0,
  instructionaltime: false

};

DailyTimeBlock.propTypes = {
  periodname: PropTypes.string.isRequired,
  instructionaltime: PropTypes.bool,
  duration: PropTypes.number,
  dailyuniquekey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired

};
export default DailyTimeBlock;
