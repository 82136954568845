import React, { useEffect } from "react";
import DayTypesForm from "../../../components/DayTypesForm/DayTypesForm";
import DropDownFormField from '@abl/components/src/DropDownFormField/DropDownFormField';
import InfoPopUp from "@abl/components/src/InfoPopUp/InfoPopUp";
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField.jsx"
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";

import { connect } from "formik";
import PropTypes from 'prop-types';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';

const StepFour = props => {
  
    useResizeHelper(false);
    useEffect(() => { // Executes only once when rendering for the component for the first time.
        // Ignores focus on the back button, hence it continues to Begin Experimenting button.
        var focusable = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        focusable[focusable.length - 2].tabIndex=-1;
    }, []);


    useEffect(() => {
        var buttons = document.getElementById("next_btn");
        let { dayTypesAmount } = props.formik.values;
        let { totalCoursesPerTerm } = props.formik.values.errors;
        if (totalCoursesPerTerm) {
            buttons.classList.add("disabled");
        }
        else {
            buttons.classList.remove("disabled");
            let n = Number(dayTypesAmount);
            for (let i = 1; i <= n; i++) {
                if (props.formik.values.errors[`dayTypePeriods.${i}`]) {
                    buttons.classList.add("disabled");
                    break;
                }
            }
        }
    });

    return (
        <div>
            
            
            <h1 className="pageFirstTitle">How many day types would you like to start with?</h1>

            <DayTypesForm /> 
            
        
            <div  className="centered_form">
                <BasicFormField

                    fieldName='totalCoursesPerTerm'
                    labelText='TOTAL COURSES STUDENTS TAKE PER TERM'
                    backgroundText='courses'
                    type='text'
                    optional="false"
                    errorMsg="Number of courses must be between 1 and 20"
                    minValue={1}
                    maxValue={20}
                    tooltipmessage="<b>How many classes do most students take in a given term at your school?</b>  Most schools offer students 6 to 8 courses per term."
                    isTooltipText="true"
                    onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8 ) event.preventDefault() }}
                />
            </div>

            <h1 className="pageTitle">Should periods rotate each day?</h1>
            <div  id="dropdown_periodsrotate_id" >
                <DropDownFormField 
                    fieldName="isRotating" 
                    backgroundText=""
                    tooltipmsgdropdown="<b>Do periods alternate when they start from day to day?</b>  Some schools rotate their periods so that subjects don't meet at the same time every day.  If you select 'yes', we will adjust the starting schedule we create for you to show periods rotating day by day.  You will be able to adjust your desired rotation when you are editing your bell schedule."
                    isTooltipText="true"
                    options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]} 
                />              
                 
            </div>
            <InfoPopUp clickableMessage={`What is a rotating ${props.formik.values.scheduleType} schedule?`} link={props.formik.values.scheduleTypeLink} />
            <div className="clean_space" />
            
            <div>
                <PageChangeButtons backRoute="/wizard/three" nextRoute="/wizard/three" nextText="Begin Experimenting" nextOnClick={(event) => {
                    event.preventDefault();
                    props.formik.handleSubmit();
                }} />
            </div>
            
           
        </div>
    );
}

StepFour.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default connect(StepFour);
