import React, { useEffect } from "react";
import SchoolDayForm from "../../../components/SchoolDayForm/SchoolDayForm";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import { connect } from "formik";
import PropTypes from 'prop-types';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';

const StepTwo = props => {
  useResizeHelper(false);
  useEffect(() => {
    var buttons = document.getElementById("next_btn");
    let { startTime, lunchDuration, lunchesPerDay, passingTime, periodDuration } = props.formik.values.errors;
    if (startTime || lunchDuration || lunchesPerDay || passingTime || periodDuration) {
      buttons.classList.add("disabled");
    }
    else {
      buttons.classList.remove("disabled");
    }
  });

  return (
    <div>
      <h1 className="pageFirstTitle">How would you like your school day to look?</h1>
      <div className="centered_form">
        <SchoolDayForm />
      </div>
      <PageChangeButtons backRoute="/wizard/one" nextRoute="/wizard/three" />
    </div >
  );
}

StepTwo.propTypes = {
  formik: PropTypes.object.isRequired
};

export default connect(StepTwo);

