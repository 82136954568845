import React from 'react';
import './PdfDailyView.scss';
import DailyViewStack from '../DailyViewStack/DailyViewStack';
import PropTypes from 'prop-types';
import PdfHeader from '../PdfHeader/PdfHeader';


const PdfDailyView = ({ dailystacksarray, dailyviewschedulekey, hourarrays, schedule }) => {
  // The dailystacksarray is an array containing arrays, the latter corresponds to the periods of a single day.
  // The hourarrays is an array of arrays, the latter corresponds to the hours of a single day.

  const stack = (dailystacksarray.map((value, index) => {
    return <div key={'div/' + dailyviewschedulekey + '/dailystack/' + index.toString() } className="dailyviewscroll-element"  >
      <DailyViewStack timeblockarray={value} dailyviewstackkey={ dailyviewschedulekey + '/dailystack/' + index.toString()} timetagsarray={hourarrays[index]} />
    </div>
  }));

  const row = ((elements) =>
    <div className="dailyviewschedule">      
    <div className="dailyviewscroll-container">
      {elements.map((element) => {
        return (element)}
      )}
      </div>
    </div>
  );

  return (
    <div>
      {row(stack.slice(0, 2))}
      {stack.length > 2 ?
        <PdfHeader key='pdfview1' instructionalminutesperyear={schedule.totalAnnualMinutes ? schedule.totalAnnualMinutes.toString() : '0'} scheduletype={schedule.scheduleType} numberdaytypes={schedule.dayTypes.length.toString()} />
        :
        <></>
      }
      {row(stack.slice(2, 4))}
      {stack.length > 4 ?
        <PdfHeader key='pdfview2' instructionalminutesperyear={schedule.totalAnnualMinutes ? schedule.totalAnnualMinutes.toString() : '0'} scheduletype={schedule.scheduleType} numberdaytypes={schedule.dayTypes.length.toString()} />
        :
        <></>
      }
      {row(stack.slice(4))}
    </div>
  );
}

PdfDailyView.propTypes = {
  dailystacksarray: PropTypes.array.isRequired,
  dailyviewschedulekey: PropTypes.string.isRequired,
  hourarrays: PropTypes.array.isRequired,
  schedule: PropTypes.object.isRequired
};

export default PdfDailyView;
