import React from 'react';
import './DesignerHeader.scss'
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import { connect } from "formik";
import ExportImage from '../../assets/export_schedule_icon.svg';


const DesignerHeader = props => {

    const ExportSchedule = () => {
        var form = document.getElementById("exportform");
        form.classList.add("active");
    }
    
    const CreateNewScheduleHandler = () => {
        var form = document.getElementById("createnewschedulewindow");
        document.getElementById("createnewschedulewindow").style.visibility = "visible";
        form.classList.add("active");
        
    }

    return (
        <div className="designerheader">
            <div className="row main_row">
                <div className="column">
                    <div className="row">
                        <div className="designer_title_container column">
                            <div className="designer_title">
                                Bell Schedule Builder
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column buttons_container ml-auto">
                    <div className="row">
                        <div className="column">
                            <FunctionalButton className="linker_text yantramanav" text="+ Create a New Schedule" onclickhandler={CreateNewScheduleHandler} />

                        </div>
                        <div className="column">
                            <FunctionalButton imgpath = {ExportImage} className="export_button button_type_a" text="Export Schedule" onclickhandler={ExportSchedule} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}



export default connect(DesignerHeader);