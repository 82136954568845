import React from 'react';
import './InfoPopUp.scss';
import PropTypes from 'prop-types';

const InfoPopUp = ({ clickableMessage, link }) => {
    const OpenPopUp = () => {
        window.open(link,'_blank');
    }
    return (
        <div className="info_popup">
            <label onClick={OpenPopUp} className="clickable_text">{clickableMessage}</label>
        </div>
    );
}

InfoPopUp.propTypes = {
    clickableMessage: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default InfoPopUp;