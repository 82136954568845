import React from 'react';
import LinkerButton from '../LinkerButton/LinkerButton';
import './PageChangeButtons.scss'
import PropTypes from 'prop-types';

const PageChangeButtons = ({ backRoute, backText, backOnClick, nextRoute, nextText, nextOnClick, tabIndex }) => {
    return (
        <div id="page_buttons" className="page_change_buttons">
            <div className="row">
                {(backRoute) ?
                    <div id="back_btn_id" className="col"  >
                        <LinkerButton tabIndex={tabIndex + 1} className="back_btn" toRoute={backRoute} text={(backText) ? backText : 'Back'} onClick={backOnClick} />
                    </div>
                    : ''}
                {(nextRoute) ?
                    <div id="next_btn" className="col">
                        <LinkerButton tabIndex={tabIndex} className="next_btn" toRoute={nextRoute} text={(nextText) ? nextText : 'Next'} onClick={nextOnClick} />
                    </div>
                    : ''}
            </div>
        </div>
    );
}

PageChangeButtons.defaultProps = {
    backRoute: null,
    backText: null,
    backOnClick: () => { },
    nextRoute: null,
    nextText: null,
    nextOnClick: () => { },
    tabIndex: 0
};

PageChangeButtons.propTypes = {

    backRoute: PropTypes.string,
    backText: PropTypes.string,
    backOnClick: PropTypes.func,
    nextRoute: PropTypes.string,
    nextText: PropTypes.string,
    nextOnClick: PropTypes.func,
    tabIndex: PropTypes.number
};


export default PageChangeButtons;