import React from 'react';
import './OnChangeValueHighlight.scss';
import PropTypes from 'prop-types';
import ChangeHighlight from "react-change-highlight";

class OnChangeValueHighlight extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    // Make sure your book prop is different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value: this.props.value,
      });
  }
  
  render() {

  
    
    return <ChangeHighlight
          showAfter={0}
          hideAfter={1000}
          containerClassName="container_timetag"
          >
            <div ref={React.createRef()}>{this.state.value}</div>
          </ChangeHighlight>
  }
}

OnChangeValueHighlight.propTypes = {
  value: PropTypes.string.isRequired
};

export default OnChangeValueHighlight;