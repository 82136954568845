import React from 'react';
import './PassingTime.scss';
import PropTypes from 'prop-types';

const PassingTime = ({ duration, passinguniquekey}) => {
  return (

    <div className="passingtime">
      <div className="passingtime-box passingtime-color">
        <div className="row passingtime-row">
          <div className="col passingtime-column">
            <div className="passingtime-column-text-passingtime">Passing Time</div>
          </div>
          <div className="col passingtime-column">
            <div className="passingtime-column-text-duration">
              {duration.toString() + ' min'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PassingTime.defaultProps = {
  duration: 0

};

PassingTime.propTypes = {
  duration: PropTypes.number,
  passinguniquekey: PropTypes.string.isRequired
};
export default PassingTime;
