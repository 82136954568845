import React from 'react';
import './DesignerTabMenu.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { connect } from "formik";

const DesignerTabMenu = props => {
  let {backup, selectedDayType, saving} = props.formik.values;
  let dayTypesAmount = Number(props.formik.values.dayTypesAmount);
  let {dayTypes, weeks} = props.formik.values;
  let designerSelectedTab = props.formik.values.designerSelectedTab;
  let history = useHistory();

  let savingClass = saving ? " disabled_on_saving" : "";

  const SelectTab = (id, path) => {

    if(!saving){

      props.formik.setFieldValue("selectedPeriodsToModify",[]);

      // Restore weeks data
      
        
      weeks = [];
      for(let i = 0; i < backup.weeks.length; i++){
        let week = {};
        Object.assign(week,backup.weeks[i]);
        weeks.push(week);
      }

      props.formik.setFieldValue("weeks", weeks);

      // Restore last selected daytype

      if(backup.dayType !== "" && designerSelectedTab !== "overview"){


        // Object.assign(dayTypes[selectedDayType],backup.dayType);
        dayTypes[selectedDayType].name = backup.dayType.name;
        dayTypes[selectedDayType].lunchesPlaced = backup.dayType.lunchesPlaced;
        dayTypes[selectedDayType].lunches = backup.dayType.lunches;
        dayTypes[selectedDayType].lunchesPositions = [];
        for(let i = 0; i < backup.dayType.lunchesPositions.length; i++){
          
          dayTypes[selectedDayType].lunchesPositions.push(backup.dayType.lunchesPositions[i]);
        }

        props.formik.setFieldValue(`dayTypes[${selectedDayType}]`, dayTypes[selectedDayType]);
        
      }

      if (id !== "overview") {
        
        
        // Save last state of data for future rollback
        backup = {
          dayType: {}
        };
        Object.assign(backup.dayType,dayTypes[id]);
        backup.dayType.lunchesPositions = [];
        for(let i = 0; i < dayTypes[id].lunchesPositions.length; i++){
          backup.dayType.lunchesPositions.push(dayTypes[id].lunchesPositions[i]);
        }

        backup.dayType.timeblocks = [];
        for(let i = 0; i < dayTypes[id].timeblocks.length; i++){
          let timeblock = {};
          Object.assign(timeblock,dayTypes[id].timeblocks[i]);
          backup.dayType.timeblocks.push(timeblock);
        }

        backup.weeks = [];
        for(let i = 0; i < weeks.length; i++){
          let week = {};
          Object.assign(week,weeks[i]);
          backup.weeks.push(week);
        }

        props.formik.setFieldValue("backup.weeks", backup.weeks);

        props.formik.setFieldValue("backup.dayType", backup.dayType);

        props.formik.setFieldValue("selectedDayType", id);
        props.formik.setFieldValue("selectedTimeblock", 0);
      }
      

      props.formik.setFieldValue("designerSelectedTab", id.toString());

    }

    props.formik.setFieldValue("saved", true);

    history.push(path);
    
  }


  const addDayType = () => {
    var window;
    if (dayTypesAmount < 10) {
      window = document.getElementById("adddaytypewindow"); 
      window.classList.add("active");
    }


  }

  function truncateName(name) {
    if(name.length > 10) name = name.substring(0,10) + "...";
    return name;
  }


  return (
    <div className="designertabmenu yantramanav">
      <div className="row">
      <FunctionalButton id ="adddaytypebtn_id" text="+ Add a Day Type" onclickhandler={addDayType} className= {(dayTypesAmount >= 10 ? "disable" : "add_button")} />

        <div className="tab_row">
          <button
            id="overview"
            type='button'
            className={(designerSelectedTab === "overview") ? ("tab_button tab_button_active" + savingClass) : ("tab_button" + savingClass)}
            onClick={() => {
              // eslint-disable-next-line
              props.formik.values.saved ? SelectTab("overview", "/designer/overview") : (window.confirm("Some changes will not be saved, are you sure?") ? SelectTab("overview", "/designer/overview") : '')}
            }
          >
            OVERVIEW
          </button>
          {dayTypes.map((dayType, index) => {
            return <button
              key={`${dayType.id}_key`}
              disabled = {saving}
              type='button'
              id={`tab_${index.toString()}`}
              className={(designerSelectedTab === index.toString()) ? ("tab_button tab_button_active" + savingClass) : ("tab_button" + savingClass)}
              onClick={()  => {
                // eslint-disable-next-line
                return props.formik.values.saved ? SelectTab(index, "/designer/daytype") : (window.confirm("Some changes will not be saved, are you sure?") ? SelectTab(index, "/designer/daytype") : '')}
              }
            >
              {truncateName(dayType.name)}
            </button>;
          })}
        </div>

      </div>
      
    </div>
  );
}

DesignerTabMenu.propTypes = {
  formik: PropTypes.object.isRequired
};

export default connect(DesignerTabMenu);
