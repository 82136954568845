import React from 'react';
import './SelectedPeriodToModifyBox.scss';
import { connect } from "formik";
import PropTypes from 'prop-types';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import DeleteImage from '../../assets/trashcan.svg';

const SelectedPeriodToModifyBox = props => {
	const periods = props.formik.values.periods;
	let {selectedPeriodsToModify} = props.formik.values;
	const {periodId} = props;
	const onDelete = () => {
		selectedPeriodsToModify[periodId] = undefined;
		props.formik.setFieldValue(`selectedPeriodsToModify`,selectedPeriodsToModify);
	}

    return (
		<div className="selectedperiodtomodify">
			<FunctionalButton className="selected_period_delete_btn" imgpath={DeleteImage} onclickhandler={onDelete} />
		
			<div style={{ backgroundColor: (periodId === "lunch")?"#ECEDF1":periods[periodId].color }} className="box">
				{(periodId === "lunch")?"Lunch":periods[periodId].name}
			</div>
		</div>
		

    );
}

SelectedPeriodToModifyBox.propTypes = {
	formik: PropTypes.object.isRequired,
	periodId: PropTypes.number.isRequired,
  };

export default connect(SelectedPeriodToModifyBox);
